import { Outlet, useLocation} from "react-router-dom"
import NavBar from "../HomePage/NavBar"
import {Suspense} from 'react'
import LoadingPage from "./LoadingPage";
import Footer from "./Footer";


export function Layout () {

  const location = useLocation();

    return (
        <div>
            <NavBar Section={location.pathname}/>
            <Suspense fallback={<LoadingPage />}>
            <Outlet />            
            </Suspense>
            <Footer />
        </div>
    )
}