import axios from 'axios'
import {useEffect,useState} from 'react'

const Adresse = ({Step, setStep , StepTitel , setFormdata}) => {

    const [Wilaya, setWilaya] = useState('')
    const [DairaList, setDairaList] = useState([])
    const [Daira, setDaira] = useState('')
    const [CommuneList, setCommuneList] = useState([])
    const [Commune, setCommune] = useState('')
    const [CodePostalList, setCodePostalList] = useState([])
    const [CodePostal, setCodePostal] = useState('')
    const [Adresse, setAdresse] = useState('')
    const [Mobile, setMobile] = useState('')
    const [Mail, setMail] = useState('')
    const [ReMail, setReMail] = useState('')
    const [NIN, setNIN] = useState('')
    const [Numerodecartenational, setNumerodecartenational] = useState('')
    const [DatededElivrence, setDatededElivrence] = useState('')
    const [Wilayadedélivrence, setWilayadedélivrence] = useState('')
    const [CommunededélivrenceList, setCommunededélivrenceList] = useState('')
    const [Communededélivrence, setCommunededélivrence] = useState('')
    const [Habitation, setHabitation] = useState('')

    const [FormValidate, setFormValidate] = useState(true)
    const [Showerr, setShowerr] = useState(false)

    console.log(DairaList.length == 0 || DairaList.length);

    useEffect(() => {

      console.log(FormValidate);


      Validation();
       
    }, [Wilaya,Daira,Commune,CodePostal,Adresse,Mobile,Mail,ReMail,NIN,Numerodecartenational,DatededElivrence,Wilayadedélivrence,Communededélivrence,Habitation])



    function onlyLetters(str) {
      return /^[a-zA-Z]+$/.test(str);
    }
    function dateIsValid(date) {
      return date instanceof Date && !isNaN(date);
    }
    function onlyNumbers(str) {
      return /^[0-9]+$/.test(str);
    }

    function isValidEmail (string) {
      // The regular expression used by [type="email"]
      var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      // Test the string against the regular expression
      return regex.test(string)
    }

    function DateFormat(date) {

      var d = new Date(date);
      var datestring = d.getFullYear()  + "/" + d.getMonth().toString().padStart(2 , '0') + "/" + d.getDate().toString().padStart(2 , '0');

      return datestring;
      
    }




    function GoNext() {

      setShowerr(true)

      console.log(FormValidate);

      if (!FormValidate) {
        return
      }

      setFormdata((prev) => [
      {...prev[0] , ...{
        "wilaya": Wilaya,
        "dairas":Daira,
        "commune":Commune,
        "codepostal": CodePostal,
        "adresse": Adresse.trim(),
        "mobile": Mobile,
        "email":Mail.trim(),
        "remail":ReMail,
        "piece":"002",
        "nin": NIN,
        "piecenum": Numerodecartenational,
        "piecedate": DateFormat(DatededElivrence),
        "wpiecepar": Wilayadedélivrence,
        "piecepar":  Communededélivrence,
        "habitation": Habitation,
      }}
      ])

      setStep(3)
    }

    function Validation() {

      setFormValidate(true)
      console.log(FormValidate);

     
      if (Wilaya === "") {
        setFormValidate(false)       
      }
      if (Daira === "") {
        setFormValidate(false)       
      }
      if (Commune ==="") {
        setFormValidate(false)       
      }
      if (CodePostal ==="") {
        setFormValidate(false)       
      }
      if (Adresse.trim() ==="") {
        setFormValidate(false)       
      }

      if (!onlyNumbers(Mobile) || Mobile.length !== 10 || (Mobile.slice(0, 2) !== "07" && Mobile.slice(0, 2) !== "05" && Mobile.slice(0, 2) !== "06") ) {
            setFormValidate(false)    
      }
      if (!isValidEmail(Mail.trim())) {
            setFormValidate(false)       
      }
      if (!isValidEmail(ReMail.trim())) {
            setFormValidate(false)       
      }
      if (Mail.trim() !== ReMail.trim()) {
        setFormValidate(false)       
      }
      if (!onlyNumbers(NIN) || NIN.length !== 18 ) {
            setFormValidate(false)    
      }
      if (!onlyNumbers(Numerodecartenational) || Numerodecartenational.length !== 9) {
         setFormValidate(false)       
      }
      if (DatededElivrence ==="") {
        setFormValidate(false)       
      }
      if (Wilayadedélivrence ==="") {
        setFormValidate(false)       
      }
      if (Communededélivrence ==="") {
        setFormValidate(false)       
      }
      // if (Habitation !== '1' || Habitation !== '2' || Habitation !== '3' || Habitation !== '4') {
      //    setFormValidate(false)
      //    console.log('hhhhhhhh');
      // }
      
    }


    useEffect(() => {

        const GetCommune = async () =>{
            try {
              const {data: response} = await axios.get(`https://opr.diardzair.com.dz/ws/region/dairas?valeur=${Wilaya}`);
              console.log(response);
              setDairaList(response['codepostals'])
              setDaira([])
              setCommune('')
              setCodePostal('')
              setCommuneList([])
              setCodePostalList([])
            } catch (error) {
              console.error(error.message);
            }
        }

        if (Wilaya !== '') {
               GetCommune();
        }
       
    
    }, [Wilaya])

    useEffect(() => {

      const GetDaira = async () =>{
          try {
            const {data: response} = await axios.get(`https://opr.diardzair.com.dz/ws/region/commune?valeur=${Daira}`);
            console.log(response);
            setCommune('')
            setCommuneList(response['codepostals'])
            setCodePostal('')
            setCodePostalList([])

          } catch (error) {
            console.error(error.message);
          }
      }

      if (Daira !== '') {
             GetDaira();
      }
     
  
  }, [Daira])

  useEffect(() => {

      const GetCodePostal = async () =>{
          try {
            const {data: response} = await axios.get(`https://opr.diardzair.com.dz/ws/region/codepostal?valeur=${Commune}`);
            console.log(response);
            setCodePostal('')
            setCodePostalList(response['codepostals'])
          } catch (error) {
            console.error(error.message);
          }
      }

      if (Commune !== '') {
            GetCodePostal();
      }
    

  }, [Commune])


          useEffect(() => {

            const Communededélivrence = async () =>{
                try {
                  const {data: response} = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilayadedélivrence}`);
                  console.log(response);
                  setCommunededélivrenceList(response['communes'])
                } catch (error) {
                  console.error(error.message);
                }
            }

            if (Wilayadedélivrence !== '') {
                  Communededélivrence();
            }
          

        }, [Wilayadedélivrence])


    console.log(CodePostal,Adresse.trim(),Mobile,Mail,ReMail,NIN,Numerodecartenational,DatededElivrence,Wilayadedélivrence,Communededélivrence,Habitation);


  return (
    <div className={`form-info form-2 row ${Step !== 2 ? 'd-none' : ''}`}>
    <div className='text-center info-step-titel mb-3'>{StepTitel[Step-1]}</div>
<form className="row g-3 m-auto justify-content-center">


<div className="col-md-6 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Wilaya</label>
    <select class="form-select" aria-label="Default select example" onChange={ (e)=> setWilaya(e.target.value)}>
    <option disabled selected="selected">wilaya</option>
    <option value="ADRAR">(01) ADRAR</option>
    <option value="CHLEF">(02) CHLEF</option>
    <option value="LAGHOUAT">(03) LAGHOUAT</option>
    <option value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
    <option value="BATNA">(05) BATNA</option>
    <option value="BEJAIA">(06) BEJAIA</option>
    <option value="BISKRA">(07) BISKRA</option>
    <option value="BECHAR">(08) BECHAR</option>
    <option value="BLIDA">(09) BLIDA</option>
    <option value="BOUIRA">(10) BOUIRA</option>
    <option value="TAMANRASSET">(11) TAMANRASSET</option>
    <option value="TEBESSA">(12) TEBESSA</option>
    <option value="TLEMCEN">(13) TLEMCEN</option>
    <option value="TIARET">(14) TIARET</option>
    <option value="TIZI OUZOU">(15) TIZI-OUZOU</option>
    <option value="ALGER">(16) ALGER</option>
    <option value="DJELFA">(17) DJELFA</option>
    <option value="JIJEL">(18) JIJEL</option>
    <option value="SETIF">(19) SETIF</option>
    <option value="SAIDA">(20) SAIDA</option>
    <option value="SKIKDA">(21) SKIKDA</option>
    <option value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
    <option value="ANNABA">(23) ANNABA</option>
    <option value="GUELMA">(24) GUELMA</option>
    <option value="CONSTANTINE">(25) CONSTANTINE</option>
    <option value="MEDEA">(26) MEDEA</option>
    <option value="MOSTAGANEM">(27) MOSTAGANEM</option>
    <option value="M SILA">(28) M-SILA</option>
    <option value="MASCARA">(29) MASCARA</option>
    <option value="OUARGLA">(30) OUARGLA</option>
    <option value="ORAN">(31) ORAN</option>
    <option value="EL BAYADH">(32) EL-BAYADH</option>
    <option value="ILLIZI">(33) ILLIZI</option>
    <option value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
    <option value="BOUMERDES">(35) BOUMERDES</option>
    <option value="EL TARF">(36) EL-TARF</option>
    <option value="TINDOUF">(37) TINDOUF</option>
    <option value="TISSEMSILT">(38) TISSEMSILT</option>
    <option value="EL OUED">(39) EL-OUED</option>
    <option value="KHENCHELA">(40) KHENCHELA</option>
    <option value="SOUK AHRAS">(41) SOUK-AHRAS</option>
    <option value="TIPAZA">(42) TIPAZA</option>
    <option value="MILA">(43) MILA</option>
    <option value="AIN DEFLA">(44) AIN-DEFLA</option>
    <option value="NAAMA">(45) NAAMA</option>
    <option value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
    <option value="GHARDAIA">(47) GHARDAIA</option>
    <option value="RELIZANE">(48) RELIZANE</option>
    <option value="Timimoun">(49) Timimoun</option>
    <option value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
    <option value="Ouled Djellal">(51) Ouled Djellal</option>
    <option value="Béni Abbès">(52) Béni Abbès</option>
    <option value="In Salah">(53) In Salah</option>
    <option value="In Guezzam">(54) In Guezzam</option>
    <option value="Touggourt">(55) Touggourt</option>
    <option value="Djanet">(56) Djanet</option>
    <option value="El M'Ghair">(57) El M'Ghair</option>
    <option value="El Meniaa">(58) El Meniaa</option>
    </select>
    <div className='alert-form'>{Showerr && (Wilaya  == "" ? 'Veuillez renseigner votre Wilaya' : '') }</div>
  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Daïras</label>
    <select class="form-select" aria-label="Default select example" onChange={ (e)=> setDaira(e.target.value)}>
    
    {/* {DairaList.length == 0 || DairaList.length > 0 && */}
        <option disabled  selected={ Daira == '' ? "selected" : ''}>Daira</option>
    {/* } */}
    

    {DairaList.length > 0 && DairaList.map((item,i) => (
        <option value={item.dairas}>{item.dairas}</option>
    )) 
    }
    </select>
    <div className='alert-form'>{Showerr && (Daira  == "" ? 'Veuillez renseigner votre Daïras' : '') }</div>
  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Commune</label>
    <select class="form-select" aria-label="Default select example" onChange={ (e)=> setCommune(e.target.value)}>

    {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
       <option disabled selected={ Commune == "" ? "selected" : ''}>Commune</option>
    {/* } */}
    
   
    {CommuneList.length > 0 && CommuneList.map((item,i) => (
        <option value={item.commune}>{item.commune}</option>
    )) 
    }
    </select>
    <div className='alert-form'>{Showerr && (Commune  == "" ? 'Veuillez renseigner votre Commune' : '') }</div>

  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Code Postal</label>
    <select class="form-select" aria-label="Default select example" onChange={ (e)=> setCodePostal(e.target.value)}>

    {/* { CodePostalList.length === 0 || CodePostalList.length > 0 && */}
    
       <option disabled selected={ CodePostal == '' ? "selected" : ''}>Code Postal</option>
    {/* } */}
    
    {CodePostalList.length > 0 && CodePostalList.map((item,i) => (
        <option value={item.codePostal}>{item.codePostal}</option>
    )) 
    }
    </select>
    <div className='alert-form'>{Showerr && (CodePostal  == "" ? 'Veuillez renseigner votre Code Postal' : '') }</div>
  </div>
  
  <div className="col-md-12 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Adresse</label>
    <input type="text" placeholder='Aresse' onChange={(e) => setAdresse(e.target.value)} value={Adresse} className="form-control" id="inputEmail4" />
    <span className='note-form'>Saisissez votre adresse  : : boulevard Zighout Youcef bt C N°41.</span>
    <div className='alert-form'>{Showerr && (Adresse.trim()  == "" ? 'Veuillez renseigner votre Adesse' : '') }</div>
  </div>
  <div className="col-md-12 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Mobile</label>
    <input type="text" placeholder='Mobile' className="form-control" id="inputEmail4" onChange={(e) => setMobile(e.target.value.replace(/[^0-9]+/g , ''))} value={Mobile} />
    <span className='note-form'>Saisissez votre numéro téléphone portable à 10 chiffres commençant par 06 ou 07 ou 05 .</span>
    <div className='alert-form'>{Showerr && (Mobile  == "" ? 'Veuillez renseigner votre Mobile' : '') }</div>
    <div className='alert-form'>{Showerr && (!onlyNumbers(Mobile) || Mobile.length !== 10 || (Mobile.slice(0, 2) !== "07" && Mobile.slice(0, 2) !== "05" && Mobile.slice(0, 2) !== "06")) ? 'Vous devez entrer un numéro de téléphone valide (Djezzy Ooredoo Mobilis)' : ''}</div>
  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Adresse email</label>
    <input type="email" placeholder='Adresse email' className="form-control" id="inputEmail4" onChange={(e) => setMail(e.target.value.trim())} value={Mail.trim()} />
    <div className='alert-form'>{Showerr && (Mail.trim()  == "" ? 'Veuillez renseigner votre Adresse email' : '') }</div>
    <div className='alert-form'>{Showerr && (!isValidEmail(Mail.trim())  ? 'Vous devez entrer un email valide' : '') }</div>
  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Confirmez l'adresse email</label>
    <input type="email" placeholder="Confirmez l'adresse email" className="form-control" id="inputEmail4" onChange={(e) => setReMail(e.target.value.trim())} value={ReMail.trim()} />
    <div className='alert-form'>{Showerr && (ReMail.trim()  == "" ? 'Veuillez renseigner votre Adresse email' : '') }</div>
    <div className='alert-form'>{Showerr && (!isValidEmail(ReMail.trim())  ? 'Vous devez entrer un email valide' : '') }</div>
    <div className='alert-form'>{Showerr && (ReMail.trim() !== Mail.trim()  ? 'Le premier e-mail doit être le même que le second' : '') }</div>

  </div>
  <div className="col-md-12 mb-3">
    <label htmlFor="inputEmail4" className="form-label">NIN (le Numéro d’Identification Nationale) </label>
    <input type="text" placeholder="NIN (le Numéro d’Identification Nationale)" className="form-control" id="inputEmail4" onChange={(e) => setNIN(e.target.value.replace(/[^0-9]+/g , ''))} value={NIN} />
    <span className='note-form'>(Le « NIN» est composé de 18 chiffres)</span>
    <div className='alert-form'>{Showerr && (NIN  == "" ? 'Veuillez renseigner votre NIN (le Numéro d’Identification Nationale)' : '') }</div>
    <div className='alert-form'>{Showerr && (NIN.length !== 18  ? 'Veuillez renseigner votre NIN (Le « NIN» est composé de 18 chiffres)' : '') }</div>
  </div>
  <div className="col-md-12 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Numéro de carte national</label>
    <input type="text" placeholder="Numéro de carte national" className="form-control" id="inputEmail4" onChange={(e) => setNumerodecartenational(e.target.value.replace(/[^0-9]+/g , ''))} value={Numerodecartenational} />
    <div className='alert-form'>{Showerr && (Numerodecartenational == "" ? 'Veuillez renseigner votre Numero carte national' : '') }</div>
    <div className='alert-form'>{Showerr && (Numerodecartenational.length !== 9 ? 'Le Numero carte national doit comporter 9 chiffres' : '') }</div>

  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Date de délivrence</label>
    <input type="date" className="form-control" id="inputEmail4" max={new Date().toISOString().slice(0, 10)} onChange={(e) => setDatededElivrence(e.target.value)} value={DatededElivrence} />
    <span className='note-form'>Veuillez choisir une date dans le calendrier</span>
    <div className='alert-form'>{Showerr && (DatededElivrence == "" ? 'Veuillez renseigner votre Date de délivrence' : '') }</div>
  </div>
  
    
  <div className="col-md-6 mb-3">
    <label htmlFor="inputEmail4" className="form-label">Wilaya de délivrence</label>
    <select class="form-select" aria-label="Default select example" onChange={ (e)=> setWilayadedélivrence(e.target.value)}>
    <option disabled selected="selected">wilaya</option>
    <option value="ADRAR">(01) ADRAR</option>
    <option value="CHLEF">(02) CHLEF</option>
    <option value="LAGHOUAT">(03) LAGHOUAT</option>
    <option value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
    <option value="BATNA">(05) BATNA</option>
    <option value="BEJAIA">(06) BEJAIA</option>
    <option value="BISKRA">(07) BISKRA</option>
    <option value="BECHAR">(08) BECHAR</option>
    <option value="BLIDA">(09) BLIDA</option>
    <option value="BOUIRA">(10) BOUIRA</option>
    <option value="TAMANRASSET">(11) TAMANRASSET</option>
    <option value="TEBESSA">(12) TEBESSA</option>
    <option value="TLEMCEN">(13) TLEMCEN</option>
    <option value="TIARET">(14) TIARET</option>
    <option value="TIZI OUZOU">(15) TIZI-OUZOU</option>
    <option value="ALGER">(16) ALGER</option>
    <option value="DJELFA">(17) DJELFA</option>
    <option value="JIJEL">(18) JIJEL</option>
    <option value="SETIF">(19) SETIF</option>
    <option value="SAIDA">(20) SAIDA</option>
    <option value="SKIKDA">(21) SKIKDA</option>
    <option value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
    <option value="ANNABA">(23) ANNABA</option>
    <option value="GUELMA">(24) GUELMA</option>
    <option value="CONSTANTINE">(25) CONSTANTINE</option>
    <option value="MEDEA">(26) MEDEA</option>
    <option value="MOSTAGANEM">(27) MOSTAGANEM</option>
    <option value="M SILA">(28) M-SILA</option>
    <option value="MASCARA">(29) MASCARA</option>
    <option value="OUARGLA">(30) OUARGLA</option>
    <option value="ORAN">(31) ORAN</option>
    <option value="EL BAYADH">(32) EL-BAYADH</option>
    <option value="ILLIZI">(33) ILLIZI</option>
    <option value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
    <option value="BOUMERDES">(35) BOUMERDES</option>
    <option value="EL TARF">(36) EL-TARF</option>
    <option value="TINDOUF">(37) TINDOUF</option>
    <option value="TISSEMSILT">(38) TISSEMSILT</option>
    <option value="EL OUED">(39) EL-OUED</option>
    <option value="KHENCHELA">(40) KHENCHELA</option>
    <option value="SOUK AHRAS">(41) SOUK-AHRAS</option>
    <option value="TIPAZA">(42) TIPAZA</option>
    <option value="MILA">(43) MILA</option>
    <option value="AIN DEFLA">(44) AIN-DEFLA</option>
    <option value="NAAMA">(45) NAAMA</option>
    <option value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
    <option value="GHARDAIA">(47) GHARDAIA</option>
    <option value="RELIZANE">(48) RELIZANE</option>
    <option value="Timimoun">(49) Timimoun</option>
    <option value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
    <option value="Ouled Djellal">(51) Ouled Djellal</option>
    <option value="Béni Abbès">(52) Béni Abbès</option>
    <option value="In Salah">(53) In Salah</option>
    <option value="In Guezzam">(54) In Guezzam</option>
    <option value="Touggourt">(55) Touggourt</option>
    <option value="Djanet">(56) Djanet</option>
    <option value="El M'Ghair">(57) El M'Ghair</option>
    <option value="El Meniaa">(58) El Meniaa</option>
    </select>
    <div className='alert-form'>{Showerr && (Wilayadedélivrence == "" ? 'Veuillez renseigner votre Wilaya de délivrence' : '') }</div>
  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Commune de délivrence</label>
    <select class="form-select" aria-label="Default select example" onChange={ (e)=> setCommunededélivrence(e.target.value)}>
    
        <option disabled selected="selected">Commune</option>
    
    {CommunededélivrenceList.length > 0 && CommunededélivrenceList.map((item,i) => (
        <option value={item.commune}>{item.commune}</option>
    )) 
    }
    </select>
    <div className='alert-form'>{Showerr && (Communededélivrence == "" ? 'Veuillez renseigner votre Commune de délivrence' : '') }</div>
  </div>
  <div className="col-md-6 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Habitation</label>
    <select class="form-select" aria-label="Default select example" onChange={ (e)=> setHabitation(e.target.value)}>
    <option value={0} disabled selected="selected">Choisissez</option>
    <option value={1}>Avec les parents</option>
    <option value={2}>Locataire</option>
    <option value={3}>Propriétaire</option>
    <option value={4}>Logt de Fonction</option>
    </select>
    <div className='alert-form'>{Showerr && (Habitation == "" ? 'Veuillez renseigner votre habitation' : '') }</div>

  </div>
  
  
  
</form>

<div className='row mt-5'>
  <div className="col text-start">
    <button type="submit" onClick={() => setStep(1)} className="next-info-btn">Retour</button>
  </div>
  <div className="col text-end">
    <button type="submit" onClick={() => GoNext()} className="next-info-btn">Suivant</button>
  </div>
  </div>
</div>
  )
}

export default Adresse