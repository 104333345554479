import axios from 'axios';
import { useEffect, useState,useContext } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import LangContext from '../Context/lang';



const CategorieDarI3mar = () => {

    const { Lang, Global, dari3marsection } = useContext(LangContext);


    let { id } = useParams();

    const [DarI3marCat, setDarI3marCat] = useState([])

    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/produit/categorie?dar=DARI3MAR&categorie=${id}`);
                // console.log(response[0]);
                setDarI3marCat(response[0]);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    // console.log(DarI3marCat);

    return (
        <div className='category-dari3mar mt-1 pt-1'>
            <div>

                <div className='dar-i3mar-page'>
                    <div className='dari3mar-head' style={{height:'200px'}}>
                        {/* {DarI3marCat.length > 0 && DarI3marCat  } */}
                    </div>
                </div>

                <div className='container mt-2 pt-5'>
                    <h1 className='mb-5 text-center'>{( dari3marsection['product'][Lang] )}</h1>
                    <div className='row'>


                        {
                            DarI3marCat.length > 0 && DarI3marCat.map((item, i) => (

                                <div className='col-lg-4 col-12 mt-5 mt-lg-0'>
                                    <a href={`/dari3mar/product/${item.hash}`} >
                                        <div className='dar-i3mar-cat-card'>
                                            <img src={DarI3marCat !== '' ? `https://opr.diardzair.com.dz/${item.image}` : ""}></img>
                                            <div className='dar-i3mar-cat-card-p'><p>{item.nom}</p></div>
                                            <svg xmlns="https://www.w3.org/2000/svg" width="63" height="43" viewBox="0 0 63 43">
                                                <defs>
                                                    <linearGradient id="linear-gradient" x1="0.044" y1="-0.585" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stop-color="#7ff6ad" />
                                                        <stop offset="1" stop-color="#37d9e1" />
                                                    </linearGradient>
                                                </defs>
                                                <rect id="Rectangle_196" data-name="Rectangle 196" width="63" height="43" rx="21.5" fill="url(#linear-gradient)" />
                                                <text id="_" data-name="+" transform="translate(23 31)" fill="#fff" font-size="27" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">+</tspan></text>
                                            </svg>

                                        </div>
                                    </a>
                                </div>

                                //   <div className="col"><Link to={"/search/?page=1&marque="+item.title} ><img className="img-fluid" loading="lazy" src={item['imageDesk']['webPath'] !== undefined ? `https://opr.diardzair.com.dz/${item['imageDesk']['webPath']}` : "img/brandt.png"}  /></Link></div>
                            ))
                        }

                        { DarI3marCat.length == 0 && 
                        <div className='text-center mt-5 '>{( dari3marsection['emptyproductmsg'][Lang] )}</div>
                        
                        }


                    </div>
                </div>

            </div>
        </div>
    )
}

export default CategorieDarI3mar