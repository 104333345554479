import { useEffect, useState } from 'react'


const Footer = () => {


    return (
        <div className='saidaty-header saidaty-footer-border'>
            <div className='overly-saidaty'></div>

            

            <div className='pic-svg-footer'>



                <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" viewBox="0 0 1007.683 503.841">
                    <defs>
                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#753217" />
                            <stop offset="0.212" stop-color="#ffca74" />
                            <stop offset="0.36" stop-color="#ad5317" />
                            <stop offset="0.49" stop-color="#ad5317" />
                            <stop offset="0.647" stop-color="#ffca74" />
                            <stop offset="0.827" stop-color="#ad5317" />
                            <stop offset="1" stop-color="#ffca74" />
                        </linearGradient>
                    </defs>
                    <path id="Tracé_1617" data-name="Tracé 1617" d="M401.325,404.767,330.043,434.29V588.449H175.887l-29.525,71.287L75.075,630.2-33.931,739.211-142.937,630.2l-71.287,29.531-29.525-71.287H-397.9V434.293l-71.287-29.525,29.531-71.284-98.114-98.113h43.306l90.927,90.926-25.638,61.9,61.9,25.635v144h143.994l25.643,61.9,61.9-25.638L-33.931,695.9,67.888,594.086l61.9,25.638,25.638-61.9h144v-144l61.892-25.638-25.638-61.9,90.931-90.926h43.3L371.8,333.483Z" transform="translate(537.775 -235.37)" fill="url(#linear-gradient)" />
                </svg>


            </div>

            <div className='footer-copyright'>
                مركز الإتصال ديار دزاير
                <div className='footer-contact'>
                    <span>0982 300 500</span>
                    <span>www.diardzair.com.dz</span>
                </div>
            </div>




        </div>
    )
}

export default Footer