import { useContext } from 'react'
import LangContext from '../Context/lang';


const PrivacyPolicy = () => {
    const { Lang } = useContext(LangContext);


    return (
        <div className='assistence-page faq-page pb-5 pt-5'>



            {/* <div className='search-area d-flex align-items-center' style={{ height: '167px', padding: 0, backgroundPosition: 'center center', background: `linear-gradient(45deg,#f33f6959,#000000fc), url(https://www.lg.com/africa_fr/images/home-appliances/HA_product_line_up-web.png) center` }}>

                <div className='container'>
                    <div className='titel ass-title'>Privacy Policy</div>


                </div>
            </div> */}


            <div className='container privacy-policy'>
                <div data-custom-class="body">
                    <div>
                        <strong>
                            <span style={{ fontSize: 26 }}>
                                <span data-custom-class="title">
                                    <bdt className="block-component" />
                                    PRIVACY NOTICE
                                    <bdt className="else-block" />
                                </span>
                            </span>
                        </strong>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                            <strong>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="subtitle">
                                        Last updated <bdt className="question">May 02, 2024</bdt>
                                    </span>
                                </span>
                            </strong>
                        </span>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    This privacy notice for{" "}
                                    <bdt className="question">
                                        diar dzair
                                        <bdt className="block-component" />
                                    </bdt>{" "}
                                    (<bdt className="block-component" />"<strong>we</strong>," "
                                    <strong>us</strong>," or "<strong>our</strong>"
                                    <bdt className="statement-end-if-in-editor" />
                                </span>
                                <span data-custom-class="body_text">
                                    ), describes how and why we might collect, store, use, and/or share
                                    (<bdt className="block-component" />"<strong>process</strong>"
                                    <bdt className="statement-end-if-in-editor" />) your information
                                    when you use our services (<bdt className="block-component" />"
                                    <strong>Services</strong>"
                                    <bdt className="statement-end-if-in-editor" />
                                    ), such as when you:
                                </span>
                            </span>
                        </span>
                        <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <div>
                            <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ fontSize: 15 }}>
                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </bdt>
                        </div>
                        <ul>
                            <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            Download and use
                                            <bdt className="block-component">
                                                <bdt className="block-component" /> our Facebook application
                                                <bdt className="block-component"> </bdt>(
                                                <bdt className="question">diar dzair)</bdt>
                                                <bdt className="statement-end-if-in-editor">,</bdt>
                                                <bdt className="statement-end-if-in-editor">
                                                    {" "}
                                                    or any other application of ours that links to this privacy
                                                    notice
                                                </bdt>
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <ul>
                            <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            Engage with us in other related ways, including any sales,
                                            marketing, or events
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span data-custom-class="body_text">
                                        <strong>Questions or concerns? </strong>Reading this privacy
                                        notice will help you understand your privacy rights and choices.
                                        If you do not agree with our policies and practices, please do not
                                        use our Services.
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <strong>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                                </span>
                            </strong>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>
                                        <em>
                                            This summary provides key points from our privacy notice, but
                                            you can find out more details about any of these topics by
                                            clicking the link following each key point or by using our{" "}
                                        </em>
                                    </strong>
                                </span>
                            </span>
                            <a data-custom-class="link" href="#toc">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>
                                            <em>table of contents</em>
                                        </strong>
                                    </span>
                                </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>
                                        <em> below to find the section you are looking for.</em>
                                    </strong>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>What personal information do we process?</strong> When you
                                    visit, use, or navigate our Services, we may process personal
                                    information depending on how you interact with us and the Services,
                                    the choices you make, and the products and features you use. Learn
                                    more about{" "}
                                </span>
                            </span>
                            <a data-custom-class="link" href="#personalinfo">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        personal information you disclose to us
                                    </span>
                                </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>Do we process any sensitive personal information?</strong>{" "}
                                    <bdt className="block-component" />
                                    We do not process sensitive personal information.
                                    <bdt className="else-block" />
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>Do we receive any information from third parties?</strong>{" "}
                                    <bdt className="block-component" />
                                    We may receive information from public databases, marketing
                                    partners, social media platforms, and other outside sources. Learn
                                    more about{" "}
                                </span>
                            </span>
                            <a data-custom-class="link" href="#othersources">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        information collected from other sources
                                    </span>
                                </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>How do we process your information?</strong> We process your
                                    information to provide, improve, and administer our Services,
                                    communicate with you, for security and fraud prevention, and to
                                    comply with law. We may also process your information for other
                                    purposes with your consent. We process your information only when we
                                    have a valid legal reason to do so. Learn more about{" "}
                                </span>
                            </span>
                            <a data-custom-class="link" href="#infouse">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        how we process your information
                                    </span>
                                </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>
                                        In what situations and with which{" "}
                                        <bdt className="block-component" />
                                        parties do we share personal information?
                                    </strong>{" "}
                                    We may share information in specific situations and with specific{" "}
                                    <bdt className="block-component" />
                                    third parties. Learn more about{" "}
                                </span>
                            </span>
                            <a data-custom-class="link" href="#whoshare">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        when and with whom we share your personal information
                                    </span>
                                </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    .<bdt className="block-component" />
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>What are your rights?</strong> Depending on where you are
                                    located geographically, the applicable privacy law may mean you have
                                    certain rights regarding your personal information. Learn more about{" "}
                                </span>
                            </span>
                            <a data-custom-class="link" href="#privacyrights">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">your privacy rights</span>
                                </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>How do you exercise your rights?</strong> The easiest way to
                                    exercise your rights is by{" "}
                                    <bdt className="block-component">submitting a </bdt>
                                </span>
                            </span>
                            <a
                                data-custom-class="link"
                                href="https://app.termly.io/notify/aa9a8d8e-573d-4a14-937c-4dbdd7c00a90"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        data subject access request
                                    </span>
                                </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />, or by contacting us.
                                    We will consider and act upon any request in accordance with
                                    applicable data protection laws.
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    Want to learn more about what we do with any information we collect?{" "}
                                </span>
                            </span>
                            <a data-custom-class="link" href="#toc">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        Review the privacy notice in full
                                    </span>
                                </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">.</span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="toc" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(0, 0, 0)" }}>
                                        <strong>
                                            <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#infocollect">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        1. WHAT INFORMATION DO WE COLLECT?
                                    </span>
                                </a>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#infouse">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        2. HOW DO WE PROCESS YOUR INFORMATION?
                                        <bdt className="block-component" />
                                    </span>
                                </a>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 58, 250)" }}>
                                    <a data-custom-class="link" href="#whoshare">
                                        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                                    </a>
                                </span>
                                <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <bdt className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#cookies">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                                    </span>
                                </a>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt className="statement-end-if-in-editor" />
                                            </span>
                                        </span>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#sociallogins">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        <span style={{ color: "rgb(0, 58, 250)" }}>
                                            <span style={{ color: "rgb(0, 58, 250)" }}>
                                                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                            </span>
                                        </span>
                                    </span>
                                </a>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                </span>
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#intltransfers">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                                    </span>
                                </a>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt className="statement-end-if-in-editor" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#inforetain">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        7. HOW LONG DO WE KEEP YOUR INFORMATION?
                                    </span>
                                </a>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <bdt className="block-component" />
                                                    <bdt className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#infominors">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        8. DO WE COLLECT INFORMATION FROM MINORS?
                                    </span>
                                </a>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt className="statement-end-if-in-editor" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 58, 250)" }}>
                                    <a data-custom-class="link" href="#privacyrights">
                                        9. WHAT ARE YOUR PRIVACY RIGHTS?
                                    </a>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <a data-custom-class="link" href="#DNT">
                                    <span style={{ color: "rgb(0, 58, 250)" }}>
                                        10. CONTROLS FOR DO-NOT-TRACK FEATURES
                                        <bdt className="block-component" />
                                    </span>
                                    <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                        </span>
                                    </bdt>
                                </a>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#DNT">
                                <bdt className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                </bdt>
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#DNT">
                                <span style={{ fontSize: 15 }} />
                            </a>
                            <a data-custom-class="link" href="#policyupdates">
                                <span style={{ color: "rgb(0, 58, 250)" }}>
                                    11. DO WE MAKE UPDATES TO THIS NOTICE?
                                </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#contact">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#request">
                                <span style={{ color: "rgb(0, 58, 250)" }}>
                                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                                    YOU?
                                </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="infocollect" style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                                    <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                                        <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                                            <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                <strong>
                                                    <span data-custom-class="heading_1">
                                                        1. WHAT INFORMATION DO WE COLLECT?
                                                    </span>
                                                </strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="personalinfo" style={{ lineHeight: "1.5" }}>
                            <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                    <strong>Personal information you disclose to us</strong>
                                </span>
                            </span>
                        </div>
                        <div>
                            <div>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <strong>
                                                            <em>In Short:</em>
                                                        </strong>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <strong>
                                                            <em> </em>
                                                        </strong>
                                                        <em>
                                                            We collect personal information that you provide to us.
                                                        </em>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        We collect personal information that you voluntarily provide to us
                                        when you{" "}
                                        <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                        express an interest in obtaining information about us or our
                                        products and Services, when you participate in activities on the
                                        Services, or otherwise when you contact us.
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>Sensitive Information.</strong>{" "}
                                    <bdt className="block-component" />
                                    We do not process sensitive information.
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <bdt className="else-block" />
                                </span>
                            </span>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component">
                                                    <bdt className="block-component" />
                                                </bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <bdt className="block-component">
                                    <bdt className="block-component" />
                                </bdt>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        All personal information that you provide to us must be true,
                                        complete, and accurate, and you must notify us of any changes to
                                        such personal information.
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                    <strong>Information automatically collected</strong>
                                </span>
                            </span>
                        </div>
                        <div>
                            <div>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <strong>
                                                            <em>In Short:</em>
                                                        </strong>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <strong>
                                                            <em> </em>
                                                        </strong>
                                                        <em>
                                                            Some information — such as your Internet Protocol (IP)
                                                            address and/or browser and device characteristics — is
                                                            collected automatically when you visit our Services.
                                                        </em>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        We automatically collect certain information when you visit, use,
                                        or navigate the Services. This information does not reveal your
                                        specific identity (like your name or contact information) but may
                                        include device and usage information, such as your IP address,
                                        browser and device characteristics, operating system, language
                                        preferences, referring URLs, device name, country, location,
                                        information about how and when you use our Services, and other
                                        technical information. This information is primarily needed to
                                        maintain the security and operation of our Services, and for our
                                        internal analytics and reporting purposes.
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        Like many businesses, we also collect information through cookies
                                        and similar technologies. <bdt className="block-component" />
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor">
                                            <span data-custom-class="body_text" />
                                        </bdt>
                                    </span>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor">
                                                            <bdt className="block-component" />
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>
                                        Information collected when you use our Facebook application(s).
                                    </strong>{" "}
                                    We by default access your{" "}
                                </span>
                                <span data-custom-class="body_text">
                                    Facebook basic account information, including your name, email,
                                    gender, birthday, current city, and profile picture URL, as well as
                                    other information that you choose to make public. We may also
                                    request access to other permissions related to your account, such as
                                    friends, check-ins, and likes, and you may choose to grant or deny
                                    us access to each individual permission. For more information
                                    regarding Facebook permissions, refer to the{" "}
                                </span>
                            </span>
                            <a
                                data-custom-class="link"
                                href="https://developers.facebook.com/docs/facebook-login/permissions"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        Facebook Permissions Reference
                                    </span>
                                </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text"> page.</span>
                            </span>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="infouse" style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                <strong>
                                                    <span data-custom-class="heading_1">
                                                        2. HOW DO WE PROCESS YOUR INFORMATION?
                                                    </span>
                                                </strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div>
                            <div style={{ lineHeight: "1.5" }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <strong>
                                                            <em>In Short: </em>
                                                        </strong>
                                                        <em>
                                                            We process your information to provide, improve, and
                                                            administer our Services, communicate with you, for
                                                            security and fraud prevention, and to comply with law.
                                                            We may also process your information for other purposes
                                                            with your consent.
                                                        </em>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <strong>
                                            We process your personal information for a variety of reasons,
                                            depending on how you interact with our Services, including:
                                        </strong>
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                    <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <bdt className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                        <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                            <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }} />
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                            <span style={{ fontSize: 15 }} />
                                                        </bdt>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                            <bdt className="block-component">
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                            </bdt>
                                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                <bdt className="block-component">
                                                                    <span style={{ fontSize: 15 }} />
                                                                </bdt>
                                                            </p>
                                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                <bdt className="block-component">
                                                                    <span style={{ fontSize: 15 }} />
                                                                </bdt>
                                                            </p>
                                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                <bdt className="block-component" />
                                                            </p>
                                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                <bdt className="block-component" />
                                                            </p>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                                <bdt className="block-component">
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text" />
                                                                    </span>
                                                                </bdt>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                                                                        <span style={{ fontSize: 15 }} />
                                                                    </bdt>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <bdt className="block-component">
                                                                            <span style={{ fontSize: 15 }} />
                                                                        </bdt>
                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <bdt className="block-component">
                                                                                    <span data-custom-class="body_text" />
                                                                                </bdt>
                                                                            </span>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <bdt className="block-component">
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span data-custom-class="body_text" />
                                                                                    </span>
                                                                                </bdt>
                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                    <bdt className="block-component">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text" />
                                                                                        </span>
                                                                                    </bdt>
                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                        <bdt className="block-component">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text" />
                                                                                            </span>
                                                                                        </bdt>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <bdt className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </bdt>
                                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                                <bdt className="block-component">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text" />
                                                                                                    </span>
                                                                                                </bdt>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <bdt className="block-component">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                                        <bdt className="block-component">
                                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                                <span data-custom-class="body_text" />
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                        <div
                                                                                                            style={{ lineHeight: "1.5" }}
                                                                                                        >
                                                                                                            <bdt className="block-component">
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                </span>
                                                                                                            </bdt>
                                                                                                            <div
                                                                                                                style={{ lineHeight: "1.5" }}
                                                                                                            >
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="block-component">
                                                                                                                        <span
                                                                                                                            style={{ fontSize: 15 }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            lineHeight: "1.5"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt className="block-component">
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                            </span>
                                                                                                                        </bdt>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                </span>
                                                                                                                            </bdt>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            id="whoshare"
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "rgb(127, 127, 127)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15,
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                id="control"
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(0, 0, 0)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <strong>
                                                                                                                                                    <span data-custom-class="heading_1">
                                                                                                                                                        3. WHEN
                                                                                                                                                        AND WITH
                                                                                                                                                        WHOM DO WE
                                                                                                                                                        SHARE YOUR
                                                                                                                                                        PERSONAL
                                                                                                                                                        INFORMATION?
                                                                                                                                                    </span>
                                                                                                                                                </strong>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                        <strong>
                                                                                                                                            <em>
                                                                                                                                                In Short:
                                                                                                                                            </em>
                                                                                                                                        </strong>
                                                                                                                                        <em>
                                                                                                                                            {" "}
                                                                                                                                            We may share
                                                                                                                                            information in
                                                                                                                                            specific
                                                                                                                                            situations
                                                                                                                                            described in
                                                                                                                                            this section
                                                                                                                                            and/or with the
                                                                                                                                            following{" "}
                                                                                                                                            <bdt className="block-component" />
                                                                                                                                            third parties.
                                                                                                                                        </em>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                        <bdt className="block-component" />
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                    We{" "}
                                                                                                                                    <bdt className="block-component" />
                                                                                                                                    may need to share
                                                                                                                                    your personal
                                                                                                                                    information in the
                                                                                                                                    following
                                                                                                                                    situations:
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <ul>
                                                                                                                            <li
                                                                                                                                data-custom-class="body_text"
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                        <strong>
                                                                                                                                            Business
                                                                                                                                            Transfers.
                                                                                                                                        </strong>{" "}
                                                                                                                                        We may share or
                                                                                                                                        transfer your
                                                                                                                                        information in
                                                                                                                                        connection with,
                                                                                                                                        or during
                                                                                                                                        negotiations of,
                                                                                                                                        any merger, sale
                                                                                                                                        of company assets,
                                                                                                                                        financing, or
                                                                                                                                        acquisition of all
                                                                                                                                        or a portion of
                                                                                                                                        our business to
                                                                                                                                        another company.
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </bdt>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                        <bdt className="block-component" />
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                        </bdt>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                                <ul>
                                                                                                                                    <li
                                                                                                                                        data-custom-class="body_text"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <strong>
                                                                                                                                                    Affiliates.{" "}
                                                                                                                                                </strong>
                                                                                                                                                We may share
                                                                                                                                                your
                                                                                                                                                information
                                                                                                                                                with our
                                                                                                                                                affiliates, in
                                                                                                                                                which case we
                                                                                                                                                will require
                                                                                                                                                those
                                                                                                                                                affiliates to{" "}
                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                honor
                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                this privacy
                                                                                                                                                notice.
                                                                                                                                                Affiliates
                                                                                                                                                include our
                                                                                                                                                parent company
                                                                                                                                                and any
                                                                                                                                                subsidiaries,
                                                                                                                                                joint venture
                                                                                                                                                partners, or
                                                                                                                                                other
                                                                                                                                                companies that
                                                                                                                                                we control or
                                                                                                                                                that are under
                                                                                                                                                common control
                                                                                                                                                with us.
                                                                                                                                            </span>
                                                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </bdt>
                                                                                                                                        </span>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <bdt className="block-component">
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                        </span>
                                                                                                                                    </bdt>
                                                                                                                                </div>
                                                                                                                                <ul>
                                                                                                                                    <li
                                                                                                                                        data-custom-class="body_text"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <strong>
                                                                                                                                                    Business
                                                                                                                                                    Partners.
                                                                                                                                                </strong>{" "}
                                                                                                                                                We may share
                                                                                                                                                your
                                                                                                                                                information
                                                                                                                                                with our
                                                                                                                                                business
                                                                                                                                                partners to
                                                                                                                                                offer you
                                                                                                                                                certain
                                                                                                                                                products,
                                                                                                                                                services, or
                                                                                                                                                promotions.
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <bdt className="block-component">
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                        </span>
                                                                                                                                    </bdt>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <bdt className="block-component" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ul>
                                                                                                                                        <li
                                                                                                                                            data-custom-class="body_text"
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    "1.5"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <strong>
                                                                                                                                                        Offer
                                                                                                                                                        Wall.{" "}
                                                                                                                                                    </strong>
                                                                                                                                                    Our
                                                                                                                                                    application(s)
                                                                                                                                                    may display
                                                                                                                                                    a
                                                                                                                                                    third-party
                                                                                                                                                    hosted{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "offer
                                                                                                                                                    wall."
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    Such an
                                                                                                                                                    offer wall
                                                                                                                                                    allows
                                                                                                                                                    third-party
                                                                                                                                                    advertisers
                                                                                                                                                    to offer
                                                                                                                                                    virtual
                                                                                                                                                    currency,
                                                                                                                                                    gifts, or
                                                                                                                                                    other items
                                                                                                                                                    to users in
                                                                                                                                                    return for
                                                                                                                                                    the
                                                                                                                                                    acceptance
                                                                                                                                                    and
                                                                                                                                                    completion
                                                                                                                                                    of an
                                                                                                                                                    advertisement
                                                                                                                                                    offer. Such
                                                                                                                                                    an offer
                                                                                                                                                    wall may
                                                                                                                                                    appear in
                                                                                                                                                    our
                                                                                                                                                    application(s)
                                                                                                                                                    and be
                                                                                                                                                    displayed to
                                                                                                                                                    you based on
                                                                                                                                                    certain
                                                                                                                                                    data, such
                                                                                                                                                    as your
                                                                                                                                                    geographic
                                                                                                                                                    area or
                                                                                                                                                    demographic
                                                                                                                                                    information.
                                                                                                                                                    When you
                                                                                                                                                    click on an
                                                                                                                                                    offer wall,
                                                                                                                                                    you will be
                                                                                                                                                    brought to
                                                                                                                                                    an external
                                                                                                                                                    website
                                                                                                                                                    belonging to
                                                                                                                                                    other
                                                                                                                                                    persons and
                                                                                                                                                    will leave
                                                                                                                                                    our
                                                                                                                                                    application(s).
                                                                                                                                                    A unique
                                                                                                                                                    identifier,
                                                                                                                                                    such as your
                                                                                                                                                    user ID,
                                                                                                                                                    will be
                                                                                                                                                    shared with
                                                                                                                                                    the offer
                                                                                                                                                    wall
                                                                                                                                                    provider in
                                                                                                                                                    order to
                                                                                                                                                    prevent
                                                                                                                                                    fraud and
                                                                                                                                                    properly
                                                                                                                                                    credit your
                                                                                                                                                    account with
                                                                                                                                                    the relevant
                                                                                                                                                    reward.
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                                </span>
                                                                                                                                            </bdt>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                <span data-custom-class="heading_1" />
                                                                                                                                                            </bdt>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="cookies"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    4.
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    USE
                                                                                                                                                                    COOKIES
                                                                                                                                                                    AND
                                                                                                                                                                    OTHER
                                                                                                                                                                    TRACKING
                                                                                                                                                                    TECHNOLOGIES?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        {" "}
                                                                                                                                                        We may use
                                                                                                                                                        cookies
                                                                                                                                                        and other
                                                                                                                                                        tracking
                                                                                                                                                        technologies
                                                                                                                                                        to collect
                                                                                                                                                        and store
                                                                                                                                                        your
                                                                                                                                                        information.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We may use
                                                                                                                                                    cookies and
                                                                                                                                                    similar
                                                                                                                                                    tracking
                                                                                                                                                    technologies
                                                                                                                                                    (like web
                                                                                                                                                    beacons and
                                                                                                                                                    pixels) to
                                                                                                                                                    access or
                                                                                                                                                    store
                                                                                                                                                    information.
                                                                                                                                                    Specific
                                                                                                                                                    information
                                                                                                                                                    about how we
                                                                                                                                                    use such
                                                                                                                                                    technologies
                                                                                                                                                    and how you
                                                                                                                                                    can refuse
                                                                                                                                                    certain
                                                                                                                                                    cookies is
                                                                                                                                                    set out in
                                                                                                                                                    our Cookie
                                                                                                                                                    Notice
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                            .
                                                                                                                                                        </span>
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            fontSize: 15
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                color:
                                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            color:
                                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color:
                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="sociallogins"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    5.
                                                                                                                                                                    HOW
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    HANDLE
                                                                                                                                                                    YOUR
                                                                                                                                                                    SOCIAL
                                                                                                                                                                    LOGINS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:{" "}
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        If you
                                                                                                                                                        choose to
                                                                                                                                                        register
                                                                                                                                                        or log in
                                                                                                                                                        to our
                                                                                                                                                        Services
                                                                                                                                                        using a
                                                                                                                                                        social
                                                                                                                                                        media
                                                                                                                                                        account,
                                                                                                                                                        we may
                                                                                                                                                        have
                                                                                                                                                        access to
                                                                                                                                                        certain
                                                                                                                                                        information
                                                                                                                                                        about you.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    Our Services
                                                                                                                                                    offer you
                                                                                                                                                    the ability
                                                                                                                                                    to register
                                                                                                                                                    and log in
                                                                                                                                                    using your
                                                                                                                                                    third-party
                                                                                                                                                    social media
                                                                                                                                                    account
                                                                                                                                                    details
                                                                                                                                                    (like your
                                                                                                                                                    Facebook or
                                                                                                                                                    Twitter
                                                                                                                                                    logins).
                                                                                                                                                    Where you
                                                                                                                                                    choose to do
                                                                                                                                                    this, we
                                                                                                                                                    will receive
                                                                                                                                                    certain
                                                                                                                                                    profile
                                                                                                                                                    information
                                                                                                                                                    about you
                                                                                                                                                    from your
                                                                                                                                                    social media
                                                                                                                                                    provider.
                                                                                                                                                    The profile
                                                                                                                                                    information
                                                                                                                                                    we receive
                                                                                                                                                    may vary
                                                                                                                                                    depending on
                                                                                                                                                    the social
                                                                                                                                                    media
                                                                                                                                                    provider
                                                                                                                                                    concerned,
                                                                                                                                                    but will
                                                                                                                                                    often
                                                                                                                                                    include your
                                                                                                                                                    name, email
                                                                                                                                                    address,
                                                                                                                                                    friends
                                                                                                                                                    list, and
                                                                                                                                                    profile
                                                                                                                                                    picture, as
                                                                                                                                                    well as
                                                                                                                                                    other
                                                                                                                                                    information
                                                                                                                                                    you choose
                                                                                                                                                    to make
                                                                                                                                                    public on
                                                                                                                                                    such a
                                                                                                                                                    social media
                                                                                                                                                    platform.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>{" "}
                                                                                                                                                    </span>
                                                                                                                                                    If you log
                                                                                                                                                    in using
                                                                                                                                                    Facebook, we
                                                                                                                                                    may also
                                                                                                                                                    request
                                                                                                                                                    access to
                                                                                                                                                    other
                                                                                                                                                    permissions
                                                                                                                                                    related to
                                                                                                                                                    your
                                                                                                                                                    account,
                                                                                                                                                    such as your
                                                                                                                                                    friends,
                                                                                                                                                    check-ins,
                                                                                                                                                    and likes,
                                                                                                                                                    and you may
                                                                                                                                                    choose to
                                                                                                                                                    grant or
                                                                                                                                                    deny us
                                                                                                                                                    access to
                                                                                                                                                    each
                                                                                                                                                    individual
                                                                                                                                                    permission.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color:
                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We will use
                                                                                                                                                    the
                                                                                                                                                    information
                                                                                                                                                    we receive
                                                                                                                                                    only for the
                                                                                                                                                    purposes
                                                                                                                                                    that are
                                                                                                                                                    described in
                                                                                                                                                    this privacy
                                                                                                                                                    notice or
                                                                                                                                                    that are
                                                                                                                                                    otherwise
                                                                                                                                                    made clear
                                                                                                                                                    to you on
                                                                                                                                                    the relevant
                                                                                                                                                    Services.
                                                                                                                                                    Please note
                                                                                                                                                    that we do
                                                                                                                                                    not control,
                                                                                                                                                    and are not
                                                                                                                                                    responsible
                                                                                                                                                    for, other
                                                                                                                                                    uses of your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    by your
                                                                                                                                                    third-party
                                                                                                                                                    social media
                                                                                                                                                    provider. We
                                                                                                                                                    recommend
                                                                                                                                                    that you
                                                                                                                                                    review their
                                                                                                                                                    privacy
                                                                                                                                                    notice to
                                                                                                                                                    understand
                                                                                                                                                    how they
                                                                                                                                                    collect,
                                                                                                                                                    use, and
                                                                                                                                                    share your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    and how you
                                                                                                                                                    can set your
                                                                                                                                                    privacy
                                                                                                                                                    preferences
                                                                                                                                                    on their
                                                                                                                                                    sites and
                                                                                                                                                    apps.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            fontSize: 15
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                color:
                                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        color:
                                                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <bdt className="block-component">
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="intltransfers"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    6.
                                                                                                                                                                    IS
                                                                                                                                                                    YOUR
                                                                                                                                                                    INFORMATION
                                                                                                                                                                    TRANSFERRED
                                                                                                                                                                    INTERNATIONALLY?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:{" "}
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        We may
                                                                                                                                                        transfer,
                                                                                                                                                        store, and
                                                                                                                                                        process
                                                                                                                                                        your
                                                                                                                                                        information
                                                                                                                                                        in
                                                                                                                                                        countries
                                                                                                                                                        other than
                                                                                                                                                        your own.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        data-custom-class="body_text"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    Our servers
                                                                                                                                                    are located
                                                                                                                                                    in
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="forloop-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                . If
                                                                                                                                                                you
                                                                                                                                                                are
                                                                                                                                                                accessing
                                                                                                                                                                our
                                                                                                                                                                Services
                                                                                                                                                                from
                                                                                                                                                                outside
                                                                                                                                                            </span>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            color:
                                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                ,
                                                                                                                                                                please
                                                                                                                                                                be
                                                                                                                                                                aware
                                                                                                                                                                that
                                                                                                                                                                your
                                                                                                                                                                information
                                                                                                                                                                may be
                                                                                                                                                                transferred
                                                                                                                                                                to,
                                                                                                                                                                stored,
                                                                                                                                                                and
                                                                                                                                                                processed
                                                                                                                                                                by us
                                                                                                                                                                in our
                                                                                                                                                                facilities
                                                                                                                                                                and by
                                                                                                                                                                those
                                                                                                                                                                third
                                                                                                                                                                parties
                                                                                                                                                                with
                                                                                                                                                                whom
                                                                                                                                                                we may
                                                                                                                                                                share
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                (see{" "}
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                "
                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="#whoshare"
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(0, 58, 250)"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                WHEN
                                                                                                                                                                AND
                                                                                                                                                                WITH
                                                                                                                                                                WHOM
                                                                                                                                                                DO WE
                                                                                                                                                                SHARE
                                                                                                                                                                YOUR
                                                                                                                                                                PERSONAL
                                                                                                                                                                INFORMATION?
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                "
                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                above),
                                                                                                                                                                in
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="forloop-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                {" "}
                                                                                                                                                                and
                                                                                                                                                                other
                                                                                                                                                                countries.
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    If you are a
                                                                                                                                                    resident in
                                                                                                                                                    the European
                                                                                                                                                    Economic
                                                                                                                                                    Area (EEA),
                                                                                                                                                    United
                                                                                                                                                    Kingdom
                                                                                                                                                    (UK), or
                                                                                                                                                    Switzerland,
                                                                                                                                                    then these
                                                                                                                                                    countries
                                                                                                                                                    may not
                                                                                                                                                    necessarily
                                                                                                                                                    have data
                                                                                                                                                    protection
                                                                                                                                                    laws or
                                                                                                                                                    other
                                                                                                                                                    similar laws
                                                                                                                                                    as
                                                                                                                                                    comprehensive
                                                                                                                                                    as those in
                                                                                                                                                    your
                                                                                                                                                    country.
                                                                                                                                                    However, we
                                                                                                                                                    will take
                                                                                                                                                    all
                                                                                                                                                    necessary
                                                                                                                                                    measures to
                                                                                                                                                    protect your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    in
                                                                                                                                                    accordance
                                                                                                                                                    with this
                                                                                                                                                    privacy
                                                                                                                                                    notice and
                                                                                                                                                    applicable
                                                                                                                                                    law.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            color:
                                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                                            fontSize: 15
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color:
                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color:
                                                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="inforetain"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    7.
                                                                                                                                                                    HOW
                                                                                                                                                                    LONG
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    KEEP
                                                                                                                                                                    YOUR
                                                                                                                                                                    INFORMATION?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:{" "}
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        We keep
                                                                                                                                                        your
                                                                                                                                                        information
                                                                                                                                                        for as
                                                                                                                                                        long as
                                                                                                                                                        necessary
                                                                                                                                                        to{" "}
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        fulfill
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                        the
                                                                                                                                                        purposes
                                                                                                                                                        outlined
                                                                                                                                                        in this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        unless
                                                                                                                                                        otherwise
                                                                                                                                                        required
                                                                                                                                                        by law.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We will only
                                                                                                                                                    keep your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    for as long
                                                                                                                                                    as it is
                                                                                                                                                    necessary
                                                                                                                                                    for the
                                                                                                                                                    purposes set
                                                                                                                                                    out in this
                                                                                                                                                    privacy
                                                                                                                                                    notice,
                                                                                                                                                    unless a
                                                                                                                                                    longer
                                                                                                                                                    retention
                                                                                                                                                    period is
                                                                                                                                                    required or
                                                                                                                                                    permitted by
                                                                                                                                                    law (such as
                                                                                                                                                    tax,
                                                                                                                                                    accounting,
                                                                                                                                                    or other
                                                                                                                                                    legal
                                                                                                                                                    requirements).
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>{" "}
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    When we have
                                                                                                                                                    no ongoing
                                                                                                                                                    legitimate
                                                                                                                                                    business
                                                                                                                                                    need to
                                                                                                                                                    process your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    we will
                                                                                                                                                    either
                                                                                                                                                    delete or{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    anonymize
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    such
                                                                                                                                                    information,
                                                                                                                                                    or, if this
                                                                                                                                                    is not
                                                                                                                                                    possible
                                                                                                                                                    (for
                                                                                                                                                    example,
                                                                                                                                                    because your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    has been
                                                                                                                                                    stored in
                                                                                                                                                    backup
                                                                                                                                                    archives),
                                                                                                                                                    then we will
                                                                                                                                                    securely
                                                                                                                                                    store your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    and isolate
                                                                                                                                                    it from any
                                                                                                                                                    further
                                                                                                                                                    processing
                                                                                                                                                    until
                                                                                                                                                    deletion is
                                                                                                                                                    possible.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="infominors"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    8.
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    COLLECT
                                                                                                                                                                    INFORMATION
                                                                                                                                                                    FROM
                                                                                                                                                                    MINORS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        {" "}
                                                                                                                                                        We do not
                                                                                                                                                        knowingly
                                                                                                                                                        collect
                                                                                                                                                        data from
                                                                                                                                                        or market
                                                                                                                                                        to{" "}
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        children
                                                                                                                                                        under 18
                                                                                                                                                        years of
                                                                                                                                                        age
                                                                                                                                                        <bdt className="else-block" />
                                                                                                                                                        .
                                                                                                                                                    </em>
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We do not
                                                                                                                                                    knowingly
                                                                                                                                                    solicit data
                                                                                                                                                    from or
                                                                                                                                                    market to
                                                                                                                                                    children
                                                                                                                                                    under 18
                                                                                                                                                    years of
                                                                                                                                                    age. By
                                                                                                                                                    using the
                                                                                                                                                    Services,
                                                                                                                                                    you
                                                                                                                                                    represent
                                                                                                                                                    that you are
                                                                                                                                                    at least 18
                                                                                                                                                    or that you
                                                                                                                                                    are the
                                                                                                                                                    parent or
                                                                                                                                                    guardian of
                                                                                                                                                    such a minor
                                                                                                                                                    and consent
                                                                                                                                                    to such
                                                                                                                                                    minor
                                                                                                                                                    dependent’s
                                                                                                                                                    use of the
                                                                                                                                                    Services. If
                                                                                                                                                    we learn
                                                                                                                                                    that
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    from users
                                                                                                                                                    less than 18
                                                                                                                                                    years of age
                                                                                                                                                    has been
                                                                                                                                                    collected,
                                                                                                                                                    we will
                                                                                                                                                    deactivate
                                                                                                                                                    the account
                                                                                                                                                    and take
                                                                                                                                                    reasonable
                                                                                                                                                    measures to
                                                                                                                                                    promptly
                                                                                                                                                    delete such
                                                                                                                                                    data from
                                                                                                                                                    our records.
                                                                                                                                                    If you
                                                                                                                                                    become aware
                                                                                                                                                    of any data
                                                                                                                                                    we may have
                                                                                                                                                    collected
                                                                                                                                                    from
                                                                                                                                                    children
                                                                                                                                                    under age
                                                                                                                                                    18, please
                                                                                                                                                    contact us
                                                                                                                                                    at{" "}
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                            <bdt className="question">
                                                                                                                                                                __________
                                                                                                                                                            </bdt>
                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="else-block">
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </bdt>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="privacyrights"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    9.
                                                                                                                                                                    WHAT
                                                                                                                                                                    ARE
                                                                                                                                                                    YOUR
                                                                                                                                                                    PRIVACY
                                                                                                                                                                    RIGHTS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        {" "}
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <em>
                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                    </em>
                                                                                                                                                                </span>
                                                                                                                                                            </span>{" "}
                                                                                                                                                        </span>
                                                                                                                                                        You may
                                                                                                                                                        review,
                                                                                                                                                        change, or
                                                                                                                                                        terminate
                                                                                                                                                        your
                                                                                                                                                        account at
                                                                                                                                                        any time.
                                                                                                                                                    </em>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                            </bdt>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="withdrawconsent"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <u>
                                                                                                                                                            Withdrawing
                                                                                                                                                            your
                                                                                                                                                            consent:
                                                                                                                                                        </u>
                                                                                                                                                    </strong>{" "}
                                                                                                                                                    If we are
                                                                                                                                                    relying on
                                                                                                                                                    your consent
                                                                                                                                                    to process
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    <bdt className="block-component" />{" "}
                                                                                                                                                    which may be
                                                                                                                                                    express
                                                                                                                                                    and/or
                                                                                                                                                    implied
                                                                                                                                                    consent
                                                                                                                                                    depending on
                                                                                                                                                    the
                                                                                                                                                    applicable
                                                                                                                                                    law,
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    you have the
                                                                                                                                                    right to
                                                                                                                                                    withdraw
                                                                                                                                                    your consent
                                                                                                                                                    at any time.
                                                                                                                                                    You can
                                                                                                                                                    withdraw
                                                                                                                                                    your consent
                                                                                                                                                    at any time
                                                                                                                                                    by
                                                                                                                                                    contacting
                                                                                                                                                    us by using
                                                                                                                                                    the contact
                                                                                                                                                    details
                                                                                                                                                    provided in
                                                                                                                                                    the section{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <a
                                                                                                                                            data-custom-class="link"
                                                                                                                                            href="#contact"
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(0, 58, 250)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(0, 58, 250)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        HOW CAN
                                                                                                                                                        YOU
                                                                                                                                                        CONTACT US
                                                                                                                                                        ABOUT THIS
                                                                                                                                                        NOTICE?
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </a>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    below
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                However,
                                                                                                                                                please note
                                                                                                                                                that this will
                                                                                                                                                not affect the
                                                                                                                                                lawfulness of
                                                                                                                                                the processing
                                                                                                                                                before its
                                                                                                                                                withdrawal
                                                                                                                                                nor,
                                                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                                                when
                                                                                                                                                applicable law
                                                                                                                                                allows,
                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                will it affect
                                                                                                                                                the processing
                                                                                                                                                of your
                                                                                                                                                personal
                                                                                                                                                information
                                                                                                                                                conducted in
                                                                                                                                                reliance on
                                                                                                                                                lawful
                                                                                                                                                processing
                                                                                                                                                grounds other
                                                                                                                                                than consent.
                                                                                                                                                <bdt className="block-component" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color:
                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="DNT"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    10.
                                                                                                                                                                    CONTROLS
                                                                                                                                                                    FOR
                                                                                                                                                                    DO-NOT-TRACK
                                                                                                                                                                    FEATURES
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    Most web
                                                                                                                                                    browsers and
                                                                                                                                                    some mobile
                                                                                                                                                    operating
                                                                                                                                                    systems and
                                                                                                                                                    mobile
                                                                                                                                                    applications
                                                                                                                                                    include a
                                                                                                                                                    Do-Not-Track
                                                                                                                                                    (
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "DNT"
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    ) feature or
                                                                                                                                                    setting you
                                                                                                                                                    can activate
                                                                                                                                                    to signal
                                                                                                                                                    your privacy
                                                                                                                                                    preference
                                                                                                                                                    not to have
                                                                                                                                                    data about
                                                                                                                                                    your online
                                                                                                                                                    browsing
                                                                                                                                                    activities
                                                                                                                                                    monitored
                                                                                                                                                    and
                                                                                                                                                    collected.
                                                                                                                                                    At this
                                                                                                                                                    stage no
                                                                                                                                                    uniform
                                                                                                                                                    technology
                                                                                                                                                    standard for{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    recognizing
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    and
                                                                                                                                                    implementing
                                                                                                                                                    DNT signals
                                                                                                                                                    has been{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    finalized
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    . As such,
                                                                                                                                                    we do not
                                                                                                                                                    currently
                                                                                                                                                    respond to
                                                                                                                                                    DNT browser
                                                                                                                                                    signals or
                                                                                                                                                    any other
                                                                                                                                                    mechanism
                                                                                                                                                    that
                                                                                                                                                    automatically
                                                                                                                                                    communicates
                                                                                                                                                    your choice
                                                                                                                                                    not to be
                                                                                                                                                    tracked
                                                                                                                                                    online. If a
                                                                                                                                                    standard for
                                                                                                                                                    online
                                                                                                                                                    tracking is
                                                                                                                                                    adopted that
                                                                                                                                                    we must
                                                                                                                                                    follow in
                                                                                                                                                    the future,
                                                                                                                                                    we will
                                                                                                                                                    inform you
                                                                                                                                                    about that
                                                                                                                                                    practice in
                                                                                                                                                    a revised
                                                                                                                                                    version of
                                                                                                                                                    this privacy
                                                                                                                                                    notice.
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </bdt>
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </bdt>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="policyupdates"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    11.
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    MAKE
                                                                                                                                                                    UPDATES
                                                                                                                                                                    TO
                                                                                                                                                                    THIS
                                                                                                                                                                    NOTICE?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <em>
                                                                                                                                                        <strong>
                                                                                                                                                            In
                                                                                                                                                            Short:{" "}
                                                                                                                                                        </strong>
                                                                                                                                                        Yes, we
                                                                                                                                                        will
                                                                                                                                                        update
                                                                                                                                                        this
                                                                                                                                                        notice as
                                                                                                                                                        necessary
                                                                                                                                                        to stay
                                                                                                                                                        compliant
                                                                                                                                                        with
                                                                                                                                                        relevant
                                                                                                                                                        laws.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We may
                                                                                                                                                    update this
                                                                                                                                                    privacy
                                                                                                                                                    notice from
                                                                                                                                                    time to
                                                                                                                                                    time. The
                                                                                                                                                    updated
                                                                                                                                                    version will
                                                                                                                                                    be indicated
                                                                                                                                                    by an
                                                                                                                                                    updated{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "Revised"
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    date and the
                                                                                                                                                    updated
                                                                                                                                                    version will
                                                                                                                                                    be effective
                                                                                                                                                    as soon as
                                                                                                                                                    it is
                                                                                                                                                    accessible.
                                                                                                                                                    If we make
                                                                                                                                                    material
                                                                                                                                                    changes to
                                                                                                                                                    this privacy
                                                                                                                                                    notice, we
                                                                                                                                                    may notify
                                                                                                                                                    you either
                                                                                                                                                    by
                                                                                                                                                    prominently
                                                                                                                                                    posting a
                                                                                                                                                    notice of
                                                                                                                                                    such changes
                                                                                                                                                    or by
                                                                                                                                                    directly
                                                                                                                                                    sending you
                                                                                                                                                    a
                                                                                                                                                    notification.
                                                                                                                                                    We encourage
                                                                                                                                                    you to
                                                                                                                                                    review this
                                                                                                                                                    privacy
                                                                                                                                                    notice
                                                                                                                                                    frequently
                                                                                                                                                    to be
                                                                                                                                                    informed of
                                                                                                                                                    how we are
                                                                                                                                                    protecting
                                                                                                                                                    your
                                                                                                                                                    information.
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="contact"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    12.
                                                                                                                                                                    HOW
                                                                                                                                                                    CAN
                                                                                                                                                                    YOU
                                                                                                                                                                    CONTACT
                                                                                                                                                                    US
                                                                                                                                                                    ABOUT
                                                                                                                                                                    THIS
                                                                                                                                                                    NOTICE?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    If you have
                                                                                                                                                    questions or
                                                                                                                                                    comments
                                                                                                                                                    about this
                                                                                                                                                    notice, you
                                                                                                                                                    may{" "}
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                <bdt className="block-component">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                </bdt>
                                                                                                                                                            </bdt>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                contact
                                                                                                                                                                us by
                                                                                                                                                                post
                                                                                                                                                                at:
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="question">
                                                                                                                                                                        diar
                                                                                                                                                                        dzair
                                                                                                                                                                    </bdt>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <bdt className="question">
                                                                                                                                                    __________
                                                                                                                                                </bdt>
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <bdt className="question">
                                                                                                                                                    __________
                                                                                                                                                </bdt>
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <bdt className="block-component">
                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                        </bdt>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </bdt>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                            <bdt className="block-component">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                />
                                                                                                                                            </bdt>
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                </bdt>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="request"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(127, 127, 127)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    13.
                                                                                                                                                                    HOW
                                                                                                                                                                    CAN
                                                                                                                                                                    YOU
                                                                                                                                                                    REVIEW,
                                                                                                                                                                    UPDATE,
                                                                                                                                                                    OR
                                                                                                                                                                    DELETE
                                                                                                                                                                    THE
                                                                                                                                                                    DATA
                                                                                                                                                                    WE
                                                                                                                                                                    COLLECT
                                                                                                                                                                    FROM
                                                                                                                                                                    YOU?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    Based on the
                                                                                                                                                    applicable
                                                                                                                                                    laws of your
                                                                                                                                                    country, you
                                                                                                                                                    may have the
                                                                                                                                                    right to
                                                                                                                                                    request
                                                                                                                                                    access to
                                                                                                                                                    the personal
                                                                                                                                                    information
                                                                                                                                                    we collect
                                                                                                                                                    from you,
                                                                                                                                                    change that
                                                                                                                                                    information,
                                                                                                                                                    or delete
                                                                                                                                                    it.{" "}
                                                                                                                                                    <bdt className="else-block">
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        To request
                                                                                                                                                        to review,
                                                                                                                                                        update, or
                                                                                                                                                        delete
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information,
                                                                                                                                                        please{" "}
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        fill out
                                                                                                                                                        and submit
                                                                                                                                                        a{" "}
                                                                                                                                                    </bdt>
                                                                                                                                                </span>
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            "rgb(0, 58, 250)"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 58, 250)",
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <a
                                                                                                                                                                data-custom-class="link"
                                                                                                                                                                href="https://app.termly.io/notify/aa9a8d8e-573d-4a14-937c-4dbdd7c00a90"
                                                                                                                                                                rel="noopener noreferrer"
                                                                                                                                                                target="_blank"
                                                                                                                                                            >
                                                                                                                                                                data
                                                                                                                                                                subject
                                                                                                                                                                access
                                                                                                                                                                request
                                                                                                                                                            </a>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                   
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






        </div>
    )
}

export default PrivacyPolicy