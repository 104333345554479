import { useEffect, useState, useContext , useRef} from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import LangContext from '../../Context/lang';
import ReCAPTCHA from "react-google-recaptcha";


const Register = ({ Alert, setAlert }) => {

  const navigate = useNavigate();

  const [email, setemail] = useState('')
  const [userName, setuserName] = useState('')
  const [pass, setpass] = useState('')
  const [repass, setrepass] = useState('')
  const [err, seterr] = useState(false)
  const [loading, setloading] = useState(false)

  const { Lang, Global, Account,confirmation } = useContext(LangContext);

  const [Showerr, setShowerr] = useState(false)



  const recaptchaRef = useRef('');
  const [CaptchaChecked, setCaptchaChecked] = useState(false)
  const [captchaerror, setcaptchaerror] = useState('')
  function onChange(value) {
    // console.log("Captcha value:", value);
    setCaptchaChecked(true)
  }

  useEffect(() => {

    // setcaptchaerror(CaptchaChecked ? '' : confirmation['captcha'][Lang])


  }, [CaptchaChecked, Lang])

  const errText = {
    "email" : {'fr' : "Veuillez vérifier votre Email d'utilisateur Min(2)Mots - Max(20)Mots" , "ar" : "يرجى التأكد من البريد الإلكتروني الحد الأدنى (2) كلمات الحد الأقصى (20) كلمة"  },
    "username" : {'fr' : "Veuillez vérifier votre nom d'utilisateur Min(2)Mots - Max(20)Mots" , "ar" : "يرجى التأكد من إسم المستخدم الحد الأدنى (2) كلمات الحد الأقصى (20) كلمة"  },
    "password" : {"fr" : "Veuillez vérifier le mot de passe Min(2)Mots -  Max(20)Mots" , "ar" : " يرجى التأكد من كلمة المرور الحد الأدنى (2) كلمات الحد الأقصى (20) كلمة" },
    "repassword" : {"fr" : "Les mots de passe doivent être identiques" , "ar" : "كلمات المرور يجب أن تكون متطابقة" },

  }

  const errTextapi = {
    "username" : {'fr' : "l'utilisateur n'existe pas" , "ar" : "هذا المستخدم غير موجود"},
    "password" : {"fr" : 'Le mot de passe est incorrect' , "ar" : 'كلمة السر غير صحيحة'}
  }




  function Register(e) {
    
    e.preventDefault();
    setShowerr(true)


    if (!email?.trim() || email?.trim()?.length < 2 || email?.trim()?.length > 20) {

      // console.log('erruser');

      return
      
    }


    if (!userName?.trim() || userName?.trim()?.length < 2 || userName?.trim()?.length > 20) {

      // console.log('erruser');

      return
      
    }

    if (!pass?.trim() || pass?.trim()?.length < 2 || pass?.trim()?.length > 20) {

      // console.log('errpass');


      return
      
    }

    if (pass?.trim() !== repass?.trim()) {

      // console.log('errpass');


      return
      
    }

    // console.log(email, userName, pass);

    setloading(true)


    axios.post(`https://opr.diardzair.com.dz/alogin/rest/register`, { "username": userName, "email": email, "password": pass }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(function (response) {

        console.log(response.data);

        if (response.data['errorCode'] == 1) {
          setloading(false)
          setAlert( Lang === "fr" ? 'Compte créé avec succès' : 'تم إنشاء حسابك بنجاح')
          setCaptchaChecked(false)

          return navigate('/account?page=login')
        }

        if (response.data['errorCode'] == "-5") {
          seterr(Lang === "fr" ? "l'email existe déjà" : "هذا البريد الإلكتروني مستعمل من قبل")
          recaptchaRef.current.reset();
          setCaptchaChecked(false)
        } else if (response.data['errorCode'] == "-4") {
          seterr(Lang === "fr" ? "L'utilisateur existe déjà" : "إسم المستخدم مستعمل من قبل" )
          recaptchaRef.current.reset();
          setCaptchaChecked(false)
        }

        setloading(false)


      })
      .catch(function (error) {
        setloading(false)
        recaptchaRef.current.reset();
        setCaptchaChecked(false)
        // console.log(error);
      });
  }


  return (

    <div className='container'>
      {err &&
        <div className="alert alert-danger" role="alert">
          {err}
        </div>}
      {loading && <div className="screen">
        <div className="loader">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>}
      <form className="row g-3 m-auto justify-content-center" onSubmit={Register}>
        <div className="col-md-8">
          <label htmlFor="inputEmail" className="form-label">{(Account['e-mail'][Lang])}</label>
          <input maxLength={20} type="email" required onChange={(e) => setemail(e.target.value)} className="form-control" id="inputEmail" />
          <div className='err_login'>{(!email?.trim() || email?.trim()?.length < 2 || email?.trim()?.length > 20) && Showerr ? errText?.email[Lang] : "" }</div>

        </div>
        <div className="col-md-8">
          <label htmlFor="inputusername" className="form-label">{(Account['Nomdutilisateur'][Lang])}</label>
          <input maxLength={30} type="text" required onChange={(e) => setuserName(e.target.value)} className="form-control" id="inputusername" />
          <div className='err_login'>{(!userName?.trim() || userName?.trim()?.length < 2 || userName?.trim()?.length > 20) && Showerr ? errText?.username[Lang] : "" }</div>
        </div>
        <div className="col-md-8">
          <label htmlFor="inputPasswordone" className="form-label">{(Account['MotdePasse'][Lang])}</label>
          <input maxLength={30} type="password" required onChange={(e) => setpass(e.target.value)} className="form-control" id="inputPasswordone" />
          <div className='err_login'>{(!pass?.trim() || pass?.trim()?.length < 2 || pass?.trim()?.length > 20) && Showerr ? errText?.password[Lang] : "" }</div>
        </div>
        <div className="col-md-8">
          <label htmlFor="inputPasswordtwo" className="form-label">{(Account['Répéterlemotdepasse'][Lang])}</label>
          <input maxLength={30} type="password" required onChange={(e) => setrepass(e.target.value)} className="form-control" id="inputPasswordtwo" />
          <div className='err_login'>{(pass?.trim() !== repass?.trim())  && Showerr ? errText?.repassword[Lang] : "" }</div>
        </div>

        <div className="col-8">
          {CaptchaChecked ? <button type="submit" className="login-btn">{(Account['Créeruncompte'][Lang])}</button> :<button type="button" className="login-btn">{(Account['Créeruncompte'][Lang])}</button> }
        </div>

        <div className='text-center d-flex justify-content-center'>


        <ReCAPTCHA
          sitekey="6Lel8MshAAAAABqg5dtbZL6Tlm1mpRJnO8m8qz68"
          onChange={onChange}
          onExpired={()=> setCaptchaChecked(false)}
          ref={recaptchaRef}
        />
      </div>
      <div className='text-center captcha-err' dir={Lang === 'fr' ? 'ltr' : 'rtl'}> {captchaerror} </div>
        
        {/* <div className="col-12 text-center">
          <button type="submit" className="btn-google login-btn-fb">
            <svg xmlns="https://www.w3.org/2000/svg" width="38.25" height="39.027" viewBox="0 0 38.25 39.027">
              <g id="_7123025_logo_google_g_icon" data-name="7123025_logo_google_g_icon" transform="translate(-30.025 -29.098)">
                <path id="Tracé_249" data-name="Tracé 249" d="M94.648,70.754a24.405,24.405,0,0,0-.34-3.954H75.9v7.525H86.443a8.869,8.869,0,0,1-3.911,5.909l6.292,4.889a19.189,19.189,0,0,0,5.824-14.369Z" transform="translate(-26.373 -21.674)" fill="#4280ef" />
                <path id="Tracé_250" data-name="Tracé 250" d="M52.33,99.629a18.667,18.667,0,0,0,12.924-4.719l-6.292-4.846a11.836,11.836,0,0,1-17.6-6.164L34.9,88.874A19.494,19.494,0,0,0,52.33,99.629Z" transform="translate(-2.803 -31.505)" fill="#34a353" />
                <path id="Tracé_251" data-name="Tracé 251" d="M38.559,66.9a11.854,11.854,0,0,1,0-7.482L32.1,54.4a19.539,19.539,0,0,0,0,17.515Z" transform="translate(0 -14.546)" fill="#f6b704" />
                <path id="Tracé_252" data-name="Tracé 252" d="M52.33,36.836a10.646,10.646,0,0,1,7.482,2.933l5.569-5.612A18.779,18.779,0,0,0,52.33,29.1,19.494,19.494,0,0,0,34.9,39.854l6.462,5.016A11.673,11.673,0,0,1,52.33,36.836Z" transform="translate(-2.803 0)" fill="#e54335" />
              </g>
            </svg>

            Continuer avec Google</button>
        </div> */}
        
      </form>
    </div>

  )
}

export default Register