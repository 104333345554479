// FallbackComponent.js
import React from 'react';

const FallbackComponent = ({ error, resetErrorBoundary }) => {
  return (
    <div>
      {/* <h2>Something went wrong: {error.message}</h2>
      <button onClick={resetErrorBoundary}>Try Again</button> */}

      <div className={`centered-logo`}>
            <img className='' alt="logodiardzair" src={`/img/${"logo.png"}`} width="150" />
            <div className="loadingPage-text">جاري التحميل <br />S'il vous plaît, attendez</div>

        </div>
    </div>
  );
};

export default FallbackComponent;
