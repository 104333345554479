import { useState, useEffect, useContext } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import Menu from '../../components/Dashboard/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';



const MesCommandes = () => {

  const { Auth, setAuth } = useContext(AuthContext);
  let navigate = useNavigate();

  

  const usememail = localStorage.getItem('Token')?.email ?  localStorage.getItem('Token')?.email : '';


  useEffect(() => {




    if (Auth) {
      // console.log('hi!');

    } else {
      // console.log('no!');
      return navigate("/");
    }


  }, [Auth])
  

  const [MyCommandes, setMyCommandes] = useState([])
  const [CurrentData, setCurrentData] = useState({})
  const [TypeDar, setTypeDar] = useState('01')
  const [Loading, setLoading] = useState(true)

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/compte/info?username=${usememail}&type=${TypeDar}`, {}, {
          headers: {
            'Authorization': `Bearer ${Auth?.token}`
          }
        });
        // console.log(response[0]);
        setMyCommandes(response['0']?.slice(0, 20));
        // console.log(DarTaksitCat)

      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }

    fetchData();

  }, [TypeDar]);

  // console.log(MyCommandes);


  const [MenuShow, setMenuShow] = useState(true)
  // console.log(MenuShow);
  return (
    <div className='dashboard pb-5 '>


      <div className=''>
        <div className=''>
          <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />
        </div>
        <div className='page-client pt-lg-5 ' style={{ marginLeft: MenuShow == true ? '328px' : '89px' }}>
          <div className='menu-mobile-section'>
            <svg onClick={() => setMenuShow((p) => !p)} xmlns="https://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
              <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" strokeWidth="2" />
                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" strokeWidth="2" />
                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" strokeWidth="2" />
              </g>
            </svg>
          </div>
          <div className='page-title'>Mes Commandes <span>Mes Information</span></div>

          <div className='container'>
          <div className='diar-tabs mt-5 flex-column flex-md-row'>
            <div onClick={()=> setTypeDar('01') } className={`dar-tab ${TypeDar == '01' ? 'dar-tab-active' : ''}`}> {TypeDar == '01' && <div className='r-btn me-3'></div>} Dar Taksit</div>
            <div onClick={()=> setTypeDar('02') } className={`dar-tab ${TypeDar == '02' ? 'dar-tab-active' : ''}`}> {TypeDar == '02' && <div className='r-btn me-3'></div>} Dar I3mar</div>
            <div onClick={()=> setTypeDar('03') } className={`dar-tab ${TypeDar == '03' ? 'dar-tab-active' : ''}`}> {TypeDar == '03' && <div className='r-btn me-3'></div>} Dar Idjar</div>

          </div>
          </div>


          <div className='dashboard-table'>
            <table>

              <thead>
                <tr>
                  <th scope="col">Numéro de demande</th>
                  <th scope="col">Date de la demande</th>
                  <th scope="col">Durée</th>
                  <th scope="col">Le montant total</th>
                  <th scope="col">Voir</th>
                  {/* <th scope="col">Plus de détails</th> */}
                </tr>
              </thead>

             
              {!Loading && <tbody>

                {MyCommandes?.length > 0 && MyCommandes?.map((item, i) => (

                  <tr>
                    <td data-label="Numéro de demande">{item?.numero_demande}</td>
                    <td data-label="Date de la demande">{item?.date_demande}</td>
                    <td data-label="Durée">{item?.duree} Mois </td>
                    <td data-label="Le montant total">{parseInt(item?.mt_produit).toLocaleString("fr", { maximumFractionDigits: 2 })} DA</td>
                    <td data-label=" "><div onClick={() => setCurrentData(item)} className='detail-btn' data-bs-toggle="modal" data-bs-target="#exampleModal">Voir</div></td>
                    {/* <td data-label=" "><a href={`/validation/${item?.hash}`}><div className='detail-btn'>Détails</div></a></td> */}
                  </tr>
                ))}
              </tbody>}
            </table>

            {Loading && 
              <div className='dashboard-loader'>
              <div className='cart-sum-loader flex-column'>
                <p className='mb-1'>Chargement</p>
                        <div><div className="spinner-grow text-danger" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-danger" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow text-danger" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        </div>
                    </div>
                    </div>
                    }

                {
                  MyCommandes?.length === 0 || !MyCommandes && !Loading &&
                    <div className='no-results'>Il n'y a pas de données</div>
                  
                }

          </div>


        </div>
      </div>


      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Commandes N° {CurrentData?.numero_demande}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Numéro de demande : {CurrentData?.numero_demande}</div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Civilité : {CurrentData?.civilite} </div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Nom : {CurrentData?.nom}</div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Prénom : {CurrentData?.prenom}</div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Date de la demande : {CurrentData?.date_demande}</div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Durée : {CurrentData?.duree} Mois </div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Le montant total : {parseInt(CurrentData?.mt_produit).toLocaleString("fr", { maximumFractionDigits: 2 })} DA</div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Montant des remboursements : {parseInt(CurrentData?.mtEch).toLocaleString("fr", { maximumFractionDigits: 2 })} DA</div>
              <div className='list-commandes-d'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" strokeLinejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>Mensualités : {parseInt(CurrentData?.mtRmb).toLocaleString("fr", { maximumFractionDigits: 2 })} DA</div>




            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default MesCommandes