import {useEffect , useState} from 'react'
// import Description from '../components/DarIdjar/Description'
// import Dossier from '../components/DarIdjar/Dossier'
// import Engagements from '../components/DarIdjar/Engagements'
// import Pub from '../components/DarIdjar/Pub'
// import Slide from '../components/DarIdjar/Slide'
// import Footer from '../components/Global/Footer'
import Header from '../../components/Saidaty/Header';
import { Helmet} from 'react-helmet-async';
import Description from '../../components/Saidaty/Description';
import Footer from '../../components/Saidaty/Footer';
import axios from 'axios';
import Information from '../../components/Saidaty/Information';

const SaidatyHome = () => {

    const [SaidatyHashCart, setSaidatyHashCart] = useState(localStorage.getItem('SaidatyHashCart'))

    useEffect(() => {


        const GetHashCart = async () => {
          try {
            const { data: response } = await axios.get(`https://opr.diardzair.com.dz/comptebanque/validateCart`);
    
            // console.log(response['panier']['hash']);
            localStorage.setItem('SaidatyHashCart', response['panierHash']);
            setSaidatyHashCart(response['panierHash'])
    
          } catch (error) {
            console.error(error.message);
          }
        }
    
    
        if (localStorage.getItem('SaidatyHashCart') == '' || localStorage.getItem('SaidatyHashCart') == null) {
          GetHashCart();
        }
    
    
      }, [])
  return (
    <>
      <Helmet>
        <title>{"سيدتي | ديار دزاير"}</title>
        <meta
          name="description"
          content={"مصرف السلام | سيدتي | ديار دزاير"}
        />
        <meta property="og:url" content={`https://new.diardzair.com.dz/saidaty`} />
        <meta property="og:title" content={"مصرف السلام | سيدتي | ديار دزاير"} />
        <meta property="og:description" content={"مصرف السلام | سيدتي | ديار دزاير"} />
        <meta property="og:image" content={"https://scontent.falg3-2.fna.fbcdn.net/v/t39.30808-6/323461824_1590164864743653_3359818138911553738_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=e3f864&_nc_ohc=NtwmJrCktZQAX_wj_CZ&_nc_zt=23&_nc_ht=scontent.falg3-2.fna&oh=00_AfDpbstQmj_VA2-bCED-mbe5SXAGjBmDUPv0M3PqjcDO_w&oe=63D0DE0A"} />
        <meta name="keywords" content={`Taksit,مصرف السلام, سيدتي ,alsalam, diar dzair, dar idjar , dz , dz ecommerce , algerie taksit , taksit algerie , ecommerce algerie, ديار دزاير , الإيجار بالتقسيط, تقسيط , التقسيط , دار الإيجار , التجارة الإلكترونية , التقسيط في الجزائر `} />
      </Helmet>
      <div className='saidaty'>
        <Header />
        <Description Hash={SaidatyHashCart} setHash={setSaidatyHashCart} />
        {/* <Information /> */}
        <Footer />
        {/* <Slide />
        <Description />
        <Dossier />
        <Engagements />
        <Pub />
        <Footer /> */}
      </div>
    </>
  )
}

export default SaidatyHome