import { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import CartContext from '../Context/cart';
import BankContext from '../../Context/bank';
import LangContext from '../../Context/lang';




const I3marSimulation = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    //   const {MaxAmount,setMaxAmount,MinAmount,setMinAmount,setTimeCart} = useContext(CartContext);

    const { bankActive } = useContext(BankContext);
    const { Lang, Global, dari3marsim } = useContext(LangContext);

    const [Product, setProduct] = useState('')


    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/produit?hash=${id}`);
                // console.log(response[0]);
                setProduct(response['product'][0]);
                setLoyer(Math.trunc(response['product'][0]['prix']).toLocaleString("fr", { maximumFractionDigits: 2 }))

            } catch (error) {
                // console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    // console.log(Product);




    const [DayList, setDayList] = useState(Array.from(Array(31), (_, i) => (i + 1).toString().padStart(2, "0")));
    const [Day, setDay] = useState('')
    const [MounthList, setMounthList] = useState(Array.from(Array(12), (_, i) => (i + 1).toString().padStart(2, "0")))
    const [Month, setMonth] = useState('')
    const [YearList, setYearList] = useState(Array.from(Array(52), (_, i) => (i + 1951).toString()))
    const [Year, setYear] = useState('')
    const [Salary, setSalary] = useState(30)
    const [Time, setTime] = useState('12')
    const [Loyer, setLoyer] = useState('')
    const [Max, setMax] = useState('')
    const [Min, setMin] = useState('')
    const [Step, setStep] = useState(1)
    const [MtLoyer, setMtLoyer] = useState('')

    const [Montantdesremboursements, setMontantdesremboursements] = useState('')
    const [FraisdeService, setFraisdeService] = useState('')
    const [Fraisderamassagededossier, setFraisderamassagededossier] = useState('')
    const [Mensualités, setMensualités] = useState('')
    const [assurance, setassurance] = useState('')


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ day: '', salary: '', time: '' })
    const [Showerrors, setShowerrors] = useState(false)

    // console.log(Day, Month, Year);
    // console.log(Salary);
    // console.log(Time);
    // console.log(Loyer);

    function calculate_age(dob) {
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);


    }

    function Validate() {

        if (!DayList.includes(Day) || !MounthList.includes(Month) || !YearList.includes(Year)) {
            seterrors((prev) => ({ ...prev, day: dari3marsim['dateerr'][Lang] }))
        } else {
            seterrors((prev) => ({ ...prev, day: '' }))
        }

        if (!Number.isInteger(Number(Salary)) || Number(Salary) < 25 || Number(Salary) > 200) {
            seterrors((prev) => ({ ...prev, salary: 'Veuillez renseigner votre Salaire' }))
        } else {
            seterrors((prev) => ({ ...prev, salary: '' }))
        }

        if (Time !== '12' && Time !== '24' && Time !== '36') {
            seterrors((prev) => ({ ...prev, time: 'Veuillez renseigner Durée de remboursement' }))
        } else {
            seterrors((prev) => ({ ...prev, time: '' }))
        }

    }

    useEffect(() => {

        Validate();

    }, [Day, Month, Year, Salary, Time, Lang, dari3marsim])

    function GoPageTwo(e) {
        e.preventDefault();

        setShowerrors(true)

        if (errors.day !== '' || errors.salary !== '') {
            // console.log('hhhhhhhhhhhhh');
            return;
        }

        // setLoading(true)
        setStep(2)

    }

    function GetLoyer(e) {


        e.preventDefault();

        setShowerrors(true)

        if (errors.time !== '') {
            // console.log('hhhhhhhhhhhhh');
            return;
        }

        setLoading(true)


        const totalSalary = Salary + '000';

        axios.get(`https://opr.diardzair.com.dz/i3mar/maxcart?r=${totalSalary}&d=${Time}`)
            .then(function (response) {
                // console.log(response.data);
                // setMensualite(response.data['mtEcho'])
                // setremboursements(response.data['mtRmb'])
                setMtLoyer(response.data['maCart01'])
                setLoading(false)
                setStep(3)
            })
            .catch(function (error) {
                setLoading(false)

                // console.log(error);
            });
        // console.log('hi!');



    }



    function getMaxamount(e) {

        e.preventDefault();

        setShowerrors(true)

        // if ((MtLoyer < Loyer) || Loyer == '') {
        //     console.log('hhhhhhhhhhhhh');
        //     return;
        // }

        setLoading(true)

        const totalSalary = Salary + '000';

        axios.get(`https://opr.diardzair.com.dz/ws/i3mar/simulation?duree=${Time}&dateNaissance=${Day}-${Month}-${Year}&salaire=${totalSalary}&mt=${Loyer}`)
            .then(function (response) {
                // setMax(response.data['max'])
                // setMin(response.data['min'])
                // console.log(response.data);
                // console.log(Max, Min);
                // console.log(calculate_age(new Date(Year, Month, Day)));
                setMensualités(response.data[0]['mtech'])

                setMontantdesremboursements(response.data[0]['mtrmb'])
                setFraisderamassagededossier(response.data[0]['tarifDossier'])
                setFraisdeService(response.data[0]['fservice'])
                setassurance(response.data[0]['assurance'])
                // console.log(response.data);

                localStorage.setItem('i3marmtEcho', response.data[0]['mtech'])
                localStorage.setItem('i3marmtRmb', response.data[0]['mtrmb'])
                localStorage.setItem('i3marTime', Time)
                localStorage.setItem('i3marSalary', Salary)
                localStorage.setItem('i3marday', Day)
                localStorage.setItem('i3marMonth', Month)
                localStorage.setItem('i3marYear', Year)

                setLoading(false)
                //   setMaxAmount(response.data['max']);
                //   setMinAmount(response.data['min']);
                //   setTimeCart(Time);
                setStep(4)
            })
            .catch(function (error) {
                // console.log(error);
                setLoading(false)

            });
        // console.log('hi!');

    }

    function idjarInscription() {




        // if ((MtLoyer < Loyer) || Loyer == '') {
        //     console.log('hhhhhhhhhhhhh');
        //     return;
        // }

        setLoading(true)

        axios.get(`https://opr.diardzair.com.dz/i3mar/validateCart?duree=${Time}&hash=${id}`)

            .then(function (response) {
                // setMax(response.data['max'])
                // setMin(response.data['min'])
                // console.log(response.data);
                // console.log(Max, Min);
                if (response.data['panierHash']) {
                    const hadhidjar = response.data['panierHash'];
                    navigate('/simulation/' + hadhidjar + '?dar=i3mar')
                }
                // console.log(response.data);
                setLoading(false)
                //   setMaxAmount(response.data['max']);
                //   setMinAmount(response.data['min']);
                //   setTimeCart(Time);

            })
            .catch(function (error) {
                // console.log(error);
                setLoading(false)

            });



    }

    return (
        // <div className='container'>

        <div className='dar-idjar-page'>
            <div className='daridjar-slide'>
                <img className='img-fluid' src='/img/dari3marpagebg.jpg' />


                <div className='pre-simulation-popup'>
                    <div className='i3mar-panier mt-5'>
                    <a href={`/dari3mar/product/${id}`} >
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-lg-4 d-flex justify-content-center'>
                            <div className='product-item-img'>
                                <img style={{borderRadius:'20px'}} className="cart-img" src={`https://opr.diardzair.com.dz/${Product['imageA']}`} />
                            </div>

                        </div>
                        <div className='col-lg-8 d-flex justify-content-center  align-items-center align-items-md-start flex-column'>
                            <p className='cart-product-name'>{Product ? Product['nom'] : ''}</p>
                            <p className='cart-product-marque'>{Product ? Product['marque'] : ''}</p>
                        </div>

                    </div>
                    </a>
                    </div>
                    <div className='cart-simulation-popup mt-2'>
                        <div className='cart-simulation-titel'>
                            <div className='bank-img-simulation me-3'>
                                <img src={'/img/' + bankActive + '.png'} />

                            </div>
                            {(dari3marsim['titre'][Lang])}</div>

                        <div className={`${Step === 1 ? 'popup-container' : ''}`}>

                            {/* {Step === 1 && <div className='cart-simulation-desc '> La pré-simulation vous permet de connaître votre capacité d’achat dans le cadre du service TAKSIT (le montant max autorisé de votre panier)</div>} */}
                            {Step === 1 &&


                                <div className=''>
                                    <form className="row g-md-3 justify-content-center">
                                        {/* <div className="col-md-6">
        <label htmlFor="inputEmail4" className="form-label">Email</label>
        <input type="email" className="form-control" id="inputEmail4" />
    </div>
    <div className="col-md-6">
        <label htmlFor="inputPassword4" className="form-label">Password</label>
        <input type="password" className="form-control" id="inputPassword4" />
    </div>
    <div className="col-12">
        <label htmlFor="inputAddress" className="form-label">Address</label>
        <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St" />
    </div>
    <div className="col-12">
        <label htmlFor="inputAddress2" className="form-label">Address 2</label>
        <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />
    </div> */}
                                        <div className='row justify-content-center'>
                                            <label htmlFor="inputCity" className="form-label mb-3 label-days text-center">{(dari3marsim['datenaissance'][Lang])}</label>
                                            <div className="col-4 text-center">
                                                <label htmlFor="inputState" className="form-label label-days">{(dari3marsim['Jour'][Lang])}</label>
                                                <select id="inputState" onChange={(e) => setDay(e.target.value)} className="form-select">
                                                    <option selected disabled>{(dari3marsim['Jour'][Lang])}</option>
                                                    {
                                                        DayList.length > 0 && DayList.map((item, i) => (
                                                            <option selected={Day == item.toString().padStart(2, "0")} value={item.toString().padStart(2, "0")}>{item.toString().padStart(2, "0")}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-4 text-center">
                                                <label htmlFor="inputState" className="form-label label-days">{(dari3marsim['Mois'][Lang])}</label>
                                                <select id="inputState" onChange={(e) => setMonth(e.target.value)} className="form-select">
                                                    <option selected disabled>{(dari3marsim['Mois'][Lang])}</option>
                                                    {
                                                        MounthList.length > 0 && MounthList.map((item, i) => (
                                                            <option selected={Month == item.toString().padStart(2, "0")} value={item.toString().padStart(2, "0")}>{item.toString().padStart(2, "0")}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-4 text-center">
                                                <label htmlFor="inputState" className="form-label label-days">{(dari3marsim['Année'][Lang])}</label>
                                                <select id="inputState" onChange={(e) => setYear(e.target.value)} className="form-select">
                                                    <option selected disabled>{(dari3marsim['Année'][Lang])}</option>
                                                    {
                                                        YearList.length > 0 && YearList.map((item, i) => (
                                                            <option selected={Year == item} value={item}>{item}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='err-cart-sum'>{Showerrors && errors.day !== '' ? errors.day : ''}</div>
                                        <div className='row justify-content-center'>

                                            <div className="col-md-10 mt-5 mb-5 text-center">

                                                <label htmlFor="inputCity" className="form-label mb-3 label-days">{(dari3marsim['Votresalaire'][Lang])}</label>
                                                <input type="range" min={30} max={200} value={Salary} onChange={(e) => setSalary(e.target.value)} defaultValue={3000000} className="slider" id="myRange" />
                                                {/* <input type="number" max={200000} min={25000}  className="form-control" value={Salary} id="inputCity" placeholder='DA' /> */}
                                                <div className='rang-popup mt-2'><div className='rang-popup-up' onClick={() => setSalary(s => parseInt(s) > 30 ? parseInt(s) - 1 : parseInt(s))}>-</div>{Salary + ' 000 '}<span> {(Global['Da'][Lang])}</span><div className='rang-popup-up' onClick={() => setSalary(s => parseInt(s) < 200 ? parseInt(s) + 1 : parseInt(s))}>+</div></div>
                                            </div>
                                            {/* <div className="col-md-2">
        <label htmlFor="inputZip" className="form-label">Zip</label>
        <input type="text" className="form-control" id="inputZip" />
    </div> */}

                                            <div className="col-12 mt-3 text-center mt-5 mb-3">
                                                <button onClick={GoPageTwo} className="btn-cart-simulation">{(dari3marsim['Suivant'][Lang])}</button>
                                                {/* <button onClick={getMaxamount} className="btn-cart-simulation">Suivant</button> */}

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>

                        {Step === 2 && !Loading &&

                            <div className='popup-container'>

                                <div className="col-md-12 text-center mt-3 mb-3 d-none">
                                    <div className='mb-2 label-days'> {(dari3marsim['Duréederemboursement'][Lang])} </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={Time == '12'} onChange={(e) => setTime(e.target.value)} defaultChecked defaultValue="12" />
                                        <label className="form-check-label" htmlFor="inlineRadio1">12 Mois</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={Time == '24'} onChange={(e) => setTime(e.target.value)} defaultValue="24" />
                                        <label className="form-check-label" htmlFor="inlineRadio2">24 Mois</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" checked={Time == '36'} onChange={(e) => setTime(e.target.value)} defaultValue="36" />
                                        <label className="form-check-label" htmlFor="inlineRadio3">36 Mois</label>
                                    </div>
                                </div>
                                <div className='mb-3 label-days text-center'> {(dari3marsim['Duréederemboursement'][Lang])} </div>
                                <div className='remboursement-box mb-5' dir={Lang === 'ar' && 'rtl'}>
                                    <div onClick={() => setTime('12')} className={`remb-box ${Time == '12' ? 'active' : ''}`}><svg xmlns="https://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                        <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                    </svg>
                                        12 {(dari3marsim['duréeMois'][Lang])}</div>
                                    <div onClick={() => setTime('24')} className={`remb-box ${Time == '24' ? 'active' : ''}`}><svg xmlns="https://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                        <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                    </svg>24 {(dari3marsim['duréeMois'][Lang])}</div>
                                    <div onClick={() => setTime('36')} className={`remb-box ${Time == '36' ? 'active' : ''}`}><svg xmlns="https://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                        <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                    </svg>36 {(dari3marsim['duréeMois'][Lang])}</div>
                                </div>

                                <div className='cart-pre-sum mt-5 '>
                                    <div className='text-center'>
                                        <button onClick={() => setStep(1)} className="btn-cart-simulation">{(dari3marsim['Retour'][Lang])}</button>
                                    </div>
                                    <div className='text-center'>
                                        <button onClick={GetLoyer} className="btn-cart-simulation">{(dari3marsim['Suivant'][Lang])}</button>
                                    </div>
                                </div>
                            </div>
                        }

                        {Loading && <div className='cart-sum-loader'>
                            <div class="spinner-grow text-danger" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div class="spinner-grow text-danger" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div class="spinner-grow text-danger" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>}

                        {Step === 3 && !Loading &&
                            <>
                                <div className='popup-container'>
                                    <div className='cart-simulation-desc mb-3'>
                                        {Lang === 'ar' ?
                                            <>
                                                الحد الأقصى للمبلغ المصرح به من سلتك (يجب ألا يتجاوز مجموع المنتجات المشتراة هذا المبلغ): <span>{MtLoyer} دج / للشهر</span>

                                            </>
                                            :
                                            <>Montant max autorisé de votre panier (La somme des produits achetés ne doit pas dépasser ce montant) : <span>{MtLoyer} DA/Mois</span></>
                                        }


                                    </div>
                                    <div className='row justify-content-center'>

                                        <div className="col-md-6 text-center">
                                            <label htmlFor="inputState" className="form-label label-days">{(dari3marsim['montant'][Lang])} </label>
                                            <input type="text" className='text-center' style={{ caretColor: 'transparent' }} dir={Lang === 'ar' && 'rtl'} onChange={(e) => setLoyer(e.target.value.replace(/[^0-9]+/g, ''))} value={Math.trunc(Product?.prix).toLocaleString("fr", { maximumFractionDigits: 2 })} readonly placeholder={(Global['Da'][Lang])} /> {Global['Da'][Lang]}
                                            {/* <div className='err-cart-sum'>{Loyer > MtLoyer ? (dari3marsim['montantmaxerr'][Lang]) : ''}</div>
                                            <div className='err-cart-sum'>{Loyer == '' ? (dari3marsim['montanterr'][Lang]) : ''}</div> */}

                                        </div>

                                    </div>
                                    <div className='cart-pre-sum mt-5 '>
                                        <div className='text-center'>
                                            <button onClick={() => setStep(2)} className="btn-cart-simulation">{(dari3marsim['Retour'][Lang])}</button>
                                        </div>
                                        <div className='text-center'>
                                            <button onClick={getMaxamount} className="btn-cart-simulation">{(dari3marsim['Suivant'][Lang])}</button>
                                        </div>
                                    </div>
                                </div>
                            </>}


                        {Step === 4 && !Loading &&
                            <div className='popup-container'>
                                <div className='validation-section'>
                                    <div className='text-center mt-2 mb-2 titel'><p>{(dari3marsim['Récapitulatif'][Lang])}</p></div>
                                    <div className='max-amount'>{(dari3marsim['Mensualités'][Lang])} {parseFloat(Mensualités).toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {(Global['Da'][Lang])}</div>

                                    <div className='user-info mt-5 mb-5'>
                                        <p className='user-info-items'><b>{(dari3marsim['VotreSalaire'][Lang])} :</b> {(Lang === 'fr') ? `${Salary} 000` : `000 ${Salary}`}  {(Global['Dajust'][Lang])}</p>
                                        <p className='user-info-items'><b>{(dari3marsim['Durée'][Lang])} : </b>{Time} {(dari3marsim['duréeMois'][Lang])}</p>
                                        <p className='user-info-items'><b>{(dari3marsim['Votreàge'][Lang])} : </b>{calculate_age(new Date(Year, Month, Day))}</p>
                                        {/* <p className='user-info-items'><b>Votre loyer : </b>{Loyer} Mois</p> */}
                                        <p className='user-info-items'><b>{(dari3marsim['remboursements'][Lang])} : </b>{parseInt(Montantdesremboursements).toLocaleString("fr", { maximumFractionDigits: 2 })} {(Global['Dajust'][Lang])}</p>
                                        <p className='user-info-items'><b>{(dari3marsim['ramassage'][Lang])} : </b>{parseInt(Fraisderamassagededossier).toLocaleString("fr", { maximumFractionDigits: 2 })} {(Global['Dajust'][Lang])}</p>
                                        <p className='user-info-items'><b>{(dari3marsim['assurance'][Lang])} : </b>{parseInt(assurance).toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {(Global['Dajust'][Lang])}</p>
                                        <p className='user-info-items'><b>{(dari3marsim['Service'][Lang])} : </b>{parseInt(FraisdeService).toFixed(2)} {(Global['Dajust'][Lang])}</p>


                                    </div>

                                    <div className='cart-pre-sum justify-content-center justify-content-md-between'>
                                        <div className='text-center'>
                                            <button onClick={() => setStep(3)} className="btn-cart-simulation">{(dari3marsim['Retour'][Lang])}</button>
                                        </div>
                                        <div className='text-center mt-3 mt-md-0'>
                                            <button onClick={() => idjarInscription()} className="btn-cart-simulation">{(dari3marsim['Remplissez'][Lang])}</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default I3marSimulation