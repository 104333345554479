import React, { createContext, useState, useEffect } from 'react'

const LangContext = createContext();

export function LangProvider({ children }) {

    //Lang
    // const [InitMode] = useState('Light');
    const [Lang, setLang] = useState('fr');
    useEffect(() => {
        const LangStorage = localStorage.getItem("language");
        if (LangStorage !== '' && LangStorage !== null && (LangStorage === 'ar' || LangStorage === 'fr')) {
            setLang(LangStorage);
            document.documentElement.lang = Lang;
        }
    }, [Lang]);

    //Home-Lang
    const [NavMenu] = useState({
        'Home': { 'fr': 'Accueil', 'ar': 'الرئيسية' },
        'DarTaksit': { 'fr': 'Dar Taksit', 'ar': 'دار التقسيط' },
        'DarIdjar': { 'fr': 'Dar Idjar', 'ar': 'دار الإيجار' },
        'DarI3mar': { 'fr': 'Dar I3mar', 'ar': 'دار الإعمار' },
        'DarSiyaha': { 'fr': "Dar Siyaha", 'ar': "دار السياحة" },
        'DarSen3a': { 'fr': 'Dar San3a', 'ar': 'دار الصنعة' },
        'Dar3a9ar': { 'fr': "Dar 3a9ar", 'ar': "دار العقار" },
        'DarAlmal': { 'fr': "Dar Almal", 'ar': "دار المال" },
        'Moto': { 'fr': "Moto", 'ar': "دراجات نارية" },



        'searchbar': { 'fr': "Que recherchez-vous ?", 'ar': "إبحث هنا" },
        'site-slogon': { 'fr': "1re plateforme e-commerce avec financement islamique digital en ligne", 'ar': "أول منصة للتجارة الإلكترونية بتمويل إسلامي رقمي عبر الإنترنت " },
        'account': { 'fr': "Mon Compte", 'ar': "حسابي" },
        'Panier': { 'fr': "Panier", 'ar': "سلة التسوق" },
        'Banque': { 'fr': "Banque", 'ar': "البنك" },
        'Aide': { 'fr': "Aide", 'ar': "مساعدة" },
        'Cadeaux': { 'fr': "Cadeaux", 'ar': "هدايا" },
        'Pack': { 'fr': "Pack", 'ar': "هدايا" },


        'Assistance': { 'fr': "Assistance", 'ar': "مساعدة" },
        'Store': { 'fr': "Boutiques", 'ar': "متاجر" },

        'Dossier-dartaksit': { 'fr': "Dossier à fournir TAKSIT", 'ar': "الملف المطلوب دار التقسيط" },
        'FAQ': { 'fr': "Réponses à vos questions", 'ar': "الإجابة على أسئلتك" },
        'search': { 'fr': "Rechercher", 'ar': "البحث" },






        'features-box1-titel': { 'fr': <>FRONT-END <br />& BACK-END</>, 'en': <>FRONT-END <br />& BACK-END</> },
        'features-box1-subtitel': { 'fr': "Conception et développement de sites Web avec une touche créative et distinctive dans l'interface, avec une architecture et un système ordonné en backend.", 'en': "Design and development of websites with a creative and distinctive touch in the interface, with an orderly architecture and system in the backend." },
        'features-box2-titel': { 'fr': <>MOBILE<br /> APPLICATION</>, 'en': <>MOBILE<br /> APPLICATION</> },
        'features-box2-subtitel': { 'fr': "Développement d'applications mobiles dynamiques, que ce soit sur le système Android ou iOS, en tenant compte de l'impact de l'application sur la batterie et la RAM, avec un design UI et UX élégant.", 'en': "Development of dynamic mobile applications, whether on Android or iOS system, taking into account the impact of the application on the battery and RAM, with an elegant UI and UX design." },
        'features-box3-titel': { 'fr': <>DEV OPS <br /> & DATABASE</>, 'en': <>DEV OPS <br /> & DATABASE</> },
        'features-box3-subtitel': { 'fr': "La base de données est importante dans tout projet, je m'assure donc qu'elle est organisée et également extensible, car elle peut être divisée en fonction de la taille et du type du projet et je place également le projet sur le serveur dans la meilleure position.", 'en': "The database is important in any project so I make sure it is organized and also expandable as it can be divided according to the size and type of the project and I also place the project on the server in the best position." },

        'Carriere': { 'fr': 'Rrière', 'en': 'Reer' },

        'features-black-titel-1': { 'fr': <>Rapidité et<br /> qualité avec créativité</>, 'en': <>Speed and<br /> quality with creativity</> },
        'features-black-subtitel-1': { 'fr': "La vitesse, dans la plupart des cas, ne se caractérise pas par la maîtrise et la qualité, mais j'ai pu enfreindre cette règle et relever le défi et j'ai pu développer mes capacités pour terminer le travail de haute qualité, mais rapidement et de manière créative.", 'en': "Speed, in most cases, is not characterized by mastery and quality, but I was able to break this rule and rise to the challenge and was able to develop my abilities to complete the work with high quality, but quickly and creatively." },
        'features-black-titel-2': { 'fr': <>L'intelligence artificielle <br />et système embarqué</>, 'en': <>Artificial intelligence <br />and embedded system</> },
        'features-black-subtitel-2': { 'fr': "L'intelligence artificielle est l'avenir de la technologie et va révolutionner le monde numérique, et je la considère comme ma passion comme le système embarqué avec lequel j'ai réalisé plusieurs projets et l'ai appris avec passion et plaisir.", 'en': "Artificial intelligence is the future of technology and will revolutionize the digital world, and I consider it my passion as the embedded system with which I have carried out several projects and learned it with passion and pleasure." },

        'Portfolio-titel': { 'fr': "Réalisations", 'en': "Portfolio" },
        'Portfolio-subtitel': { 'fr': "MES-TRAVAUX", 'en': "Some Of My Work" },
        'Portfolio-btn': { 'fr': "Voir Plus", 'en': "See more" },

        'contact-desc': { 'fr': "Si vous avez plus de questions ou avez besoin de plus d'informations, veuillez me contacter", 'en': "If you have more questions or need more information, please contact me." },
        'contact-titel': { 'fr': "DÉVELOPPEUR FULL STACK", 'en': "FULL STACK DEVELOPER" },
        'contact-subtitel': { 'fr': "Qu'il est beau de travailler dans le domaine que vous aimez, êtes passionné et appréciez.", 'en': "How beautiful it is to work in the field that you love, are passionate about and appreciate." },
    });

    const [Global, setGlobal] = useState({
        'Plus': { 'ar': 'شاهد المزيد', 'fr': 'Voir Plus' },
        'TopAnnonce': { 'fr': 'Top Annonce', 'ar': 'افضل المبيعات' },
        'Da': { 'fr': 'DA | Mois', 'ar': 'دج | للشهر' },
        'Dajust': { 'fr': 'DA', 'ar': 'دج' },
        'wilaya': { 'fr': 'Wilaya', 'ar': 'الولاية' },
        'minCartCadeaux': { 'fr': 'Montant minimum du panier pour bénéficier de ce cadeau', 'ar': 'الحد الأدنى لمبلغ السلة للاستفادة من هذه الهدية' },
        'latin': { 'fr': "Merci d'écrire en français", 'ar': 'الكتابة بالأحرف الاتينية' },
        'Number': { 'fr': "Veuillez écrire en chiffres", 'ar': 'الكتابة بالأرقام' },

        'conditions': { 'ar': 'الشروط', 'fr': 'Conditions' },





    })

    const [ProductLang] = useState({

        'title': { 'fr': 'Nos engagements', 'ar': 'التزاماتنا' },
        'sell-btn': { 'fr': "j'achète", 'ar': 'أشتري' },
        'Quantité': { 'fr': 'Quantité', 'ar': 'الكمية' },
        'Livraison': { 'fr': <>livraison sur<br />les 58 wilayas</>, 'ar': 'التسليم في 58 ولاية' },
        'Marque': { 'fr': 'Marque', 'ar': 'العلامة التجارية' },
        'Ref': { 'fr': 'Référence', 'ar': 'المرجع' },
        'Simailaire': { 'fr': 'Articles Similaires', 'ar': 'سلع مماثلة' },
        'Taksit36': { 'fr': <>DA <span className='product-mounth'> / mois  </span>   <span className='product-mounth-time'> jusqu'à 36 mois</span> </>, 'ar': 'دج / للشهر حتى 36 شهر' },
        'Taksit24': { 'fr': <>DA <span className='product-mounth'> / mois  </span>   <span className='product-mounth-time'> jusqu'à 24 mois</span> </>, 'ar': 'دج / للشهر حتى 24 شهر' },
        'Taksit12': { 'fr': <>DA <span className='product-mounth'> / mois  </span>   <span className='product-mounth-time'> jusqu'à 12 mois</span> </>, 'ar': 'دج / للشهر حتى 12 شهر' },
        'Epuisé': { 'fr': <><strong>Désolé,</strong>  ce produit est épuisé</>, 'ar': 'عذرا هذا المنتج لم يعد متوفر' },
        'Description': { 'fr': 'Description', 'ar': 'تفاصيل المنتوج' },
        'Caractéristique': { 'fr': 'Caractéristique', 'ar': 'خصائص المنتوج' },
        'Jefaisunesimulation': { 'fr': 'Je fais une simulation', 'ar': 'القيام بمحاكاة' },
        'existincart': { 'fr': 'Le produit existe déjà dans le panier', 'ar': 'المنتج موجود في سلة التسوق' },
        'showitincart': { 'fr': 'Afficher dans le panier', 'ar': 'عرض سلة التسوق' },
        gettaksit: (month) => {

            return { 'fr': <>DA <span className='product-mounth'> / mois  </span>   <span className='product-mounth-time'> jusqu'à {month} mois</span> </>, 'ar': `دج / للشهر حتى ${month} شهر` }

        },
        productgift: (price) => { return { 'fr': <>Avec cadeau gratuit d'une valeur de : <b>{price}</b> DA <a style={{ color: '#ffc2dd' }} href={"/search/?page=1&parent=Dar-Taksit-Cadeaux"}> <span><b>cliquez ici </b></span></a></>, 'ar': <>مع هدية مجانية بقيمة: <b>{price}</b> دج  <a style={{ color: '#ffc2dd' }} href={"/search/?page=1&parent=Dar-Taksit-Cadeaux"}> <span> <b> إنقر هنا </b> </span></a></> } }

    });

    const [Engagements] = useState({

        'title': { 'fr': 'Nos engagements', 'ar': 'التزاماتنا' },
        'engagement1': { 'fr': 'Une réponse de principe immédiate', 'ar': 'موافقة مبدئية فورية' },
        'engagement2': { 'fr': 'Un ramassage à domicile de votre dossier', 'ar': 'يتم جمع ملف تقسيط من المنزل ' },
        'engagement3': { 'fr': 'Un traitement efficace de votre dossier', 'ar': 'موافقة نهائية تتم بعد دراسة ملف الزبون لتاكد من امكانية استفادته من خدماتنا' },
        'engagement4': { 'fr': 'une livraison à domicile de votre commande', 'ar': 'خدمة توصيل طلبيتكم الى غاية منزلكم' },

    });

    const [Footer] = useState({

        'site-slogon': { 'fr': "1re plateforme e-commerce avec financement islamique digital en ligne", 'ar': "أول منصة للتجارة الإلكترونية بتمويل إسلامي رقمي عبر الأنترنيت" },
        'copyright': { 'fr': `Diar Dzair - Tous droits réservés ${new Date().getFullYear()}`, 'ar': 'ديار دزاير - جميع الحقوق محفوظة' },
        'tel': { 'fr': 'Tél : 0982 300 500', 'ar': ' 0982 300 500 : هاتف' },
        'email': { 'fr': 'email : helpdesk@diardzair.com.dz', 'ar': 'helpdesk@diardzair.com.dz : بريد إلكتروني' },
        'adresse': { 'fr': '62 A, Lotissement des Jeunes Aveugles, Draria, Alger, Algérie', 'ar': 'شارع المكفوفين درارية,الجزائر العاصمة,الجزائر,ا62' },
        'contact': { 'fr': 'Contact', 'ar': 'إتصل بنا' },
        'moncompte': { 'fr': 'mon compte', 'ar': 'حسابي' },
        'Catégories': { 'fr': 'Catégories', 'ar': 'جميع التصنيفات' },
        'socialmedia': { 'fr': 'Suivez-nous', 'ar': 'تابعونا' },
        'conditionsgenerales': { 'fr': 'Conditions d’Utilisation', 'ar': 'معلومات قانونية' },
        "Conditions_Générales": { 'fr': "Conditions Générales D’utilisation", 'ar': "الشروط العامة للإستعمال" },
        'Conditions_services': { 'fr': "Conditions d'utilisation de nos services", 'ar': "شروط الإستفادة من خدماتنا" },
        'Politique_protection': { 'fr': "Politique de protection des données personnelles", 'ar': "سياسة حماية المعطيات الشخصية" },




    })


    const [daridjardef] = useState({

        'titre': { 'fr': 'C’est quoi dar idjar ? ', 'ar': 'ماهي دار الإيجار ؟' },
        'definition': { 'fr': ' DAR IDJAR  «دار الإيجار»    : est un service Digital en ligne de diar dzair qui assure aux e-consommateurs la sous location en ligne avec Financement Immobilier –Sous location- Digital en ligne ', 'ar': 'دار الإيجار": هي خدمة رقمية عبر الإنترنت من ديار دزاير توفر للزبائن الإلكترونيين  خدمة التأجير عبر الإنترنت من خلال التمويل العقاري - التأجير - الرقمي عبر الإنترنت' },
        'dossier': { 'fr': 'Dossier a fournir', 'ar': 'الملف المطلوب' },
    });
    const [daridjarPage] = useState({
        'dossier': { 'fr': 'Dossier a fournir', 'ar': 'الملف المطلوب' },
        'titre': { 'fr': <>C’est quoi <br /> <span>DAR IDJAR ?</span></>, 'ar': <>ماهي <br /> <span>دار الإيجار ؟</span></> },
        'definition': { 'fr': ' DAR IDJAR  «دار الإيجار»    : est un service Digital en ligne de diar dzair qui assure aux e-consommateurs la sous location en ligne avec Financement Immobilier –Sous location- Digital en ligne ', 'ar': 'دار الإيجار": هي خدمة رقمية عبر الإنترنت من ديار دزاير توفر للزبائن الإلكترونيين  خدمة التأجير عبر الإنترنت من خلال التمويل العقاري - التأجير - الرقمي عبر الإنترنت' },
    });

    const [idjarsection1] = useState({

        'titre': { 'fr': 'Pour les salariés et retraités', 'ar': 'للموظفين والمتقاعدين' },
        'sous-titre': { 'fr': <>Pour les professions libérales et <span>les commerçants</span></>, 'ar': 'للمهن الحرة والتجار' },
        'voirplus': { 'fr': 'Voir plus', 'ar': 'شاهد المزيد' }
    });
    const [daridjarsim] = useState({

        'titre': { 'fr': 'Simulation Dar Idjar', 'ar': 'محاكاة دار الإيجار' },
        'Jour': { 'fr': 'Jour', 'ar': 'اليوم' },
        'Mois': { 'fr': 'Mois', 'ar': 'الشهر' },
        'Année': { 'fr': 'Année', 'ar': 'السنة' },
        'duréeMois': { 'fr': 'Mois', 'ar': 'شهر' },
        'Suivant': { 'fr': 'Suivant', 'ar': 'التالي' },
        'Retour': { 'fr': 'Retour', 'ar': 'السابق' },
        'Remplissez': { 'fr': 'Remplissez le Formulaire', 'ar': 'إملأ إستمارة الطلب' },
        'datenaissance': { 'fr': 'Date de naissance', 'ar': 'تاريخ الميلاد' },
        'Votresalaire': { 'fr': 'Votre salaire', 'ar': 'الراتب الشهري' },
        'Duréederemboursement': { 'fr': 'Durée de remboursement', 'ar': 'فترة السداد' },
        'message': { 'fr': '', 'ar': ' أدخل مبلغ الإيجار الشهري الذي يجب ألا يتجاوز      يتم تعيين حد الإيجار حسب الدخل' },
        'Loyermensuel': { 'fr': 'Loyer mensuel ? - DA', 'ar': ' ?  مبلغ الكراء الشهري بالدبنار' },
        'dateerr': { 'fr': 'Veuillez renseigner votre Date naissance', 'ar': 'الرجاء ادخال تاريخ ميلادك' },
        'Loyererr': { 'fr': 'Veuillez renseigner le montant du loyer', 'ar': 'الرجاء إدخال مبلغ الإيجار' },
        'Loyermaxerr': { 'fr': 'Vous avez dépassé le montant du loyer mensuel autorisé', 'ar': 'لقد تجاوزت مبلغ الإيجار الشهري المصرح به' },
        'Récapitulatif': { 'fr': 'Récapitulatif de votre simulation', 'ar': 'ملخص المحاكاة الخاصة بكم' },
        'Mensualités': { 'fr': 'Mensualités', 'ar': 'الدفع الشهري' },
        'VotreSalaire': { 'fr': 'Votre Salaire', 'ar': 'الراتب الشهري' },
        'Durée': { 'fr': 'Durée', 'ar': 'مدة السداد' },
        'Votreàge': { 'fr': 'Votre àge', 'ar': 'عمرك' },
        'assurance': { 'fr': "Prime d'assurance", 'ar': 'مبلغ التأمين' },
        'Service': { 'fr': "Frais de Service", 'ar': 'مصاريف الخدمه' },
        'ramassage': { 'fr': "Frais de ramassage de dossier", 'ar': 'تكاليف استيلام و نقل الملف' },
        'remboursements': { 'fr': "Montant des remboursements", 'ar': 'تكلفة الإيجار الاجمالية' },
        'Loyertotal': { 'fr': "Votre loyer", 'ar': 'مبلغ الإيجار الشهري' },


    });

    const [dartaksitsim] = useState({

        'titre': { 'fr': 'Simulation', 'ar': 'المحاكاة الأولية' },
        'sous-titre2': { 'fr': 'La pré-simulation vous permet de connaître votre capacité d’achat dans le cadre du service TAKSIT (le montant max autorisé de votre panier)', 'ar': 'تسمح لك المحاكاة المسبقة بمعرفة قدرتك الشرائية في إطار خدمة دار التقسيط (الحد الأقصى للمبلغ المسموح به )' },
        'Jour': { 'fr': 'Jour', 'ar': 'اليوم' },
        'Mois': { 'fr': 'Mois', 'ar': 'الشهر' },
        'Année': { 'fr': 'Année', 'ar': 'السنة' },
        'duréeMois': { 'fr': 'Mois', 'ar': 'شهر' },
        'Suivant': { 'fr': 'Suivant', 'ar': 'التالي' },
        'Retour': { 'fr': 'Retour', 'ar': 'السابق' },
        'Validation': { 'fr': 'Validation', 'ar': 'تفعيل' },
        'livraison': { 'fr': 'wilaya de livraison', 'ar': 'ولاية تسليم المنتوج' },
        'wilayaramassage': { 'fr': 'wilaya de ramassage', 'ar': 'ولاية جمع الملف' },

        'sous-titre3': { 'fr': 'Montant max autorisé de votre panier', 'ar': 'المبلغ الأقصى المسموح به للسلة الخاصة بكم' },
        'sous-titre4': { 'fr': 'La somme des produits achetés ne doit pas dépassée ce montant', 'ar': 'يجب ألا يتجاوز مجموع المنتجات المشتراة هذا المبلغ ' },
        'Remplissez': { 'fr': 'Remplissez le Formulaire', 'ar': 'إملأ إستمارة الطلب' },
        'datenaissance': { 'fr': 'Date de naissance', 'ar': 'تاريخ الميلاد' },
        'Votresalaire': { 'fr': 'Votre salaire', 'ar': 'الراتب الشهري' },
        'Duréederemboursement': { 'fr': 'Durée de remboursement', 'ar': 'فترة السداد' },
        'message': { 'fr': '', 'ar': ' أدخل مبلغ الإيجار الشهري الذي يجب ألا يتجاوز      يتم تعيين حد الإيجار حسب الدخل' },
        'Loyermensuel': { 'fr': 'Loyer mensuel ?', 'ar': '? مبلغ الكراء الشهري' },
        'dateerr': { 'fr': 'Veuillez renseigner votre Date naissance', 'ar': 'الرجاء ادخال تاريخ ميلادك' },
        'WilayadeRamassage': { 'fr': 'Veuillez renseigner votre Wilaya de Ramassage', 'ar': 'الرجاء تحديد الولاية' },
        'Wilayadelivraison': { 'fr': 'Veuillez renseigner votre Wilaya de livraison', 'ar': 'الرجاء تحديد الولاية' },
        'Loyererr': { 'fr': 'Veuillez renseigner le montant du loyer', 'ar': 'الرجاء إدخال مبلغ الإيجار' },
        'Loyermaxerr': { 'fr': 'Vous avez dépassé le montant du loyer mensuel autorisé', 'ar': 'لقد تجاوزت مبلغ الإيجار الشهري المصرح به' },
        'Récapitulatif': { 'fr': 'Récapitulatif de votre simulation', 'ar': 'ملخص المحاكاة الخاصة بكم' },
        'Mensualités': { 'fr': 'Mensualités', 'ar': 'الدفع الشهري' },
        'VotreSalaire': { 'fr': 'Votre Salaire', 'ar': 'الراتب الشهري' },
        'Durée': { 'fr': 'Durée', 'ar': 'مدة السداد' },
        'Votreàge': { 'fr': 'Votre àge', 'ar': 'عمرك' },
        'assurance': { 'fr': "Prime d'assurance", 'ar': 'مبلغ التأمين' },
        'Service': { 'fr': "Frais de Service", 'ar': 'مصاريف الخدمه' },
        'ramassage': { 'fr': "Frais de ramassage de dossier", 'ar': 'تكاليف استيلام و نقل الملف' },
        'remboursements': { 'fr': "Montant des remboursements", 'ar': 'تكلفة الإيجار الاجمالية' },
        'Loyertotal': { 'fr': "Votre loyer", 'ar': 'مبلغ الإيجار الشهري' },

        'Votredemande': { 'fr': 'Votre demande TAKSIT en 3 étapes', 'ar': 'طلب التقسيط الخاص بك في 3 خطوات' },
        'Remplissez': { 'fr': '1. Remplissez', 'ar': ' 1. إملأ' },
        'Remplissez1': { 'fr': 'En ligne votre demande de crédit TAKSIT en remplissant notre formulaire. vous obtiendrez une réponse de principe immédiate .', 'ar': 'املئ إستمارة طلب الخدمة تقسيط عبر الإنترنت ستحصل على اجابة مبدئية فورية ' },
        'Préparez': { 'fr': '2. Préparez', 'ar': '2. حضّر' },
        'Préparez2': { 'fr': 'votre dossier à fournir', 'ar': 'الملف اللازم' },
        'Envoyez': { 'fr': '3. Envoyez nous', 'ar': '3. راسلنا' },
        'Envoyez3': { 'fr': 'Le dossier demandé sans vous déplacer. Nous assurons le ramassage à domicile de votre dossier via nos livreurs.', 'ar': ' بدون تنقلكم نؤمن لكم جمع الملفات من عنوان منزلكم' },



    });

    const [RecapitulatifSimTaksit] = useState({

        'titre1': { 'fr': 'Récapitulatif de votre simulation', 'ar': 'ملخص المحاكاة الخاصة بكم' },
        'Mensualité': { 'fr': 'Mensualité', 'ar': 'الدفع الشهري' },
        'Durée': { 'fr': 'Durée', 'ar': 'المدة' },
        'mntf': { 'fr': 'Montant à financer', 'ar': 'مبلغ التمويل' },
        'mntr': { 'fr': 'Montant des remboursements', 'ar': 'مبلغ السداد' },
        'ap': { 'fr': 'Apport personnel', 'ar': 'المساهمة الشخصية' },
        'fraisramass': { 'fr': 'Frais de ramassage', 'ar': 'رسوم جمع الملف' },
        'fraislivr': { 'fr': 'Frais de livraison', 'ar': 'رسوم التوصيل' },
        'fraisservice': { 'fr': 'Frais de service', 'ar': 'رسوم الخدمة' },
        'dossier': { 'fr': 'Dossier a fournir', 'ar': 'الملف اللازم' },
        'formtaksit': { 'fr': 'Remplissez le formulaire taksit', 'ar': 'إملأ إستمارة التقسيط' },
        'gratuit': { 'fr': 'Gratuit', 'ar': 'مجانا' },
        'DA/1KG': { 'fr': 'DA/1KG', 'ar': 'دج للكيلو غرام الواحد' },
        'montantdemarge': { 'fr': 'Montant de Marge', 'ar': 'مبلغ الهامش' },
        'tva': { 'fr': 'Montant de TVA ', 'ar': 'القيمة الضريبية' }



    })

    const [dari3marsim] = useState({

        'titre': { 'fr': 'Simulation Dar I3mar', 'ar': 'محاكاة دار الإعمار' },
        'Jour': { 'fr': 'Jour', 'ar': 'اليوم' },
        'Mois': { 'fr': 'Mois', 'ar': 'الشهر' },
        'Année': { 'fr': 'Année', 'ar': 'السنة' },
        'duréeMois': { 'fr': 'Mois', 'ar': 'شهر' },
        'Suivant': { 'fr': 'Suivant', 'ar': 'التالي' },
        'Retour': { 'fr': 'Retour', 'ar': 'السابق' },
        'Remplissez': { 'fr': 'Remplissez le Formulaire', 'ar': 'إملأ إستمارة الطلب' },
        'datenaissance': { 'fr': 'Date de naissance', 'ar': 'تاريخ الميلاد' },
        'Votresalaire': { 'fr': 'Votre salaire', 'ar': 'الراتب الشهري' },
        'Duréederemboursement': { 'fr': 'Durée de remboursement', 'ar': 'فترة السداد' },
        'message': { 'fr': '', 'ar': ' أدخل مبلغ الإيجار الشهري الذي يجب ألا يتجاوز      يتم تعيين حد الإيجار حسب الدخل' },
        'montant': { 'fr': 'le montant de votre produit', 'ar': 'ثمن منتجك' },
        'dateerr': { 'fr': 'Veuillez renseigner votre Date naissance', 'ar': 'الرجاء ادخال تاريخ ميلادك' },
        'montanterr': { 'fr': 'Veuillez renseigner montant de produit', 'ar': 'الرجاء إدخال مبلغ المنتج' },
        'montantmaxerr': { 'fr': 'Vous avez dépassé le montant max autorisé de votre panier', 'ar': 'لقد تجاوزت الحد الأقصى للمبلغ المصرح به من سلتك' },
        'Récapitulatif': { 'fr': 'Récapitulatif de votre simulation', 'ar': 'ملخص المحاكاة الخاصة بكم' },
        'Mensualités': { 'fr': 'Mensualités', 'ar': 'الدفع الشهري' },
        'VotreSalaire': { 'fr': 'Votre Salaire', 'ar': 'الراتب الشهري' },
        'Durée': { 'fr': 'Durée', 'ar': 'مدة السداد' },
        'Votreàge': { 'fr': 'Votre àge', 'ar': 'عمرك' },
        'assurance': { 'fr': "Prime d'assurance", 'ar': 'مبلغ التأمين' },
        'Service': { 'fr': "Frais de Service", 'ar': 'مصاريف الخدمه' },
        'ramassage': { 'fr': "Frais de ramassage de dossier", 'ar': 'تكاليف استيلام و نقل الملف' },
        'remboursements': { 'fr': "Montant des remboursements", 'ar': 'ثمن بيع المنتج' },

    });

    const [taksitdossier] = useState({

        'titre1': { 'fr': ' Dossier à fournir TAKSIT', 'ar': 'الملف المطلوب دار التقسيط' },
        'titre2': { 'fr': 'Liste des documents à fournir pour le financement via le service digital « Dar Taksit »', 'ar': '"ملـف طلـب تمويـل عبر الخدمة الرقمية "دار تقسيط' },
        'listbna': [
            { 'fr': 'Justificatif d’identité en cours de validité (C.N.I biométrique) ', 'ar': 'نسخة من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            { 'fr': 'Un acte de naissance ', 'ar': 'نسخة من شهادة الميلاد  ' },
            { 'fr': 'Justificatif de résidence de moins de 3 mois (Fiche de résidence) ', 'ar': 'نسخة من بطاقة اقامة قيد الصلاحية،لا تتعدى 3 أشهر' },
            { 'fr': 'Une  fiche familiale pour les personnes mariées', 'ar': 'شهـادة عائليـة للمتزوجين' },
            { 'fr': 'Une attestation de travail de moins d’un (01) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': 'شهادةعمل لايزيد تاريخها عن شهر تثبت أن عقد العمل غيرمحدود المدة وأن العامل مرسم في منصبه' },
            { 'fr': 'Justificatif de revenus ', 'ar': 'إثبات الدخل' },
            { 'fr': 'Retraités : attestation de perception de retraite', 'ar': 'المتقاعدين: شهادة منحة التقاعد' },
            { 'fr': 'Salariés : trois (03) dernières fiches de paie', 'ar': 'الموظفين: كشف راتب للأشهرالثلاثة (03)الأخيرة ومصادق عليها من صاحب العمل' },
            { 'fr': 'Un chèque CCP barré', 'ar': 'صك بريدي باسم المتعامل مشطوب عليه' },
            { 'fr': 'Mise a jour de financement en cas de crédit en cours', 'ar': 'نسخة من تحديث التمويل في حالة وجود قرض لدى البنوك الزميلة' },
            { 'fr': 'L’extrait de comptes:', 'ar': ': كشف حساب ' },
            { 'fr': 'Public: Le relevé de comptes des trois 03 derniers mois', 'ar': 'كشف حساب للأشهر الثلاث الأخيرة : الوظيف العمومي' },
            { 'fr': 'Privé: Le relevé de comptes d’une année ', 'ar': 'كشف حساب لسنة كاملة : الوظيف الخاص' },
            { 'fr': 'La déclaration annuelle des salaires (DAS) pour les employés du secteur privé.', 'ar': ' DAS التصريح السنوي بالأجور للعاملين في القطاع الخاص' },
            { 'fr': 'Une copie de la carte d’affiliation a la sécurité sociale CNAS', 'ar': 'CNAS نسخة من شهادة الانخراط في الضمان الاجتماعي ' },
            { 'fr': 'Une copie autorisation de prélèvement sur compte CCP', 'ar': 'CCP نسخة من تفويض الخصم المباشر من حساب' },
            { 'fr': 'Les bénéficiaires des accords conclus doivent prouver leur affiliation à ces organismes.', 'ar': 'على المستفيدين من الاتفاقيات المبرمة أن يثبتوا انتسابهم إلى هذه الهيئات' },
        ],
        'listalsalam': [
            { 'fr': 'Justificatif d’identité en cours de validité (C.N.I biométrique) (02) deux exemplaires', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            { 'fr': 'Un acte de naissance (02) deux exemplaires ', 'ar': 'نسختين من شهادة الميلاد  ' },
            { 'fr': 'Justificatif de résidence de moins de 3 mois (Fiche de résidence) (02) deux exemplaires  ', 'ar': 'نسختين من بطاقة اقامة قيد الصلاحية،لا تتعدى 3 أشهر' },
            { 'fr': 'Une fiche familiale pour les personnes mariées', 'ar': 'شهـادة عائليـة للمتزوجين' },
            { 'fr': 'Une attestation de travail de moins d’un (01) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': 'شهادةعمل لايزيد تاريخها عن شهر تثبت أن عقد العمل غيرمحدود المدة وأن العامل مرسم في منصبه' },
            { 'fr': 'Justificatif de revenus ', 'ar': 'إثبات الدخل' },
            { 'fr': 'Retraités : attestation de perception de retraite', 'ar': 'المتقاعدين: شهادة منحة التقاعد' },
            { 'fr': 'Salariés : trois (03) dernières fiches de paie', 'ar': 'الموظفين: كشف راتب للأشهرالثلاثة (03) الأخيرة ومصادق عليها من صاحب العمل' },
            { 'fr': 'Un chèque CCP barré', 'ar': 'صك بريدي باسم المتعامل مشطوب عليه' },
            { 'fr': 'Le relevé de compte:', 'ar': ': كشف حساب ' },
            { 'fr': 'Deux (02) photos', 'ar': ': صورتين شمسيتين ' },
            { 'fr': 'une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض لدى البنوك' },
            { 'fr': 'Une copie autorisation de prélèvement sur compte CCP', 'ar': 'CCP نسخة من تفويض الخصم المباشر من حساب' },
            { 'fr': 'Les bénéficiaires des accords conclus doivent prouver leur affiliation à ces organismes.', 'ar': 'على المستفيدين من الاتفاقيات المبرمة أن يثبتوا انتسابهم إلى هذه الهيئات' },
        ],

        'titre3': { 'fr': 'Préparez maintenant votre dossier TAKSIT et cliquez sur le lien ci-après', 'ar': 'الآن قم بإعداد ملف دار التقسيط الخاص بك وانقر على الرابط أدناه' },
    })





    const [idjardossier] = useState({

        'titre1': { 'fr': 'Dossier à fournir pour le financement via le service digital Dar IDJAR «دار الإيجار» ', 'ar': ' «Dar IDJAR» الملف المطلوب للاستفادة من خدمة الرقمية دار الايجار ' },
        'titre2': { 'fr': 'Pour les salariés et retraités ', 'ar': 'للموظفين والمتقاعدين' },
        'list1': [
            { 'fr': 'Demande de financement ', 'ar': 'استمارة طلب التمويل' },
            { 'fr': 'Autorisation de consultation de la centrale des requises entreprise et ménages de la banque d’Algérie CREM-BEF', 'ar': 'CREM-BEFالترخيص باستشارة مركزية المخاطر للافراد و الشركات لدرى البنك المركزي الجزائري ' },
            { 'fr': 'Tout document justifiant la propriété du bien (acte enregistré et publié, livret foncier…)', 'ar': 'الوثائق المثبتة لملكية العقار (عقد الملكية؛ دفتر عقاري؛ شهادة التخصيص ....)' },
            { 'fr': 'Extrait d acte de naissance N 12.', 'ar': ' نسخة من شهادة الميلاد رقم 12' },
            { 'fr': 'Certificat de résidence de moins de 3 mois', 'ar': '  نسخة من شهادة الاقامة لاتتجاوز ثلاثةاشهر' },
            { 'fr': 'Une fiche familiale pour les personnes mariées.', 'ar': 'شهادة عائلية للمتزوجين' },
            { 'fr': 'Une copie de la piéce identité nationale ou permis de conduire ou copie du passport et carte de séjour pour les résidents a l’étranger', 'ar': 'نسخة من بطاقة التعريف الوطنية او رخصة السياقة او جواز السفر او بطاقة الاقامة للمقيمين في الخارج' },
            { 'fr': 'Une attestation de travail récente', 'ar': 'شهادة عمل حديثة ' },
            { 'fr': 'Les trois (03) derniéres fiches de paie ou une attestation de pension délivrée par la Caisse Nationale de Retraite ', 'ar': 'كشف الراتب لثلاثة اشهر الاخيرة للاجراء او بيان منحة التقاعد او شهادة كشف الاجر من الضمان الاجتماعي للمتقاعدين' },
            { 'fr': 'Un extrait des role apuré recent daté de moins de trois (03) mois (Document non exigible lors du dépot du dossier et doit étre recuilli et remis au notaire pour l’établisement de l’acte location avant la mobilisation des fonds)', 'ar': 'مستخرج تدقيق ضريبي حديث لا يتعدى ثلاثة اشهر وثيقة غير مطلوبة عند ايداع الملف و يجب جمعها و ايداعها لدى موثق من اجل اصدار عقد الرهن قبل تعبأة الاموال ' },
            { 'fr': 'Un relvé de compte bancaire ou postal (CCP) des trois mois (03) derniers mois', 'ar': 'جدول الحساب البنكي او الحساب البريدي على مدى ثلاثة اشهر الاخيرة' },
            { 'fr': 'La déclaration annuelle des salaires (DAS) pour les employés du secteur privé.', 'ar': ' DAS التصريح السنوي بالأجور للعاملين في القطاع الخاص' },
            { 'fr': 'Une copie de la carte d affiliation a la sécurité sociale CNAS', 'ar': 'CNAS نسخة من شهادة الانخراط في الضمان الاجتماعي ' },
            { 'fr': 'Engagement de domiciliation de salaire', 'ar': 'توقيع مستخدم المتعامل على نموذج الالتزام بتوطين راتب الموظف طيلة مدة التمويل لدى المصرف' },
            { 'fr': 'Autorisation de prélévement CCP', 'ar': 'الترخيص باقتطاع الدائم من الحساب البريدي في حالة تعذر توطين الراتب' },
            { 'fr': 'Engagement de prélévement permanant sur salaire', 'ar': '(الالتزام باقتطاع الدائم  من الراتب من الحساب البنكي(في حالة حيازته على حساب بنكي' },
        ],

        'titre3': { 'fr': <>Pour les professions <br></br>libérales et les commerçants</>, 'ar': 'لاصحاب المهن الحرة والتجار' },
        'list2': [
            { 'fr': 'Demande de financement ', 'ar': 'استمارة طلب التمويل' },
            { 'fr': 'Autorisation de consultation de la centrale des requises entreprise et ménages de la banque d’Algérie CREM-BEF', 'ar': 'CREM-BEFالترخيص باستشارة مركزية المخاطر للافراد و الشركات لدرى البنك المركزي الجزائري ' },
            { 'fr': 'Tout document justifiant la propriété du bien (acte enregistré et publié, livret foncier…)', 'ar': 'الوثائق المثبتة لملكية العقار (عقد الملكية؛ دفتر عقاري؛ شهادة التخصيص ....)' },
            { 'fr': 'Une copie de la piéce identité nationale ou permis de conduire ou copie du passport et carte de séjour pour les résidents a l’étranger', 'ar': 'نسخة من بطاقة التعريف الوطنية او رخصة السياقة او جواز السفر او بطاقة الاقامة للمقيمين في الخارج' },
            { 'fr': 'Extrait d acte de naissance N 12.', 'ar': ' نسخة من شهادة الميلاد رقم 12' },
            { 'fr': 'Une fiche familiale pour les personnes mariées.', 'ar': 'شهادة عائلية للمتزوجين' },
            { 'fr': 'Certificat de résidence de moins de 3 mois', 'ar': 'نسختان (02) من شهادة الاقامة لاتتجاوز ثلاثة اشهر' },
            { 'fr': 'Une déclaration fiscale de revenu et / ou nilan fiscal ou C20 des (03) derniers exercices ', 'ar': 'C20 التصريح الضريبي عن الدخل و او ميزانية الضرائب خلال ثلاث سنوات الاخيرة' },
            { 'fr': 'Une copie du registre commerce', 'ar': 'نسخة من السجل التجاري ' },
            { 'fr': 'Une copie de l’agrément ou décision d’installation ', 'ar': 'نسخة من الاعتماد او قرار التثبيت' },
            { 'fr': 'Une attestation de mise a jour CASNOS et CNAS ', 'ar': 'CASNOS شهادة تحديث من الصندوق الوطني للضمان الاجتماعي لغير الاجراء و الصندوق الوطني للتامينات الاجتماعية' },
            { 'fr': 'Un extrait de role apuré récent daté de moins de trois (03) mois (document non exigible lors du dépot du dossier et doit étre recuilli avant la mobilisation des fonds ', 'ar': '(مستخرج تدقيق ضريبي حديث لا يتعدى ثلاثة اشهر (وثيقة غير مشترطة عند ايداع الملف لكن يتعين متابعة استفاءها قبل تعبئة التمويل' }
        ]
    })

    const [dari3mardossier] = useState({

        'titre1': { 'fr': 'Dossier à fournir pour le financement via le service digital Dar I3mar « دار الإعمار »', 'ar': ' «Dar I3MAR» ملـف طلـب تمويـل عبر الخدمة الرقمية دار الإعمار' },
        'titre2': { 'fr': 'Documents requis pour le demandeur de financement', 'ar': 'المستندات المطلوبة لطلب التمويل' },
        'list1': [
            { 'fr': 'L’Acte de naissance N° 12.', 'ar': ' شهادة الميلاد رقم 12' },
            { 'fr': 'Une (01) fiche familiale pour les personnes mariées.', 'ar': 'شهادة عائلية للمتزوجين' },
            { 'fr': 'Un Certificat de résidence moins de 03 mois.', 'ar': '  نسخة من شهادة الاقامة لاتتجاوز ثلاثةاشهر' },
            { 'fr': 'Une Copie de la pièce d’identité nationale ou permis de conduire ou copie du passeport et carte de séjour pour les résidents à l’étranger.', 'ar': 'نسخة من بطاقة التعريف الوطنية أو رخصة السياقة أو جواز السفر و بطاقة الإقامة للمقيمين بالخارج' },
            { 'fr': 'Une copie de la carte d’affiliation à la sécurité sociale CNAS.', 'ar': 'CNAS نسخة من بطاقة الانخراط في الضمان الإجتماعي' },
            { 'fr': ' Extrait de relevé de compte bancaire ou postal (CCP) des trois (03) derniers mois', 'ar': 'مستخرج الحساب البنكي أو الحساب البريدي على مدى (03) أشهر الأخيرة' },
            { 'fr': 'Un Extrait de rôle apuré récent daté de moins de trois (03) mois (document non exigible lors du dépôt du dossier et doit être recueilli et remis au Notaire pour l’établissement de l’acte de l’hypothèque avant la mobilisation des fonds).', 'ar': 'مستخرج تدقيق ضريبي حديث لا يتعدى (03) أشهر ( وثيقة غير مطلوبة عند إيداع الملف، و يجب جمعها و إيداعها لدى موثق من أجل إصدار عقد الرهن قبل تعبأة الأموال)' },
            { 'fr': 'Documents prouvant d’autres revenus non liés à l’activité professionnelle réelle.', 'ar': ' وثائق تثبت مداخيل أخرى غير متعلقة بالنشاط المهني الفعلي' },
        ],

        'titre3': { 'fr': 'Documents immobiliers', 'ar': 'الوثائق الخاصة بالعقار' },
        'sous-titre': { 'fr': 'La copie de l’acte de propriété enregistré et publié du bien immobilier ou justifier l’un des titres de propriété suivants', 'ar': 'نسخـة من عقد الملكية للعقار مسجل و مشهر، أو التبرير بأحد سندات الملكية التالية' },
        'list2': [
            { 'fr': 'Un Contrat de location OPGI accompagné d’une quittance de paiement des loyers mise à jour', 'ar': 'OPGI عقد إيجار ديوان الترقية و التسيير العقاري (مرفوق بإيصال دفع الإيجار حديث)' },
            { 'fr': 'Un Contrat de location APC accompagné d’une quittance de paiement des loyers', 'ar': 'عقد إيجار من مصالح البلدية مرفوق بإيصال دفع الإيجار' },
            { 'fr': 'Un Contrat de location-vente AADL .', 'ar': 'عقد إيجار - شراء AADL' },
            { 'fr': 'Une Décision d’attribution AADL accompagné d’un PV de remise des clefs (en cas d’absence du PV de remise des clefs exigé la dernière quittance d’électricité).', 'ar': 'AADL قرار تخصيص  مرفوق بمحضر تسليم المفاتيح (في حالة عدم توفر محضر تسليم المفاتيح يجب دفع إيصال الكهرباء) ' },
            { 'fr': 'Une Décision d’attribution LPP accompagné d’un PV de remise des clefs (en cas d’absence du PV de remise des clefs exigé la dernière quittance d’électricité)', 'ar': 'LPP قرار تخصيص  مرفوق بمحضر تسليم المفاتيح (في حالة عدم توفر محضر تسليم المفاتيح يجب دفع إيصال الكهرباء )' },
            { 'fr': 'Un Contrat de vente sur plans accompagné d’un procès-verbal de remise des clefs', 'ar': 'عقد بيع وفق مخططات مرفوق بمحضر تسليم المفاتيح ' },
            { 'fr': 'Un Certificat de possession accompagné d’une attestation d’achèvement d’une bâtisse délivré par les services de l’APC', 'ar': 'شهادة حيازة مرفوقة بشهادة إكمال المسكن صادرة من مصالح البلدية' },
            { 'fr': 'Un Acte de propriété publié et enregistré des logements sociaux participatifs (LSP)', 'ar': 'عقد ملكية مسجل و مشهر للسكنات الإجتماعية التساهمية LSP ' },
            { 'fr': 'Acte de propriété du terrain accompagné d’une attestation d’achèvement d’une bâtisse délivré par les services de l’APC.', 'ar': 'عقد ملكية للعقار مرفوق بشهادة إكمال المسكن صادرة من مصالح البلدية' },
        ],
        'titre4': { 'fr': 'Pour les salariés ajouter ', 'ar': 'اجراء اضافي للعمال الاجراء' },
        'list3': [
            { 'fr': ' Une attestation de travail récente.', 'ar': ' شهادة عمل حديثة؛ لا يزيد تاريخها عن شهر تثبت أن عقد العمل غير محددة المدة أو أن العامل مثبت بمنصبه' },
            { 'fr': 'Les trois (03) dernières fiches de paie', 'ar': ' كشف الراتب الشهري لثلاث ( 03 ) الأشهر الأخيرة للأجراء' },
            { 'fr': ' Le Relevé des émoluments du client, pour les salariés.', 'ar': ' كشف الراتب السنوي للإجراء' },
            { 'fr': ' La déclaration annuelle des salaires (DAS) pour les employés du secteur privé.', 'ar': 'لتصريح السنوي للأجور ( DAS ) – لعمال الشركات الخاصة' },
        ],
        'titre5': { 'fr': 'Pour les et retraités ajouter:', 'ar': 'اجراء اضافي للمتقاعدين' },
        'list4': [
            { 'fr': 'Une attestation de pension délivrée par la Caisse Nationale de Retraite.', 'ar': 'بيـان منحـة التقـاعـد أو شهـادة كشـف الأجـر مـن الضمـان الوطني للتقاعد' },
            { 'fr': ' Domiciliation dela pension de Retraite ou la signature du formulaire d’ autorisation de prélèvement postal (CCP )  l’egalise par service de la APC', 'ar': 'توطين منحة التقاعد طيلة مدة التمويل لدى مصرف السلام-الجزائر أو التوقيع على استمارة الترخيص بالاقتطاع البريدي  مصادق عليها من طرف مصالح البلدية ( في حالة تعذر توطين الراتب )' },
        ],
        'titre6': { 'fr': 'Pour les professions libérales et les commerçants ajouter:', 'ar': 'اجراء اضافي لاصحاب المهن الحرة و التجار' },
        'list5': [
            { 'fr': 'Le Rapport d’existence et d’expertise du bien (établi par un expert qualifié de la banque).', 'ar': '( تقرير الخبرة العقارية لتخمين العقار و الكشف الوصفي و الكمي لاشغال التهيئة او الترميم( معد من قبل خبير عقاري معتمد لدى المصرف' },
            { 'fr': 'Une copie de la piéce identité nationale ou permis de conduire', 'ar': 'نسخة من بطاقة التعريف الوطنية او رخصة السياقة ' },
            { 'fr': ' Déclaration fiscale de revenu (C20 ) et /ou bilan fiscal des 03 derniers exercices', 'ar': 'تصريح ضريبي عن الدخل و/أو ميزانية الضرائب خلال (03) السنوات الأخيرة' },
            { 'fr': ' Une Copie du registre de commerce', 'ar': 'نسخة من السجل التجاري' },
            { 'fr': ' Une Copie de l’agrément ou décision d’installation.', 'ar': ' نسخة من الإعتماد أو قرار التثبيت' },
            { 'fr': ' Une Attestation de mise à jour CASNOS et CNAS.', 'ar': ' شهادة تحديث من الصنـدوق الوطني للضمـان الإجتماعي لغيـر الأجراء و الصندوق الـوطني للتأمينات الإجتماعية' },
        ]
    });

    const [dari3marsection] = useState({

        'titre': { 'fr': 'C’est quoi DAR I3MAR ?', 'ar': 'ماهي دار الاعمار ؟' },
        'dossier': { 'fr': 'Dossier à fournir', 'ar': 'الملف المطلوب' },
        'Catégories': { 'fr': 'Catégories', 'ar': 'جميع التصنيفات' },
        'product': { 'fr': 'Produits', 'ar': 'منتجات' },
        'emptyproductmsg': { 'fr': 'Aucun produit disponible', 'ar': 'لا يوجد منتج متوفر' }
    });

    const [dari3mardef] = useState({


        'titre': { 'fr': 'C’est quoi DAR I3MAR ?', 'ar': 'ماهي دار الإعمار ؟' },
        'definition': { 'fr': 'Dar i3mar « دار الإعمار » : est un service Digital en ligne de Diar Dzair qui assure aux e-consommateurs l’aménagement en ligne avec Financement Immobilier –aménagement- Digital en ligne', 'ar': 'دار الإعمار":هي خدمة رقمية عبر الإنترنت لديار دزاير توفر للزبائن الإلكترونيين إمكانية اقتناء مواد البناء من خلال التمويل العقاري –الإعمار- الرقمي عبر الإنترنت' },
        'dossier': { 'fr': 'Dossier a fournir', 'ar': 'الملف المطلوب' },
    });


    const [Aide] = useState({

        'titre': { 'fr': 'Assistance par téléphone', 'ar': ' مرافقتكم عبر الهاتف' },
        'sous-titre': { 'fr': "Demander de l'assistance par téléphone", 'ar': 'اطلب المساعدة عن طريق الهاتف' },
        'Time': { 'fr': "Heures d'ouverture 7j/7 : 8:00 - 20:00", 'ar': ' ساعات العمل 7 أيام في الأسبوع: 8:00 صباحًا - 8:00 مساءً' },
    });

    const [FAQ] = useState({


        'titre1': { 'fr': 'Foire Aux Questions Trouvez les réponses à vos questions dans cette rubrique !', 'ar': 'الأسئلة الشائعة' },
        'titre2': { 'fr': 'Questions générales', 'ar': 'اسئلة عامة' },
        'QR1': [
            { 'Q': { 'fr': 'Qu’est-ce que « DIAR DZAIR » ?', 'ar': 'ما هي "ديار دزاير" ؟' }, 'R': { 'fr': 'Diar Dzair , e-commerce et innovation » est la 1re plateforme e-commerce avec Financement Islamique Digital en Ligne.Diar Dzair, e-commerce et innovation » est une Startup Algérienne, dont sa mission est l’innovation orientée vers la participation à la Digitalisation et le Développement du e-commerce en Algérie via sa plateforme E-commerce 100% Made in Algeria, développée par des compétences 100% Algériennes.', 'ar': 'ديار دزاير للتجارة الإلكترونية و الإبتكار هي أول منصة للتجارة الرقمية بتمويل إسلامي رقمي-عبر انترنت . ديار دزاير  للتجارة الإلكترونية والابتكار هي شركة ناشئة جزائرية ، مهمتها هي الابتكار الموجه نحو المساهمة في الرقمنة و تطوير التجارة الإلكترونية في الجزائر ، عبر منصتها للتجارة الإلكترونية 100٪ منشأة و مطورة في الجزائر من طرف كفاءات 100٪ جزائرية' } },
            { 'Q': { 'fr': 'Comment je peux accéder à ce site ?', 'ar': 'كيف يمكنني الولوج إلى الموقع ؟' }, 'R': { 'fr': 'www.diardzair.com.dz', 'ar': 'www.diardzair.com.dz' } },
            { 'Q': { 'fr': 'Où se situe « DIAR DZAIR » ?', 'ar': 'أين يقع المقر الرئيسي لديار دزاير ؟' }, 'R': { 'fr': '62 A, Lotissement des Jeunes Aveugles. Draria. 16050 Alger. Algérie', 'ar': 'أ62- شارع الشباب المكفوفين درارية 16050 - الجزائر' } },
            { 'Q': { 'fr': 'Y a-t-il un showroom/un magasin ?', 'ar': 'هل لديكم متجر ؟' }, 'R': { 'fr': 'Nous assurons la vente en ligne via notre web marchand. Nous ne disposons pas de showrooms. Notre boutique virtuelle est DiarDzair.com.dz', 'ar': 'www.diardzair.com.dz ديار دزاير تعتمد على البيع عبر الانترنت ـ تتيح للمستخدمين الالكترونيين موقعها عبر شبكة الانترنت من أجل تقديم مجموعة من المنتجات و الخدمات الرقمية . متجرنا الافتراضي هو ' } },
            { 'Q': { 'fr': 'Quelles sont les modes de paiement possibles ?', 'ar': 'ماهي طرق الدفع الممكنة ؟' }, 'R': { 'fr': <>Paiement en ligne via la carte monétique CIB.<br></br>  Paiement en ligne via la carte monétique EDAHABIA d’Algérie Poste.<br></br> Paiement à domicile</>, 'ar': 'الدفع من المنزل- . CIB الدفع عن طريق بطاقة سيب بين البنوك- . EDAHABIA الدفع ببطاقة الذهبية من بريد الجزائر' }, },
            { 'Q': { 'fr': 'C’est quoi les produits disponibles ?', 'ar': 'ماهي المنتجات المتوفرة ؟' }, 'R': { 'fr': 'Électroménagers , Meubles , Smartphones , Informatique , Motocycle , KIOSQUE , Cadeaux , Pack', 'ar': 'أجهزة كهرومنزلية - هدايا - أثاث المنزل - هواتف ذكية -  تخفيضات ديار دزاير - معلوماتية  - دراجات نارية - كشك متنقل'  } },
        ],
        'titre3': { 'fr': 'DAR TAKSIT', 'ar': 'دار التقسيط' },
        'QR2': [


            { 'Q': { 'fr': 'Qu’est- ce que « DAR TAKSIT » ?', 'ar': 'ماهي "دار التقسيط" ؟' }, 'R': { 'fr': '« Dar Taksit » est un service digital de Diar Dzair, disponible 24h/24 et 7j/7 sur www.diardzair.com.dz , vous permettant d’acheter en ligne et d’équiper votre maison immédiatement de chez vous sans avoir à vous déplacer, avec un Financement Islamique Digital en Ligne, sans apport personnel, paiement jusqu’ à 36 mois, accord de principe en instantanée, accord final en ligne, livraison à domicile pour les 48 wilayas ; Le tout en total conformité avec les préceptes de la Charia.', 'ar': '" دار التقسيط "هي خدمة "لديار دزاير"، و هو فضاء رقمي جديد متوفر 7أيام/7 و 24سا/24 على موقع ديار دزاير , يسمح لكم بالشراء عبر الانترنت و تجهيز منزلكم على الفور بدون عناء التنقل بواسطة تمويل إسلامي رقمي بدون مساهمة شخصية و دفع يصل إلى 36 شهر ، موافقة مبدئية فورية، موافقة نهائية آلية ، توصيل على كامل التراب الوطني. كل هذا موافق للشريعة الإسلامي.' }, },

            { 'Q': { 'fr': 'A qui s’adresse « DAR TAKSIT » ?', 'ar': 'من يمكنه الاستفادة من خدمة "دار التقسيط " ؟' }, 'R': { 'fr': 'A vous si vous remplissez ces conditions : -Être âgé entre 19 et 70 ans (dernière échéance). \n -Justifier d’un revenu stable et régulier domicilié auprès d’Algérie poste.\n -Justifier d’un salaire minimum de 25 000 DA.\n -Durée de financement allant de 12 à 36 mois.\n-Financement allant de 80 000 DA à 700 000 DA.\n -Mensualités ne dépassant pas 30% du revenu fixe', 'ar': "المستحقات الشهرية لا تتعدى 30 بالمائة من الراتب الشهري الثابت – تمويل تتراوح قيمته من 30.000دج إلى 100000دج مدة التمويل تتراوح من 12 إلى 36 شهرا . إثبات راتب شهري قيمته 25000دج كحد أدنى. اثبات دخل ثابت و منتظم ( موطن لدى حساب بريد الجزائر أو كامل البنوك عبر التراب الوطني – أن يكون سنكم يتراوح من 19 إلى 70 سنة) " }, },

            { 'Q': { 'fr': 'C’est quoi le dossier à fournir ?', 'ar': 'ما هو الملف المطلوب ؟' }, 'R': { 'fr': '-Justificatif d’identité en cours de validité (C.N.I. Biométrique) deux (02) exemplaires. \n -Acte de Naissance deux (02) exemplaires.\n -Justificatif de résidence de moins de 3 mois (Fiche de Résidence) deux (02) exemplaires.\n -Une (01) Fiche Familiale pour les personnes mariées. \n - Un (01) Extrait de Compte des trois (3) derniers mois.\n -Une attestation de travail de moins d’un (01) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé.\n -Trois (03) dernières Fiches de Paie.\n - Deux (02) photos.\n -Une copie d’échéancier en cas de crédit en cours.\n -Un chèque barré. \n -Une autorisation de prélèvement sur compte CCP légalisée auprès de l’A.P.C. imprimable de puis le site Diar Dzair', 'ar': ["\nصك بريدي باسم المتعامل مشطوب عليه-", "\nنسخة من جدول السداد في حالة وجود قرض لدى البنوك الزميلة-", "\nصورتين شمسيتين-", "\nالموظفين: كشف راتب للأشهرالثلاثة (03)الأخيرة ومصادق عليها من صاحب العمل*", "المتقاعدين:شهادة منحة التقاعد*", "\nإثبات الدخل-", "\nشهادةعمل لايزيد تاريخها عن شهرتثبت أن عقد العمل غيرمحدود المدة وأن العامل مرسم في منصبه-", "\nكشف حساب للأشهرالثلاث الأخيرة-", "\nشهـادة عائليـة للمتزوجين-", "\nنسختين من بطاقة اقامة قيد الصلاحية،لا تتعدى 3 أشهر-", "\nنسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني-"] }, },

            { 'Q': { 'fr': 'La livraison est-elle disponible dans les 48 wilayas ?', 'ar': 'هل خدمة التوصيل متوفرة في 48 ولاية ؟' }, 'R': { 'fr': 'Oui. La livraison est disponible pour le territoire national', 'ar': 'نعم، التوصيل متوفر في كامل التراب الوطني.' }, },

            { 'Q': { 'fr': 'Comment je m’inscris pour pouvoir bénéficier du service « DAR TAKSIT » et passer ma commande ?', 'ar': 'كيف يمكنني التسجيل في الموقع و الاستفادة من الخدمة ؟' }, 'R': { 'fr': 'Veuillez nous appeler : 0982 300 500', 'ar': 'يرجى الإتصال بنا : 0982 300 500 ' } },

            { 'Q': { 'fr': 'C’est quoi le financement MINIMUM du service « DAR TAKSIT » ?', 'ar': 'ماهو الحد الأدنى للتمويل ؟' }, 'R': { 'fr': '30 000 DA', 'ar': '30.000 دج' }, },

            { 'Q': { 'fr': 'C’est quoi le financement MAXIMUM du service « DAR TAKSIT » ?', 'ar': 'ماهو الحد الأقصى للتمويل ؟' }, 'R': { 'fr': '100 000 DA', 'ar': '100.000 دج' }, },

            { 'Q': { 'fr': 'C’est quoi le salaire MINIMUM du E-consommateur ?', 'ar': 'ماهو الراتب الشهري الأدنى اللازم للإستفادة من خدمتكم ؟' }, 'R': { 'fr': '20 000 DA', 'ar': '20.000 دج' }, },

            { 'Q': { 'fr': 'Que dois-je faire si j’ai un souci avec le produit ?', 'ar': 'ما ذا افعل اذا واجهت مشكلة بعد مع المنتج ؟' }, 'R': { 'fr': 'Veuillez nous contacter aux :.\n  -TEL: 0982 300 500\n  -Site: www.diardzair.com.dz.\n  -Email: helpdesk@diardzair.com.dz', 'ar': [' يرجى التواصل معنا \n ', '\nhelpdesk@diardzair.com.dz *', '\nwww.diardzair.com.dz *', '\n0982 300 500 *'] }, },

            { 'Q': { 'fr': 'C’est quoi les frais que je dois payer ?', 'ar': 'ماهي الرسوم التي علي دفعها ؟' }, 'R': { 'fr': '-Frais de ramassage à domicile du dossier Taksit (non remboursables).\n -Frais de livraison à domicile des produits achetés en ligne dans le cadre du service Dar Taksit (non remboursables).\n -Frais de service Taksit (non remboursables).', 'ar': ['\nرسوم الخدمات دار تقسيط (غير قابلة للاسترجاع)-', '\nتكاليف التوصيل للمنزل للمنتجات المشتراة عبرالانترنت كجزء من الخدمة دارالتقسيط (غير قابلة للاسترجاع)-', '\nرسوم جمع ملف تقسيط من المنزل(غير قابلة للاسترجاع)-'] }, },

            { 'Q': { 'fr': 'Quel est votre délai de livraison ?', 'ar': 'ما هو متوسط الوقت للتسليم ؟' }, 'R': { 'fr': 'Après le paiement des frais de service ainsi que les frais de livraison de la commande, les produits achetés en ligne dans le cadre du service Dar Taksit seront livrés à domicile au plus tard (15) jours à compter de la date de paiement des frais de service et des frais de livraison pour les régions des Hauts plateaux et le sud de l’Algérie et au plus tard sept (07) jours à compter de la date de paiement des frais de service et des frais de livraison pour les reste des régions de l’Algérie hors Hauts plateaux et le sud de l’Algérie', 'ar': 'بعد دفع رسوم الخدمة بالإضافة إلى رسوم التوصيل ، سيتم تسليم المنتجات المشتراة عبر الإنترنت كجزء من خدمة دار التقسيط إلى منزلك في موعد لا يتجاوز (15) يومًا من تاريخ دفع رسوم التوصيل و تكاليف الخدمة لمناطق المرتفعات وجنوب الجزائر وفي موعد لا يتجاوز سبعة (07) أيام من تاريخ دفع رسوم الخدمة وتكاليف التوصيل لباقي مناطق الجزائر (الجزائر خارج المرتفعات وجنوب الجزائر)' }, },

            { 'Q': { 'fr': 'Puis-je annuler ma commande ?', 'ar': 'هل يمكنني الغاء طلبي ؟' }, 'R': { 'fr': 'Oui. Vous pouvez l’annuler avant la livraison.', 'ar': 'نعم يمكن الغاؤه قبل التسليم' } },

            { 'Q': { 'fr': 'Elle sert à quoi la pré-simulation ?', 'ar': 'ما هو الهدف من المحاكاة الأولية ؟' }, 'R': { 'fr': 'Cette opération vous permet de calculer le montant de financement autorisé par la banque sur la base de votre salaire et votre âge et ce, pour arrêter le montant max de votre panier relatif à vos capacités d’achat en ligne (le montant max autorisé de votre panier).', 'ar': 'قبل تأكيد سلة المشتريات ، يجب على المستهلك الإلكتروني محاكاة رصيد تقسيط الخاص به مسبقًا على أساس سنه و راتبه هذه العملية تسمح له بمعرفة قدرته الشرائية (الحد الأقصى المسموح به لسلة المشتريات) .' }, },

            { 'Q': { 'fr': 'Elle sert à quoi la simulation ?', 'ar': 'ماهو الهدف من المحاكاة ؟' }, 'R': { 'fr': '-Après la validation de son panier dans la limite du montant de financement calculé dans la pré-simulation, l’E-consommateur doit faire, sur le site du E-fournisseur, une simulation détaillée de son crédit à la consommation conforme Charia. Cette opération lui permettra de savoir les détails ci-après:\n * Montant à financer.\n  * Montant des remboursements.\n * Montant total de la marge.\n * Montant total de la TVA.\n * Durée de remboursement.\n-Ainsi que tous les frais liés au service Dar Taksit, à savoir:\n *Frais de ramassage à domicile du dossier Taksit (non remboursables).\n *Frais de livraison à domicile des produits achetés en ligne dans le cadre du service Dar Taksit (non remboursables).\n *Frais de service Dar Taksit (non remboursables).', 'ar': ['\nرسوم خدمة تقسيط (غير قابلة للاسترداد)*', '\nتكاليف التوصيل إلى المنزل للمنتجات المطلوبة كجزء من خدمة تقسيط (غير قابلة للاسترداد) *', '\nرسوم جمع الملف من المنزل و الخاص بخدمة تقسيط (غير قابلة للاسترداد)*', '\nوكذلك جميع التكاليف المتعلقة بملف تقسيط وهي', '\nمدة السداد*', '\nإجمالي مبلغ الضريبة على القيمة المضافة*', '\nالمبلغ الإجمالي للهامش*', '\nمبلغ السداد*', '\nمبلغ التمويل*', '\n:بعد تأكيد سلة المشتريات على أساس مبلغ التمويل المصرح به والمحسوب في المحاكاة الأولية ، يجب أن يقوم المستهلك الإلكتروني بمحاكاة رصيد تقسيط الخاص به ، وهذا الأخير يسمح له بمعرفة'] }, },

            { 'Q': { 'fr': 'J’ai besoin d’aide. Comment je peux vous contactez ?', 'ar': 'هل أنت بحاجة للمساعدة ؟' }, 'R': { 'fr': '- Pour une assistance particulière en rapport avec le service digital Dar Taksit, l’E-consommateur est invité à transmettre un e-mail, en fournissant les renseignements nécessaires au traitement de sa requête à l’adresse suivante : helpdesk@diardzair.com.dz.\n - En appelant aux : 0982 300 500\n - www.diardzair.com.dz', 'ar': [<>اتصل بنا : 0982 300 500  <br /></>, <>  للحصول على مساعدة خاصة فيما يتعلق بخدمة دار التقسيط الرقمية ، يُدعى المستهلك الإلكتروني لإرسال بريد إلكتروني ، مع توفير المعلومات اللازمة لمعالجة طلبه إلى العنوان التالي <span>helpdesk@diardzair.com.dz</span></>] }, },

        ]


    })


    const [Panier] = useState({

        'panier': { 'fr': 'Panier', 'ar': 'سلة التسوق' },
        'Produits': { 'fr': 'Produits', 'ar': 'المنتجات' },
        'prix_total': { 'fr': 'Prix Total', 'ar': 'المبلغ الاجمالي' },
        'Quantité': { 'fr': 'Quantité', 'ar': 'الكمية' },
        'Commander': { 'fr': 'Commander', 'ar': 'طلب الشراء' },
        'Continuermesachats': { 'fr': 'Continuer mes achats', 'ar': 'مواصلة التسوق' },
        'paniervide': { 'fr': 'Votre panier est vide', 'ar': 'سلتك فارغة' },
    })

    const [Account] = useState({

        'Retour': { 'fr': 'Retour au Diar Dzair', 'ar': 'العودة إلى موقع ديار دزاير' },
        'Créeruncompte': { 'fr': 'Créer un compte', 'ar': 'إفتح حساب' },
        'Seconnecter': { 'fr': 'Se connecter', 'ar': 'تسجيل الدخول' },
        'Nomdutilisateur': { 'fr': "Nom d'utilisateur", 'ar': 'إسم المستخدم' },
        'MotdePasse': { 'fr': 'Mot de Passe', 'ar': 'كلمة السر' },
        'e-mail': { 'fr': 'Adresse e-mail', 'ar': 'البريد الإلكتروني' },
        'Répéterlemotdepasse': { 'fr': 'Répéter le mot de passe', 'ar': 'أعد كلمة السر' },
        'Motdepasseoublié': { 'fr': 'Mot de passe oublié ?', 'ar': 'نسيت كلمة السر ؟' },

    })

    const [infoperssonnelle] = useState({
        'titre': { 'fr': 'Information Personnelle', 'ar': 'المعلومات الشخصية' },

        'civilité': { 'fr': 'Civilité  *', 'ar': '  * الحالة المدنية'  },
        'civ1': { 'fr': 'M', 'ar': 'السيد' },
        'civ2': { 'fr': 'Mme', 'ar': 'السيدة' },
        'civ3': { 'fr': 'Mle', 'ar': 'الآنسة' },
        'Nom': { 'fr': 'Nom  *', 'ar': ' * اللقب' },
        'msgerr1': { 'fr': ' Veuillez renseigner votre nom.', 'ar': 'يرجى إدخال اللقب.' },
        'msgerr2': { 'fr': ' Veuillez saisir votre nom sans chiffres ni caractères spéciaux.', 'ar': 'يرجى إدخال اللقب بدون أرقام أو أحرف خاصة' },
        'Prénom': { 'fr': 'Prénom  *', 'ar': ' * الإسم' },
        'msgerr3': { 'fr': 'Veuillez renseigner votre prenom.', 'ar': 'يرجى ادخال الاسم' },
        'msgerr4': { 'fr': ' Veuillez saisir votre prenom sans chiffres ni caractères spéciaux', 'ar': ' يرجى إدخال اسمكم بدون أرقام أو أحرف خاصة' },
        'Prénompère': { 'fr': 'Prénom du père  *', 'ar': ' * إسم الأب ' },
        'msgerr5': { 'fr': 'Veuillez renseigner votre Prénom du père', 'ar': ' يرجى إدخال إسم الأب .' },
        'msgerr6': { 'fr': 'Veuillez saisir votre prenom pére sans chiffres ni caractères spéciaux', 'ar': ' يرجى إدخال اسم الاب بدون أرقام أو أحرف خاصة' },
        'Nommère': { 'fr': 'Nom de la mère  *', 'ar': ' * لقب الأم ' },
        'msgerr7': { 'fr': ' Veuillez renseigner votre nom du mère.', 'ar': 'يرجى ادخال لقب الام' },
        'msgerr8': { 'fr': ' Veuillez saisir votre nom du mère sans chiffres ni caractères spéciaux.', 'ar': ' يرجى إدخال لقب الام بدون أرقام أو أحرف خاصة' },
        'Prenommère': { 'fr': 'Prenom de mère  *', 'ar': ' * إسم الأم' },
        'msgerr9': { 'fr': 'Veuillez renseigner votre prénom du mère.', 'ar': 'يرجى ادخال اسم الام' },
        'msgerr10': { 'fr': 'Veuillez saisir votre prénom du mère sans chiffres ni caractères spéciaux.', 'ar': ' يرجى إدخال اسم الام بدون أرقام أو أحرف خاصة' },
        'Date_naissance': { 'fr': 'Date de naissance  *', 'ar': ' * تاريخ الميلاد' },
        'msgerr11': { 'fr': 'Veuillez renseigner votre date de naissance.', 'ar': ' يرجى ادخال تاريخ الميلاد' },
        'Wilaya_naissance': { 'fr': 'Wilaya de naissance  *', 'ar': ' * ولاية الميلاد' },
        'msgerr12': { 'fr': 'Veuillez renseigner votre wilaya de naissance.', 'ar': 'يرجى ادخال ولاية الميلاد' },
        'Commune_naissance': { 'fr': 'Commune de naissance  *', 'ar': ' * بلدية الميلاد' },
        'msgerr13': { 'fr': 'Veuillez renseigner votre commune de naissance.', 'ar': 'يرجى ادخال بلدية الميلاد' },
        'Num_act_naissance': { 'fr': 'Numéro d’acte de naissance  *', 'ar': ' * رقم شهادة الميلاد' },
        'msgerr14': { 'fr': ' Saisissez votre numéro d’cte de naissance à 5 chiffres.', 'ar': 'أدخل رقم شهادة الميلاد المكون من 5 أرقام' },
        'type_acte': { 'fr': 'Type d’acte de naissance  *', 'ar': ' * نمط شهادة الميلاد' },
        'msgerr15': { 'fr': 'Veuillez renseigner votre type act naissnce .', 'ar': 'أدخل نمط شهادة الميلاد' },
        'situation_familiale': { 'fr': 'Situation familiale  *', 'ar': ' * الحالة العائلية' },
        'situation_familiale_list':
            [{ 'fr': 'Célibataire', 'ar': 'عازب', 'value': 'c' },
            { 'fr': 'Marié ', 'ar': 'متزوج', 'value': 'm' },
            { 'fr': 'Divorcé ', 'ar': 'مطلق', 'value': 'd' },
            { 'fr': 'veuf ', 'ar': 'أرمل', 'value': 'v' },
            ],

        'msgerr16': { 'fr': ' Veuillez renseigner votre Situation familiale  *', 'ar': ' * يرجى تحديد الوضعية العائلية' },
        'Niveau': { 'fr': 'Niveau D’instructions  *', 'ar': ' * المستوى الدراسي' },
        'Niveau_list':
            [{ 'fr': 'Moins que le niveau secondaire', 'ar': 'أقل من المستوى الثانوي', 'value': 1 },
            { 'fr': 'Niveau secondaire', 'ar': 'مستوى ثانوي', 'value': 2 },
            { 'fr': 'Bachelier', 'ar': 'طالب بكالوريا', 'value': 3 },
            { 'fr': 'Universitaire', 'ar': 'جامعي', 'value': 4 },
            ],
        'msgerr17': { 'fr': 'Veuillez renseigner votre Niveau D’instructions  *', 'ar': ' * يرجى تحديد  مستواكم الدراسي' },
        'nbrEnfants': { 'fr': 'Nombre d’enfants à charge  *', 'ar': ' * عدد الأطفال' },
        '1': { 'fr': 'Enfants:', 'ar': 'الاطفال' },
        'nbrpersonnes': { 'fr': 'Nombre d’autres personnes à charge  *', 'ar': ' * عدد المعالين الآخرين' },
        '2': { 'fr': 'Personnes:', 'ar': 'الاشخاص' }
    })

    const [adresse] = useState({
        'titre': { 'fr': 'Adresse  *', 'ar': ' * معلومات المتعلقة بالعنوان' },
        'Wilaya': { 'fr': 'Wilaya  *', 'ar': ' * الولاية' },
        'msgerr1': { 'fr': 'Veuillez renseigner votre wilaya .', 'ar': ' يرجى تحديد الولاية' },
        'Daïras': { 'fr': 'Daïras  *', 'ar': ' * الدائرة' },
        'msgerr2': { 'fr': 'Veuillez renseigner votre dairas .', 'ar': ' يرجى تحديد الدائرة' },
        'Commune': { 'fr': 'Commune  *', 'ar': ' * البلدية' },
        'msgerr3': { 'fr': 'Veuillez renseigner votre commune.', 'ar': ' يرجى تحديد البلدية.' },
        'Codepostal': { 'fr': 'Code Postal  *', 'ar': ' * الرمز البريدي' },
        'msgerr4': { 'fr': 'Veuillez renseigner votre code postal', 'ar': 'يرجى إدخال الرقم البريدي' },
        'Adresse': { 'fr': 'Adresse  *', 'ar': ' * العنوان' },
        'msg1': { 'fr': 'Saisissez votre adresse : boulevard Zighout Youcef bt C N°41.', 'ar': ' C 41 ادخل عنوانك مثال : شارع زيغود يوسف رقم ' },
        'msgerr5': { 'fr': 'Veuillez renseigner votre adresse', 'ar': 'يرجى إدخال عنوانك' },
        'Mobile': { 'fr': 'Mobile  *', 'ar': ' * الهاتف' },
        'Mobile2': { 'fr': 'Mobile  2', 'ar': ' 2 الهاتف' },

        'msg2': { 'fr': 'Saisissez votre numéro téléphone portable à 10 chiffres commençant par 06 ou 07 ou 05 ', 'ar': ' ادخل رقم هاتفك المحمول المكون من 10 أرقام بدءًا من 06 أو 07 او 05' },
        'msgerr6': { 'fr': ' Veuillez renseigner votre Mobile.', 'ar': ' يرجى إدخال رقم هاتفكم' },
        'msgerr61': { 'fr': 'Vous devez entrer un numéro de téléphone valide (Djezzy Ooredoo Mobilis)', 'ar': 'يجب إدخال رقم هاتف صالح (جازي أوريدو موبيليس)' },
        'msgerr62': { 'fr': 'Le premier numéro de téléphone et le deuxième numéro de téléphone ne doivent pas correspondre', 'ar': 'رقم الهاتف الأول ورقم الهاتف الثاني يجب ألا يكونا متطابقين' },
        'email': { 'fr': 'Adresse email  *', 'ar': ' * البريد الإلكتروني' },
        'msgerr7': { 'fr': 'Veuillez renseigner votre Adresse email.', 'ar': 'يرجى إدخال بريدكم الإلكتروني' },
        'msgerr71': { 'fr': 'Vous devez entrer un email valide.', 'ar': 'يجب عليك إدخال بريد إلكتروني صالح' },
        'confirmemail': { 'fr': 'Confirmez l’adresse email *', 'ar': '* تأكيد البريد الإلكتروني' },
        'msgerr8': { 'fr': 'Veuillez renseigner votre Adresse email.', 'ar': 'يرجى إدخال بريدكم الإلكتروني' },
        'msgerr9': { 'fr': ' Les adresses e-mail saisies sont différentes.', 'ar': ' عناوين البريد الإلكتروني التي تم إدخالها مختلفة' },
        'NIN': { 'fr': 'NIN (le Numéro d’Identification Nationale)  *', 'ar': ' * NIN (رقم الهوية الوطنية)' },
        'msgerr10': { 'fr': 'Veuillez renseigner votre NIN (le Numéro d’Identification Nationale)', 'ar': 'يرجى إدخال رقم التعريف الوطني' },
        'msgerr11': { 'fr': 'Veuillez renseigner votre NIN (Le « NIN» est composé de 18 chiffres)', 'ar': ' يرجى إدخال (رقم التعريف الوطني) مكون من 18 رقم' },
        'msg3': { 'fr': '(Le « NIN» est composé de 18 chiffres)', 'ar': ' (رقم التعريف الوطني) مكون من 18 رقم' },
        'numcn': { 'fr': 'Numéro de carte national *', 'ar': '* رقم بطاقة التعريف الوطنية' },
        'msgerr12': { 'fr': ' Veuillez renseigner votre Numero carte national.', 'ar': 'يرجى إدخال رقم بطاقة التعريف الوطني' },
        'msgerr12-': { 'fr': ' Le Numéro carte national doit comporter 9 chiffres.', 'ar': 'يجب أن يتكون رقم بطاقة التعريف الوطنية من 9 أرقام' },
        'Date_délivrence': { 'fr': 'Date de délivrence *', 'ar': '* تاريخ الإصدار' },
        'msgerr13': { 'fr': 'Veuillez renseigner votre date de délivrence', 'ar': 'يرجى تحديد  تاريخ الإصدار' },
        'Wilaya_délivrence': { 'fr': 'Wilaya de délivrence *', 'ar': '* ولاية الإصدار' },
        'msgerr14': { 'fr': 'Veuillez renseigner votre wilaya de délivrence ', 'ar': 'يرجى تحديد ولاية الإصدار' },
        'Commune_délivrence': { 'fr': 'Commune de délivrence *', 'ar': '* بلدية ' },
        'msgerr15': { 'fr': 'Veuillez renseigner votre commune de délivrence ', 'ar': ' يرجى تحديد بلدية  الإصدار' },
        'Habitation': { 'fr': 'Habitation *', 'ar': '* مكان السكن' },
        'Habitation_list':
            [{ 'fr': 'Avec les parents', 'ar': 'مع الوالدين', 'value': 1 },
            { 'fr': 'Locataire ', 'ar': 'المستأجر', 'value': 2 },
            { 'fr': 'Propriétaire ', 'ar': 'صاحب', 'value': 3 },
            { 'fr': 'Logt de Fonction ', 'ar': 'سكن وظيفي', 'value': 4 },
            ],
        'msgerr16': { 'fr': 'Veuillez renseigner votre habitation  ', 'ar': ' يرجى تحديد مكان السكن.' },
    })

    const [situationprofessionnelle] = useState({

        'titre': { 'fr': 'Situation Professionnelle', 'ar': '* الوضعية المهنية ' },
        'secteuractiv': { 'fr': 'Secteur d’activité *', 'ar': 'قطاع النشاط' },
        'secteuractiv_list':
            [{ 'fr': 'Public', 'ar': 'عمومي', 'value': "p" },
            { 'fr': 'Privé ', 'ar': 'قطاع خاص', 'value': "v" },
            { 'fr': 'Retraité ', 'ar': 'متقاعد', 'value': "r" },
            ],
        'secteuractiv_err': { 'fr': "Veuillez renseigner votre Secteur d'activité", 'ar': 'يرجى تحديد قطاع النشاط' },
        'Profession': { 'fr': 'Profession *', 'ar': '* المهنة' },
        'msgerr1': { 'fr': 'Veuillez renseigner votre profession.', 'ar': 'يرجى تحديد مهنتكم ' },
        'tyoe_contrat': { 'fr': 'Type de contrat *', 'ar': '* نوع العقد' },
        'msgerr12': { 'fr': 'Veuillez renseigner votre type de contrat.', 'ar': 'يرجى تحديد نوع العقد ' },
        'période_essai': { 'fr': 'Période d’essai terminée ?', 'ar': 'انتهت الفترة التجريبية؟ ' },
        'Ancienneté': { 'fr': 'Ancienneté de votre emploi actuel depui?', 'ar': 'مدة العمل الحالية منذ؟' },
        'msgerr13': { 'fr': 'Veuillez renseigner le mois de debut d’emploi.', 'ar': 'يرجى تحديد الشهر' },
        'msgerr14': { 'fr': 'Veuillez renseigner l’année de debut d’emploi.', 'ar': 'يرجى تحديد السنة' },
        'msgerr14_': { 'fr': 'Vous devez entrer une date valide.', 'ar': 'يجب عليك إدخال تاريخ صالح' },
        'nom_emp': { 'fr': 'Nom de l’employeur', 'ar': 'إسم الموظف' },
        'msgerr15': { 'fr': 'Veuillez renseigner le nom de votre employeur.', 'ar': 'يرجى تحديد إسم الموظف' },
        'msgerr15_': { 'fr': "Veuillez saisir votre Nom de l'employeur sans chiffres ni caractères spéciaux.", 'ar': 'يرجى تحديد إسم الموظف بدون أرقام أو أحرف خاصة'  },
        'wilaya_emp': { 'fr': 'Wilaya de l’employeur', 'ar': 'ولاية الموظف' },
        'msgerr16': { 'fr': 'Veuillez renseigner la wilaya de votre employeur.', 'ar': ' يرجى تحيد ولاية الموظف' },
        'adresse_emp': { 'fr': 'Adresse de l’employeur *', 'ar': '* عنوان الموظف' },
        'msgerr17': { 'fr': 'Veuillez renseigner l’adresse de votre employeur.', 'ar': 'يرجى تحديد عنوان الموظف' },
        'tel_prof': { 'fr': 'Téléphone professionnel', 'ar': 'رقم الهاتف المهني' },
        'saliare': { 'fr': 'Salaire net mensuel', 'ar': 'صافي الراتب الشهري' },
        'saliare_info': { 'fr': 'Ce salaire est celui que vous avez saisie dans la pré-simulation', 'ar': 'هذا الراتب هو الراتب الذي أدخلته في المحاكاة المسبقة' },

        'saliare_err': { 'fr': 'Veuillez saisir votre Salaire sans chiffres ni caractères spéciaux. Max 7 chiffres , Min 5 chiffres', 'ar': 'الرجاء إدخال راتبك بدون أرقام أو رموز خاصة. 7 أرقام كحد أقصى ، 5 أرقام كحد أدنى' },

        'msg': { 'fr': 'Ce salaire est le même que vous avez saisi dans la pré-simulation', 'ar': 'هذا الراتب هو نفسه الذي أدخلته في مرحلة ما قبل المحاكاة' },
        'cmp': { 'fr': 'Votre compte ?', 'ar': 'حسابك' },
        'r1': { 'fr': 'CCP', 'ar': 'CCP' },
        'r2': { 'fr': 'Banque', 'ar': 'البنك' },
        'msgerr18': { 'fr': 'Veuillez renseigner votre compte ccp.', 'ar': 'الرجاء إدخال رمز حساب CCP الخاص بك' },
        'msgerr18_': { 'fr': 'Veuillez renseigner votre Clé CCP.', 'ar': 'الرجاء إدخال مفتاح CCP الخاص بك' },
        'msgerr18__': { 'fr': 'votre Clé CCP doit être composée de deux chiffres.', 'ar': 'يجب أن يتكون مفتاح CCP الخاص بك من رقمين' },

        'msgerr19': { 'fr': 'Veuillez renseigner votre banque', 'ar': 'يرجى تحديد  يرجى تحديد بنك معاملاتكم.' },
        'Comptesalam': { 'fr': 'Avez-vous un Compte Al Salam Bank-Algeria ?', 'ar': 'هل لديكم حساب في مصرف السلام-الجزائر؟' },
        'r3': { 'fr': 'oui', 'ar': 'نعم' },
        'r4': { 'fr': 'non', 'ar': 'لا' },
        'revenus': { 'fr': 'Avez-vous d’autre sources de revenus ?', 'ar': 'هل لديك مصادر دخل أخرى؟' },
        'r5': { 'fr': 'oui', 'ar': 'نعم' },
        'r6': { 'fr': 'non', 'ar': 'لا' }

    })

    const [chargesetpatrimoine] = useState({
        'Q1': { 'fr': 'Avez-vous un crédit emprunt immobilier en cours ', 'ar': 'هل لديك قرض عقاري حالي ' },
        'r1': { 'fr': 'oui', 'ar': 'نعم' },
        'r2': { 'fr': 'non', 'ar': 'لا' },
        'Q1Mensualité': { 'fr': 'Mensualité du crédit immobilier Da/Mois', 'ar': 'قرض عقاري شهري - دج/للشهر' },
        'Q1Mensualitéerr': { 'fr': 'Veuillez renseigner votre Mensualité du crédit immobilier', 'ar': 'يرجى تحديد قرضكم العقاري الشهري' },
        'Q1Organisme': { 'fr': 'Organisme prêteur immobilier', 'ar': 'مقرض عقاري' },
        'Q1Organismeerr': { 'fr': 'Veuillez renseigner votre Organisme prêteur immobilier', 'ar': 'يرجى تحديد مصرف القرض العقاري' },
        'Q1Date': { 'fr': 'Date de fin du crédit immobilier', 'ar': 'تاريخ إنتهاء القرض العقاري' },
        'Q1Dateerr': { 'fr': 'Veuillez renseigner votre Date de fin du crédit immobilier', 'ar': 'يرجى تحديد تاريخ إنتهاء القرض العقاري .' },


        'Q2': { 'fr': 'Avez-vous un crédit voiture en cours ?', 'ar': 'هل لديك قرض سيارة حالي ' },
        'r3': { 'fr': 'oui', 'ar': 'نعم' },
        'r4': { 'fr': 'non', 'ar': 'لا' },
        'Q2Mensualité': { 'fr': 'Mensualité du crédit voiture Da/Mois', 'ar': 'سداد قرض السيارة شهرياً - دج/للشهر' },
        'Q2Mensualitéerr': { 'fr': 'Veuillez renseigner votre Mensualité du crédit voiture', 'ar': 'الرجاء إدخال رصيد سيارتك الشهري' },
        'Q2Organisme': { 'fr': 'Organisme prêteur de crédit voiture', 'ar': 'مقرض ائتمان السيارة' },
        'Q2Organismeerr': { 'fr': 'Veuillez renseigner votre Organisme prêteur de crédit voiture', 'ar': 'يرجى تحديد مصرف إقراض سيارتك' },
        'Q2Date': { 'fr': 'Date de fin du crédit voiture', 'ar': 'تاريخ إنتهاء قرض السيارة' },
        'Q2Dateerr': { 'fr': 'Veuillez renseigner votre Organisme prêteur de Date de fin du crédit voiture', 'ar': 'يرجى تحديد تاريخ بداية و نهاية قرض سيارتكم' },
        


        'Q3': { 'fr': 'Avez-vous un Autre crédit en cours ?', 'ar': 'هل لديكم قرض جاري آخر ؟' },
        'r5': { 'fr': 'oui', 'ar': 'نعم' },
        'r6': { 'fr': 'non', 'ar': 'لا' },
        'Q3Mensualité': { 'fr': 'Mensualité du autre crédit Da/Mois', 'ar': 'سداد قرض شهري آخر - دج/للشهر' },
        'Q3Mensualitéerr': { 'fr': 'Veuillez renseigner votre Mensualité du autre crédit', 'ar': 'الرجاء إدخال دفعتك الشهرية للرصيد الآخر' },
        'Q3Organisme': { 'fr': 'Organisme prêteur autre crédit', 'ar': 'مقرض ائتماني آخر' },
        'Q3Organismeerr': { 'fr': 'Veuillez renseigner votre Date de fin du autre crédit', 'ar': 'يرجى تحديد المصرف المقرض للائتمان الآخر' },
        'Q3Date': { 'fr': 'Date de fin du autre crédit', 'ar': 'تاريخ إنتهاء القرض الآخر' },
        'Q3Dateerr': { 'fr': 'Veuillez renseigner votre Date de fin du autre crédit', 'ar': 'يرجى تحديد إنتهاء القرض الأخر' },



        'Q4': { 'fr': 'Avez-vous un Loyer à payer en cours ?', 'ar': 'هل لديك إيجار حالي لدفعه؟' },
        'r7': { 'fr': 'oui', 'ar': 'نعم' },
        'r8': { 'fr': 'non', 'ar': 'لا' },
        'Q4Loyer': { 'fr': 'Loyer', 'ar': 'الإيجار' },
        'Q4Loyererr': { 'fr': 'Veuillez renseigner votre Loyer', 'ar': 'يرجى إدخال مبلغ إيجاركم' },

        'Q5': { 'fr': 'Séjour plus de 183 jours aux Etats Unis (USA) ?', 'ar': 'إقامة مؤقتة لأكثر من 183 يومًا في الولايات المتحدة (الولايات المتحدة)؟' },
        'r9': { 'fr': 'oui', 'ar': 'نعم' },
        'r10': { 'fr': 'non', 'ar': 'لا' },
        'Q6': { 'fr': 'Possession d’une carte verte « Green Card » ?', 'ar': 'حيازة "البطاقة الخضراء"؟' },
        'r11': { 'fr': 'oui', 'ar': 'نعم' },
        'r12': { 'fr': 'non', 'ar': 'لا' },
        'Q7': { 'fr': 'Avez-vous un Compte épargne ?', 'ar': 'هل لديك حساب التوفير؟' },
        'r13': { 'fr': 'oui', 'ar': 'نعم' },
        'r14': { 'fr': 'non', 'ar': 'لا' },
        'Q7épargne': { 'fr': 'Compte épargne', 'ar': 'حساب التوفير' },
        'Q7épargneerr': { 'fr': 'Veuillez renseigner Votre Compte épargne', 'ar': 'رجى تحديد حساب توفيركم' },

        'Q8': { 'fr': 'Biens immeubles ou meubles', 'ar': 'العقارات أو الأثاث' },
        'msg': { 'fr': 'Saisissez votre Biens immeubles ou meubles, hors prime, arrondi au dinar près sans virgule ', 'ar': 'أدخل عقارك أو أثاثك ، بدون علاوات ، وتقريبه إلى أقرب دينار بدون فاصلة' }


    })

    const [confirmation] = useState({
        'titre': { 'fr': 'Confirmation', 'ar': 'التأكيد' },
        'texte': { 'fr': 'Veuillez assurer la certitude des informatins saisies, en cas de contradiction entre les informations saisis et le dossier fourni, votre dossier sera rejeté et ou un dossier complémentaire vous sera demandé et les frais d’un deuxième ramassage vous seront imposés.', 'ar': 'يرجى التأكد من صحة المعلومات التي تم إدخالها ، في حالة التناقض بين المعلومات التي تم إدخالها والملف المقدم ، سيتم رفض ملفك أو سيتم طلب ملف إضافي وستُفرض عليك تكاليف جمع الملف الثاني' },
        'confirmation': { 'fr': 'Voulez vous confirmer ?', 'ar': ' تريد التاكيد؟' },
        'captcha': { 'fr': 'Veuillez vérifier le captcha pour passer', 'ar': 'يرجى فحص "CAPTCHA" للمرور' },
        'condition': { 'fr': "Conditions d'utilisation", 'ar': 'شروط الإستخدام' },
        'conditioncheck': { 'fr': "J'accepte toutes les conditions d'utilisation", 'ar': 'أنا أقبل جميع شروط الاستخدام' },
        'conditionerr': { 'fr': "Veuillez lire et accepter les conditions d'utilisation", 'ar': 'يرجى قراءة شروط الإستخدام والموافقة عليها' },


    })

    const [FormulairetTitels] = useState({
        'titre1': { 'fr': 'Information Personnelle', 'ar': 'المعلومات الشخصية' },
        'titre2': { 'fr': 'Adresse', 'ar': 'معلومات المتعلقة بالعنوان' },
        'titre3': { 'fr': 'Situation Professionnelle', 'ar': 'الوضعية المهنية' },
        'titre4': { 'fr': 'Charges Patrimoine', 'ar': 'رسوم التراث' },
        'titre5': { 'fr': 'Confirmation', 'ar': 'التأكيد' },
    })

    // const[validation]= useState({

    //     'titre1':{'fr':'Votre demande TAKSIT N° est validée avec l’accord de principe de la banque','ar':'طلبيتم رقم تم الموافقة عليها مبدئيا من طرف البنك'},
    //     'titre2':{'fr':'L’accord final vous sera transmis par e-mail, et ce après étude et validation de votre dossier physique par la banque.','ar':'سيتم إرسال الاتفاقية النهائية إليك عبر البريد الإلكتروني ، بعد دراسة ملفك والتحقق من صحته من قبل البنك'},
    //     'titre3':{'fr':'Dossier TAKSIT à fournir :','ar':'TAKSIT ملف طلب خدمة '},


    //     '1': {'fr': 'Justificatif d’identité en cours de validité (C.N.I biométrique) (02) deux exemplaires', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني'},
    //     '2': {'fr': 'Acte de naissance (02) deux exemplaires', 'ar': 'نسختين من شهادة الميلاد (02) '},
    //     '3': {'fr': 'Justificatif de résidence de moins de 3 mois (Fiche de résidence) (02) deux exemplaires', 'ar':'سختين من بطاقة اقامة قيد الصلاحية،لا تتعدى 3 أشهر'},
    //     '4': {'fr': 'Une (01) fiche familiale pour les personnes mariées', 'ar': 'شهـادة عائليـة للمتزوجين'},
    //     '5': {'fr': 'L’extrait de comptes des trois (3) derniers mois', 'ar': 'كشف حساب للأشهرالثلاث الأخيرة'},
    //     '6': {'fr': 'Une attestation de travail de moins d’un (01) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': 'شهادةعمل لايزيد تاريخها عن شهر تثبت أن عقد العمل غيرمحدود المدة وأن العامل مرسم في منصبه'},
    //     '7': {'fr': 'Justificatif de revenus ', 'ar': 'إثبات الدخل'},
    //     '8': {'fr': 'Retraités : attestation de perception de retraite', 'ar': 'المتقاعدين: شهادة منحة التقاعد'},
    //     '9': {'fr': 'Salariés : trois (03) dernières fiches de paie', 'ar': 'الموظفين: كشف راتب للأشهرالثلاثة (03)الأخيرة ومصادق عليها من صاحب العمل'},
    //     '10': {'fr': 'Un chèque CCP barré', 'ar': 'صك بريدي باسم المتعامل مشطوب عليه'},
    //     '11': {'fr': 'Deux (02) photos', 'ar':'صورتين شمسيتين'},
    //     '12': {'fr': 'Une copie de l’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض لدى البنوك الزميلة'},
    //     '13': {'fr': 'Une copie autorisation de prélèvement sur compte CCP', 'ar':'CCP نسخة من تفويض الخصم المباشر من حساب'},


    //     'titre4':{'fr':'Demande :','ar':'الطلبية'},
    //     'Numero': {'fr': 'Numero', 'ar':'رقم الطلب'},
    //     'date': {'fr': 'Date', 'ar':'التاريخ'},
    //     'durée': {'fr': 'Durée de rembourssement', 'ar':'فترة السداد'},
    //     'mensualité': {'fr': 'Mensualité', 'ar':'الدفع الشهري'},
    //     'total_rem': {'fr': 'Total de rembourssement', 'ar':'مبلغ السداد'},



    //     'titre42':{'fr':'Vos informations personnelle :','ar':'معلوماتكم الشخصية'},
    //     'civilité': {'fr': 'Civilité', 'ar':'الحالة المدنية'},
    //     'Nom': {'fr': 'Nom', 'ar':'للقب'},
    //     'Prénom': {'fr': 'Prénom', 'ar':'الإسم'},
    //     'Prénompère': {'fr': 'Prénom du père', 'ar':'إسم الأب '},
    //     'Nommère': {'fr': 'Nom de la mère', 'ar':'لقب الأم '},
    //     'Prenommère': {'fr': 'Prenom de mère', 'ar':'إسم الأم'},
    //     'Date_naissance': {'fr': 'Date de naissance', 'ar':'تاريخ الميلاد'},
    //     'Wilaya_naissance': {'fr': 'Wilaya de naissance', 'ar':'ولاية الميلاد'},
    //     'Commune_naissance': {'fr': 'Commune de naissance', 'ar':'بلدية الميلاد'},
    //     'Num_act_naissance': {'fr': 'Numéro d’acte de naissance', 'ar':'رقم شهادة الميلاد'},
    //     'type_acte': {'fr': 'Type d’acte de naissance', 'ar':'نمط شهادة الميلاد'},
    //     'situation_familiale': {'fr': 'Situation familiale', 'ar':'لحالة العائلية'},
    //     'Niveau': {'fr': 'Niveau D’instructions', 'ar':'المستوى الدراسي'},
    //     'nbrEnfants': {'fr': 'Nombre d’enfants à charge', 'ar':'عدد الأطفال'},
    //     'nbrpersonnes': {'fr': 'Nombre d’autres personnes à charge', 'ar':'عدد المعالين الآخرين'},


    //     'titre5':{'fr':'Adresse','ar':'معلومات المتعلقة بالعنوان'},
    //     'Wilaya': {'fr': 'Wilaya', 'ar':'الولاية'},
    //     'Daïras': {'fr': 'Daïras', 'ar':'الدائرة'},
    //     'Commune': {'fr': 'Commune', 'ar':'البلدية'},
    //     'Codepostal': {'fr': 'Code Postal', 'ar':'الرمز البريدي'},
    //     'Adresse': {'fr': 'Adresse', 'ar':'العنوان'},
    //     'Mobile': {'fr': 'Mobile', 'ar':'الهاتف'},
    //     'email': {'fr': 'Adresse email', 'ar':'البريد الإلكتروني'},
    //     'NIN': {'fr': 'NIN (le Numéro d’Identification Nationale)', 'ar':'NIN (رقم الهوية الوطنية) *'},
    //     'numcn': {'fr': 'Numéro de carte national', 'ar':'رقم بطاقة التعريف الوطنية'},
    //     'Date_délivrence': {'fr': 'Date de délivrence', 'ar':'تاريخ الإصدار'}, 
    //     'Wilaya_délivrence': {'fr': 'Wilaya de délivrence', 'ar':'ولاية الإصدار'},
    //     'Commune_délivrence': {'fr': 'Commune de délivrence', 'ar':'بلدية '},
    //     'Habitation': {'fr': 'Habitation', 'ar':'مكان السكن'}, 


    //     'titre52':{'fr':'Situation Professionnelle','ar':'الوضعية المهنية'},
    //     'secteuractiv':{'fr':'Secteur d’activité','ar':'قطاع النشاط'},
    //     'Profession':{'fr':'Profession','ar':'المهنة'},
    //     'titre6':{'fr':'Revenus','ar':'الدخل'},
    //     'secteuractiv':{'fr':'Secteur d’activité','ar':'قطاع النشاط'},
    //     'Compte':{'fr':'Compte CCP','ar':'الحساب البريدي'},
    //     'bank':{'fr':'Banque','ar':'البنك'},
    //     'titre88':{'fr':'Charge et patrimoine','ar':'الاعباء و الممتلكات'},
    //     'charges':{'fr':'Avez-vous un Compte épargne ?','ar':'هل لديك حساب التوفير؟'},

    //  })


    const [validation] = useState({

        'titre1': { 'fr': 'Votre demande TAKSIT N° est validée avec l’accord de principe de la banque', 'ar': 'طلبيتم رقم تم الموافقة عليها مبدئيا من طرف البنك' },
        'titre2': { 'fr': 'L’accord final vous sera transmis par e-mail, et ce après étude et validation de votre dossier physique par la banque.', 'ar': <>سيتم إرسال الاتفاقية النهائية إليك عبر البريد الإلكتروني ، بعد دراسة ملفك والتحقق من صحته من قبل البنك<br /> سيتم الإتصال بكم من قبل المكلفين بالزبائن لدينا من أجل تأكيد موعد جمع ملفكم من منزلكم</>},


        'titre3': { 'fr': 'Dossier à fournir', 'ar': 'ملف طلب خدمة' },


        'CCP':

        {
            'public': [

                { 'fr': 'deux (02) copies de la carte nationale biométrique', 'ar': ' نسختين(2) من بطاقة التعريف البيىومترية' },
                { 'fr': 'deux (02) actes de naissance', 'ar': ' نسختين من شهادة الميلاد' },
                { 'fr': 'deux (02) résidences', 'ar': 'نسختين من بطاقة الإقامة' },
                { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                { 'fr': 'les (03) dernières fiches de paies des (03) derniers mois', 'ar': 'كشف الراتب للأشهر الثلاثة (3) الأخيرة' },
                { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للأشهر الثلاثة (3) الأخيرة' },
                { 'fr': 'Une attestation de travail de moins de (03) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': ' شهادة عمل حديثة لا تتعدى 3 أشهر مع ضرورة اثبات أن عقد العمل غير منتهى المدة' },
                { 'fr': 'Deux (02) photos', 'ar': 'صورتين شمسيتين' },
                { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                { 'fr': 'Un chèque barré', 'ar': ' صك بريدي مشطوب' },
                { 'fr': 'Une autorisation de prélèvement sur compte CCP légalisée auprès de l’A.P.C. imprimable depuis le site Diar Dzair', 'ar': 'الترخيص بالإقتطاع مصادق عليه من طرف مصالح البلدية و ممضي عليه' },

            ],
            // 'indépendant': [

            //     { 'fr': 'Copie de la carte fiscale pour les entrepreneurs individuels ET profession libérale et les commerçants …etc.', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Un extrait de rôle apure de date récente ou un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Les bilans et comptes de résultats des trois derniers exercices', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Une attestation de mise à jour fiscale et parafiscale de date récente', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Relevé de compte pour les trois derniers mois', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Les trois derniers avertissements fiscaux ou tout autre justificatif de revenu', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Mise à jour CNAS et CASNOS apurée cas échéant un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Copie du registre de commerce', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Copie de largement', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },

            // ],
            'retraite': [

                { 'fr': 'deux (02) copies de la carte nationale biométrique', 'ar': ' نسختين(2) من بطاقة التعريف البيىومترية' },
                { 'fr': 'deux (02) actes de naissance', 'ar': 'نسختين (2) من شهادة الميلاد' },
                { 'fr': 'deux (02) résidences', 'ar': 'نسختين (2) من بطاقة الإقامة' },
                { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للثلاثة أشهر (3) الأخيرة' },
                { 'fr': 'Une attestation de revenu de moins de (03) mois', 'ar': ' شهادة إثبات الدخل للثلاث الأشهر الأخيرة' },
                { 'fr': 'Deux (02) photos', 'ar': ' صورتين شمسيتين' },
                { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                { 'fr': 'Un chèque barré', 'ar': 'صك بريدي مشطوب' },
                { 'fr': 'Une autorisation de prélèvement sur compte CCP légalisée auprès de l’A.P.C. imprimable depuis le site Diar Dzair', 'ar': ' الترخيص بالإقتطاع مصادق عليه من طرف مصالح البلدية و ممضي عليه' },


            ],
            'prive': [

                { 'fr': 'une copie de la carte d’identité biométrique', 'ar': 'نسخة من بطاقة الهوية البيومترية' },
                { 'fr': 'fiche familiale', 'ar': 'شهادة عائلية' },
                { 'fr': 'acte de naissance', 'ar': 'شهادة الميلاد' },
                { 'fr': 'certificat de résidence', 'ar': 'بطاقة إقامة قيد الصلاحية، لا تتعدى 3 أشهر' },
                { 'fr': 'attestation de travail récente cdi au permanant', 'ar': 'شهادة عمل لا يزيد تاريخها عن شهر تثبت أن عقد العمل غير محدود المدة وأن العامل مرسم في منصبه' },
                { 'fr': 'les trois dernières fiches de paies de trois dernier mois ou le relevé des émoluments', 'ar': 'كشف راتب للأشهر الثلاثة (03)الأخيرة ومصادق عليها من صاحب العمل او كشف الراتب السنوي' },
                { 'fr': 'le relevé de compte d’une année', 'ar': 'كشف الحساب لمدة سنة' },
                { 'fr': 'un chèque barré', 'ar': 'صك بريدي باسم المتعامل مشطوب عليه' },
                { 'fr': 'affiliation CNAS', 'ar': 'شهادة تحديث من الصندوق الوطني للتأمينات الاجتماعية' },
                { 'fr': 'déclaration annuelle des salaires', 'ar': 'التصريح السنوي بالأجور' },
                { 'fr': 'mise à jour de financement on cas de crédit', 'ar': 'تحديث التمويل أو حالة الائتمان في حالة وجود قرض' },


            ]
        },

        'Banque':

        {
            'public': [

                { 'fr': 'une (01) copie de la carte nationale biométrique', 'ar': 'نسخة من بطاقة التعريف البيىومترية' },
                { 'fr': 'un (01) acte de naissance', 'ar': 'نسخة من شهادة الميلاد' },
                { 'fr': 'une (01) résidence', 'ar': 'نسخة من بطاقة الإقامة' },
                { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للثلاثة الأشهر (3) الأخيرة' },
                { 'fr': 'Une attestation de travail de moins de (03) mois', 'ar': 'شهادة عمل حديثة لا تتعدى 3 أشهر' },
                { 'fr': 'Les (03) dernières fiches de paie', 'ar': 'كشف الراتب للثلاثة الأشهر (3) الأخيرة' },
                { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                { 'fr': 'Un chèque barré', 'ar': 'صك مشطوب' },
                { 'fr': 'Une autorisation de prélèvement bancaire accusé de la banque où vous étés domiciliés imprimable depuis le site Diar Dzair', 'ar': 'الترخيص بالإقتطاع مصادق عليه من طرف البنك و ممضي عليه' },

            ],
            // 'indépendant': [

            //     { 'fr': 'Copie de la carte fiscale pour les entrepreneurs individuels ET profession libérale et les commerçants …etc.', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Un extrait de rôle apure de date récente ou un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Les bilans et comptes de résultats des trois derniers exercices', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Une attestation de mise à jour fiscale et parafiscale de date récente', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Relevé de compte pour les trois derniers mois', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Les trois derniers avertissements fiscaux ou tout autre justificatif de revenu', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Mise à jour CNAS et CASNOS apurée cas échéant un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Copie du registre de commerce', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
            //     { 'fr': 'Copie de largement', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },

            // ],
            'retraite': [

                { 'fr': 'une (01) copie de la carte nationale biométrique', 'ar': 'نسخة من بطاقة التعريف البيىومترية' },
                { 'fr': 'une (01) acte de naissance', 'ar': ' نسخة من شهادة الميلاد' },
                { 'fr': 'une (01) résidence', 'ar': 'نسخة من بطاقة الإقامة' },
                { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للثلاثة أشهر (3) الأخيرة' },
                { 'fr': 'Une attestation de revenu de moins de (03) mois', 'ar': 'شهادة إثبات الدخل حديثة (لا تتعدى ثلاثة أشهر)' },
                { 'fr': ' Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض أخر' },
                { 'fr': 'Un chèque barré', 'ar': 'صك مشطوب' },
                { 'fr': 'Une autorisation de prélèvement accusée de la banque ou vous étés domiciliés', 'ar': 'الترخيص بالإقتطاع مصادق عليه من طرف البنك و ممضي عليه' },

            ],
            'prive': [

                { 'fr': 'une (01) copie de la carte nationale biométrique', 'ar': 'نسخة من بطاقة التعريف البيىومترية' },
                { 'fr': 'un (01) acte de naissance', 'ar': 'نسخة من شهادة الميلاد' },
                { 'fr': 'deux résidences', 'ar': ' نسختين (2) من بطاقة الإقامة' },
                { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                { 'fr': 'les (03) dernières fiches de paies des (03) derniers mois', 'ar': 'كشف الراتب للثلاثة أشهر (3) الأخيرة' },
                { 'fr': 'Un (01) Extrait de Compte Annuel', 'ar': 'كشف الحساب السنوي ( سنة واحدة)' },
                { 'fr': 'Une attestation de travail de moins de (03) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': ' شهادة عمل حديثة لا تتعدى 3 أشهر مع ضرورة اثبات أن عقد العمل غير منتهى المدة' },
                { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                { 'fr': 'Un chèque barré', 'ar': 'صك مشطوب' },
                { 'fr': 'Une autorisation de prélèvement accusée de la banque ou vous étés domiciliés', 'ar': ' الترخيص بالاقتطاع مصادق عليه من طرف البنك و ممضي عليه' },
                { 'fr': 'Une copie de la déclaration annuelle des salaires extraite du lieu de travail', 'ar': ' نسخة من بيان الراتب السنوي' },
                { 'fr': 'Affiliation', 'ar': 'شهادة الانتساب' },


            ]
        },

        '1': { 'fr': 'Justificatif d’identité en cours de validité (C.N.I biométrique) (02) deux exemplaires', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
        '2': { 'fr': 'Acte de naissance (02) deux exemplaires', 'ar': 'نسختين من شهادة الميلاد (02) ' },
        '3': { 'fr': 'Justificatif de résidence de moins de 3 mois (Fiche de résidence) (02) deux exemplaires', 'ar': 'نسختين من بطاقة اقامة قيد الصلاحية،لا تتعدى 3 أشهر' },
        '4': { 'fr': 'Une (01) fiche familiale pour les personnes mariées', 'ar': 'شهـادة عائليـة للمتزوجين' },
        '5': { 'fr': 'L’extrait de comptes des trois (3) derniers mois', 'ar': 'كشف حساب للأشهرالثلاث الأخيرة' },
        '6': { 'fr': 'Une attestation de travail de moins d’un (01) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': 'شهادةعمل لايزيد تاريخها عن شهر تثبت أن عقد العمل غيرمحدود المدة وأن العامل مرسم في منصبه' },
        '7': { 'fr': 'Justificatif de revenus ', 'ar': 'إثبات الدخل' },
        '8': { 'fr': 'Retraités : attestation de perception de retraite', 'ar': 'المتقاعدين: شهادة منحة التقاعد' },
        '9': { 'fr': 'Salariés : trois (03) dernières fiches de paie', 'ar': 'الموظفين: كشف راتب للأشهرالثلاثة (03)الأخيرة ومصادق عليها من صاحب العمل' },
        '10': { 'fr': 'Un chèque CCP barré', 'ar': 'صك بريدي باسم المتعامل مشطوب عليه' },
        '11': { 'fr': 'Deux (02) photos', 'ar': 'صورتين شمسيتين' },
        '12': { 'fr': 'Une copie de l’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض لدى البنوك الزميلة' },
        '13': { 'fr': 'Une copie autorisation de prélèvement sur compte CCP', 'ar': 'CCP نسخة من تفويض الخصم المباشر من حساب' },



        'titre49': { 'fr': 'Dossier TAKSIT à fournir BNA :', 'ar': 'TAKSIT ملف طلب خدمة ' },
        '1': { 'fr': 'Justificatif d’identité en cours de validité (C.N.I biométrique) ', 'ar': 'نسخة من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
        '2': { 'fr': 'Un acte de naissance ', 'ar': 'نسخة من شهادة الميلاد  ' },
        '3': { 'fr': 'Justificatif de résidence de moins de 3 mois (Fiche de résidence) ', 'ar': 'نسخة من بطاقة اقامة قيد الصلاحية،لا تتعدى 3 أشهر' },
        '4': { 'fr': 'Une  fiche familiale pour les personnes mariées', 'ar': 'شهـادة عائليـة للمتزوجين' },
        '5': { 'fr': 'Une attestation de travail de moins d’un (01) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': 'شهادةعمل لايزيد تاريخها عن شهر تثبت أن عقد العمل غيرمحدود المدة وأن العامل مرسم في منصبه' },
        '6': { 'fr': 'Justificatif de revenus ', 'ar': 'إثبات الدخل' },
        '7': { 'fr': 'Retraités : attestation de perception de retraite', 'ar': 'المتقاعدين: شهادة منحة التقاعد' },
        '8': { 'fr': 'Salariés : trois (03) dernières fiches de paie', 'ar': 'الموظفين: كشف راتب للأشهرالثلاثة (03)الأخيرة ومصادق عليها من صاحب العمل' },
        '9': { 'fr': 'Un chèque CCP barré', 'ar': 'صك بريدي باسم المتعامل مشطوب عليه' },
        '10': { 'fr': 'Mise a jour de financement en cas de crédit en cours', 'ar': 'نسخة من تحديث التمويل في حالة وجود قرض لدى البنوك الزميلة' },
        '11': { 'fr': 'L’extrait de comptes:', 'ar': ': كشف حساب ' },
        '12': { 'fr': 'Public: ', 'ar': 'الوظيف العمومي' },
        '13': { 'fr': ' Le relevé de comptes des trois 03 derniers mois ', 'ar': 'كشف حساب للأشهر الثلاث الأخيرة' },
        '14': { 'fr': 'Privé: ', 'ar': 'الوظيف الخاص' },
        '15': { 'fr': 'Le relevé de comptes d’une année ', 'ar': 'كشف حساب لسنة كاملة' },
        '16': { 'fr': 'La déclaration annuelle des salaires (DAS) pour les employés du secteur privé.', 'ar': ' DAS التصريح السنوي بالأجور للعاملين في القطاع الخاص' },
        '17': { 'fr': 'Une copie de la carte d’affiliation a la sécurité sociale CNAS', 'ar': 'CNAS نسخة من شهادة الانخراط في الضمان الاجتماعي ' },
        '18': { 'fr': 'Une copie autorisation de prélèvement sur compte CCP', 'ar': 'CCP نسخة من تفويض الخصم المباشر من حساب' },
        '19': { 'fr': 'Les bénéficiaires des accords conclus doivent prouver leur affiliation à ces organismes.', 'ar': 'على المستفيدين من الاتفاقيات المبرمة أن يثبتوا انتسابهم إلى هذه الهيئات' },
        '20': { 'fr': 'Préparez maintenant votre dossier TAKSIT et cliquez sur le lien ci-après', 'ar': 'الآن قم بإعداد ملف دار التقسيط الخاص بك وانقر على الرابط أدناه' },

        'titre4': { 'fr': 'Demande :', 'ar': 'الطلبية' },
        'Numero': { 'fr': 'Numero', 'ar': 'رقم الطلب' },
        'date': { 'fr': 'Date', 'ar': 'التاريخ' },
        'durée': { 'fr': 'Durée de rembourssement', 'ar': 'فترة السداد' },
        'mensualité': { 'fr': 'Mensualité', 'ar': 'الدفع الشهري' },
        'total_rem': { 'fr': 'Total de rembourssement', 'ar': 'مبلغ السداد' },



        'titre42': { 'fr': 'Vos informations personnelle :', 'ar': 'معلوماتكم الشخصية' },
        'civilité': { 'fr': 'Civilité', 'ar': 'الحالة المدنية' },
        'Nom': { 'fr': 'Nom', 'ar': 'اللقب' },
        'Prénom': { 'fr': 'Prénom', 'ar': 'الإسم' },
        'Prénompère': { 'fr': 'Prénom du père', 'ar': 'إسم الأب ' },
        'Nommère': { 'fr': 'Nom de la mère', 'ar': 'لقب الأم ' },
        'Prenommère': { 'fr': 'Prenom de mère', 'ar': 'إسم الأم' },
        'Date_naissance': { 'fr': 'Date de naissance', 'ar': 'تاريخ الميلاد' },
        'Wilaya_naissance': { 'fr': 'Wilaya de naissance', 'ar': 'ولاية الميلاد' },
        'Commune_naissance': { 'fr': 'Commune de naissance', 'ar': 'بلدية الميلاد' },
        'Num_act_naissance': { 'fr': 'Numéro d’acte de naissance', 'ar': 'رقم شهادة الميلاد' },
        'type_acte': { 'fr': 'Type d’acte de naissance', 'ar': 'نمط شهادة الميلاد' },
        'situation_familiale': { 'fr': 'Situation familiale', 'ar': 'الحالة العائلية' },
        'Niveau': { 'fr': 'Niveau D’instructions', 'ar': 'المستوى الدراسي' },
        'nbrEnfants': { 'fr': 'Nombre d’enfants à charge', 'ar': 'عدد الأطفال' },
        'nbrpersonnes': { 'fr': 'Nombre d’autres personnes à charge', 'ar': 'عدد المعالين الآخرين' },


        'titre5': { 'fr': 'Adresse', 'ar': 'معلومات المتعلقة بالعنوان' },
        'Wilaya': { 'fr': 'Wilaya', 'ar': 'الولاية' },
        'Daïras': { 'fr': 'Daïras', 'ar': 'الدائرة' },
        'Commune': { 'fr': 'Commune', 'ar': 'البلدية' },
        'Codepostal': { 'fr': 'Code Postal', 'ar': 'الرمز البريدي' },
        'Adresse': { 'fr': 'Adresse', 'ar': 'العنوان' },
        'Mobile': { 'fr': 'Mobile', 'ar': 'الهاتف' },
        'Mobile2': { 'fr': 'Mobile 2', 'ar': '2 الهاتف' },
        'email': { 'fr': 'Adresse email', 'ar': 'البريد الإلكتروني' },
        'NIN': { 'fr': 'NIN (le Numéro d’Identification Nationale)', 'ar': 'NIN (رقم الهوية الوطنية) *' },
        'piece': { 'fr': "Pièce D'identité", 'ar': 'بطاقة التعريف' },
        'numcn': { 'fr': 'Numéro de carte national', 'ar': 'رقم بطاقة التعريف الوطنية' },
        'Date_délivrence': { 'fr': 'Date de délivrence', 'ar': 'تاريخ الإصدار' },
        'Wilaya_délivrence': { 'fr': 'Wilaya de délivrence', 'ar': 'ولاية الإصدار' },
        'Commune_délivrence': { 'fr': 'Commune de délivrence', 'ar': 'بلدية ' },
        'Habitation': { 'fr': 'Habitation', 'ar': 'مكان السكن' },


        'titre52': { 'fr': 'Situation Professionnelle', 'ar': 'الوضعية المهنية' },
        'secteuractiv': { 'fr': 'Secteur d’activité', 'ar': 'قطاع النشاط' },
        'Profession': { 'fr': 'Profession', 'ar': 'المهنة' },
        'TypeDeContrat': { 'fr': 'Type De Contrat ', 'ar': 'نوع العقد' },
        'titre6': { 'fr': 'Revenus', 'ar': 'الدخل' },
        'secteuractiv': { 'fr': 'Secteur d’activité', 'ar': 'قطاع النشاط' },
        'Salaire': { 'fr': 'Salaire Net Mensuel', 'ar': 'صافي الراتب الشهري' },
        'Compte': { 'fr': 'Compte', 'ar': 'الحساب' },
        'bank': { 'fr': 'Banque', 'ar': 'البنك' },
        'titre88': { 'fr': 'Charge et patrimoine', 'ar': 'الاعباء و الممتلكات' },
        'charges': { 'fr': 'Avez-vous un Compte épargne ?', 'ar': 'هل لديك حساب التوفير؟' },
        'charge': { 'fr': 'Charges', 'ar': 'Charges' },



        'btn-downlaod-ccp': { 'fr': 'l’autorisation de prélèvement sur compte CCP', 'ar': 'CCP  تفويض الخصم من حسابك البريدي الجاري' },
        'btn-downlaod-ccp-link': { 'fr': 'Imprimer', 'ar': 'طباعة' },

        'btn-downlaod-banque': { 'fr': 'L’autorisation de prélèvement bancaire', 'ar': 'الحصول على تفويض الخصم المباشر' },
        'btn-downlaod-banque-link': { 'fr': 'Cliquez ici', 'ar': 'انقر هنا' },



    })


    const [BanqueDossier] = useState(
        {

            'CCP':

            {
                'public': [

                    { 'fr': 'deux (02) copies de la carte nationale biométrique', 'ar': ' نسختين(2) من بطاقة التعريف البيىومترية' },
                    { 'fr': 'deux (02) actes de naissance', 'ar': ' نسختين من شهادة الميلاد' },
                    { 'fr': 'deux (02) résidences', 'ar': 'نسختين من بطاقة الإقامة' },
                    { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                    { 'fr': 'les (03) dernières fiches de paies des (03) derniers mois', 'ar': 'كشف الراتب للأشهر الثلاثة (3) الأخيرة' },
                    { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للأشهر الثلاثة (3) الأخيرة' },
                    { 'fr': 'Une attestation de travail de moins de (03) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': ' شهادة عمل حديثة لا تتعدى 3 أشهر مع ضرورة اثبات أن عقد العمل غير منتهى المدة' },
                    { 'fr': 'Deux (02) photos', 'ar': 'صورتين شمسيتين' },
                    { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                    { 'fr': 'Un chèque barré', 'ar': ' صك بريدي مشطوب' },
                    { 'fr': 'Une autorisation de prélèvement sur compte CCP légalisée auprès de l’A.P.C. imprimable depuis le site Diar Dzair', 'ar': 'الترخيص بالإقتطاع مصادق عليه من طرف مصالح البلدية و ممضي عليه' },

                ],
                // 'indépendant': [

                //     { 'fr': 'Copie de la carte fiscale pour les entrepreneurs individuels ET profession libérale et les commerçants …etc.', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Un extrait de rôle apure de date récente ou un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Les bilans et comptes de résultats des trois derniers exercices', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Une attestation de mise à jour fiscale et parafiscale de date récente', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Relevé de compte pour les trois derniers mois', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Les trois derniers avertissements fiscaux ou tout autre justificatif de revenu', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Mise à jour CNAS et CASNOS apurée cas échéant un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Copie du registre de commerce', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Copie de largement', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },

                // ],
                'retraite': [

                    { 'fr': 'deux (02) copies de la carte nationale biométrique', 'ar': ' نسختين(2) من بطاقة التعريف البيىومترية' },
                    { 'fr': 'deux (02) actes de naissance', 'ar': 'نسختين (2) من شهادة الميلاد' },
                    { 'fr': 'deux (02) résidences', 'ar': 'نسختين (2) من بطاقة الإقامة' },
                    { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                    { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للثلاثة أشهر (3) الأخيرة' },
                    { 'fr': 'Une attestation de revenu de moins de (03) mois', 'ar': ' شهادة إثبات الدخل للثلاث الأشهر الأخيرة' },
                    { 'fr': 'Deux (02) photos', 'ar': ' صورتين شمسيتين' },
                    { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                    { 'fr': 'Un chèque barré', 'ar': 'صك بريدي مشطوب' },
                    { 'fr': 'Une autorisation de prélèvement sur compte CCP légalisée auprès de l’A.P.C. imprimable depuis le site Diar Dzair', 'ar': ' الترخيص بالإقتطاع مصادق عليه من طرف مصالح البلدية و ممضي عليه' },


                ],
                'prive': [

                    { 'fr': 'une copie de la carte d’identité biométrique', 'ar': 'نسخة من بطاقة الهوية البيومترية' },
                    { 'fr': 'fiche familiale', 'ar': 'شهادة عائلية' },
                    { 'fr': 'acte de naissance', 'ar': 'شهادة الميلاد' },
                    { 'fr': 'certificat de résidence', 'ar': 'بطاقة إقامة قيد الصلاحية، لا تتعدى 3 أشهر' },
                    { 'fr': 'attestation de travail récente cdi au permanant', 'ar': 'شهادة عمل لا يزيد تاريخها عن شهر تثبت أن عقد العمل غير محدود المدة وأن العامل مرسم في منصبه' },
                    { 'fr': 'les trois dernières fiches de paies de trois dernier mois ou le relevé des émoluments', 'ar': 'كشف راتب للأشهر الثلاثة (03)الأخيرة ومصادق عليها من صاحب العمل او كشف الراتب السنوي' },
                    { 'fr': 'le relevé de compte d’une année', 'ar': 'كشف الحساب لمدة سنة' },
                    { 'fr': 'un chèque barré', 'ar': 'صك بريدي باسم المتعامل مشطوب عليه' },
                    { 'fr': 'affiliation CNAS', 'ar': 'شهادة تحديث من الصندوق الوطني للتأمينات الاجتماعية' },
                    { 'fr': 'déclaration annuelle des salaires', 'ar': 'التصريح السنوي بالأجور' },
                    { 'fr': 'mise à jour de financement on cas de crédit', 'ar': 'تحديث التمويل أو حالة الائتمان في حالة وجود قرض' },


                ]
            },

            'Banque':

            {
                'public': [

                    { 'fr': 'une (01) copie de la carte nationale biométrique', 'ar': 'نسخة من بطاقة التعريف البيىومترية' },
                    { 'fr': 'un (01) acte de naissance', 'ar': 'نسخة من شهادة الميلاد' },
                    { 'fr': 'une (01) résidence', 'ar': 'نسخة من بطاقة الإقامة' },
                    { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                    { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للثلاثة الأشهر (3) الأخيرة' },
                    { 'fr': 'Une attestation de travail de moins de (03) mois', 'ar': 'شهادة عمل حديثة لا تتعدى 3 أشهر' },
                    { 'fr': 'Les (03) dernières fiches de paie', 'ar': 'كشف الراتب للثلاثة الأشهر (3) الأخيرة' },
                    { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                    { 'fr': 'Un chèque barré', 'ar': 'صك مشطوب' },
                    { 'fr': 'Une autorisation de prélèvement bancaire accusé de la banque où vous étés domiciliés imprimable depuis le site Diar Dzair', 'ar': 'الترخيص بالإقتطاع مصادق عليه من طرف البنك و ممضي عليه' },

                ],
                // 'indépendant': [

                //     { 'fr': 'Copie de la carte fiscale pour les entrepreneurs individuels ET profession libérale et les commerçants …etc.', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Un extrait de rôle apure de date récente ou un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Les bilans et comptes de résultats des trois derniers exercices', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Une attestation de mise à jour fiscale et parafiscale de date récente', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Relevé de compte pour les trois derniers mois', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Les trois derniers avertissements fiscaux ou tout autre justificatif de revenu', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Mise à jour CNAS et CASNOS apurée cas échéant un calendrier', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Copie du registre de commerce', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },
                //     { 'fr': 'Copie de largement', 'ar': 'نسختين من وثيقـة هوية رسميـة قيد الصلاحية تحمل رقم التعريـف الوطني' },

                // ],
                'retraite': [

                    { 'fr': 'une (01) copie de la carte nationale biométrique', 'ar': 'نسخة من بطاقة التعريف البيىومترية' },
                    { 'fr': 'une (01) acte de naissance', 'ar': ' نسخة من شهادة الميلاد' },
                    { 'fr': 'une (01) résidence', 'ar': 'نسخة من بطاقة الإقامة' },
                    { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                    { 'fr': 'Un (01) Extrait de Compte des trois (3) derniers mois', 'ar': 'كشف الحساب للثلاثة أشهر (3) الأخيرة' },
                    { 'fr': 'Une attestation de revenu de moins de (03) mois', 'ar': 'شهادة إثبات الدخل حديثة (لا تتعدى ثلاثة أشهر)' },
                    { 'fr': ' Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض أخر' },
                    { 'fr': 'Un chèque barré', 'ar': 'صك مشطوب' },
                    { 'fr': 'Une autorisation de prélèvement accusée de la banque ou vous étés domiciliés', 'ar': 'الترخيص بالإقتطاع مصادق عليه من طرف البنك و ممضي عليه' },

                ],
                'prive': [

                    { 'fr': 'une (01) copie de la carte nationale biométrique', 'ar': 'نسخة من بطاقة التعريف البيىومترية' },
                    { 'fr': 'un (01) acte de naissance', 'ar': 'نسخة من شهادة الميلاد' },
                    { 'fr': 'deux résidences', 'ar': ' نسختين (2) من بطاقة الإقامة' },
                    { 'fr': 'une fiche familiale', 'ar': 'شهادة عائلية' },
                    { 'fr': 'les (03) dernières fiches de paies des (03) derniers mois', 'ar': 'كشف الراتب للثلاثة أشهر (3) الأخيرة' },
                    { 'fr': 'Un (01) Extrait de Compte Annuel', 'ar': 'كشف الحساب السنوي ( سنة واحدة)' },
                    { 'fr': 'Une attestation de travail de moins de (03) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé', 'ar': ' شهادة عمل حديثة لا تتعدى 3 أشهر مع ضرورة اثبات أن عقد العمل غير منتهى المدة' },
                    { 'fr': 'Une copie d’échéancier en cas de crédit en cours', 'ar': 'نسخة من جدول السداد في حالة وجود قرض اخر' },
                    { 'fr': 'Un chèque barré', 'ar': 'صك مشطوب' },
                    { 'fr': 'Une autorisation de prélèvement accusée de la banque ou vous étés domiciliés', 'ar': ' الترخيص بالاقتطاع مصادق عليه من طرف البنك و ممضي عليه' },
                    { 'fr': 'Une copie de la déclaration annuelle des salaires extraite du lieu de travail', 'ar': ' نسخة من بيان الراتب السنوي' },
                    { 'fr': 'Affiliation', 'ar': 'شهادة الانتساب' },


                ]
            },

            // 'banque': {
            //     'public': [
            //         { 'fr': 'Une copie de la carte nationale biométrique recto verso' },
            //         { 'fr': 'Un acte de naissance(ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une résidence(ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une fiche familiale(ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une attestation de travail récente qui ne dépasse pas les(03) mois avec une preuve de permanence du travail' },
            //         { 'fr': 'Les 3 dernières fiches de paies ou le relevé des émoluments' },
            //         { 'fr': 'Relevé de compte bancaire des trois derniers mois' },
            //         { 'fr': 'Autorisation de prélèvement accusée de la banque ou vous êtes domicilié' },
            //         { 'fr': 'Un chèque barré' }

            //     ],
            //     'prive': [
            //         { 'fr': 'Une copie de la carte nationale biométrique recto verso' },
            //         { 'fr': 'Un acte de naissance(ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une résidence(ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une fiche familiale(ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une attestation de travail récente qui ne dépasse pas les(03) mois avec une preuve de permanence du travail' },
            //         { 'fr': 'Les 3 dernières fiches de paies ou le relevé des émoluments' },
            //         { 'fr': 'Relevé de compte bancaire d’une année' },
            //         { 'fr': 'Une attestation d’affiliation' },
            //         { 'fr': 'Déclaration annuelle des salaires(extraite du lieu de travail)' },
            //         { 'fr': 'Autorisation de prélèvement accusée de la banque ou vous êtes domicilié' },
            //         { 'fr': 'Un chèque barré' },
            //     ],
            //     'retraite': [
            //         { 'fr': 'Une copie de la carte nationale biométrique recto verso' },
            //         { 'fr': 'Un acte de naissance (ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une résidence (ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une fiche familiale (ne dépasse pas les 06 mois)' },
            //         { 'fr': 'Une attestation de revenu' },
            //         { 'fr': 'Relevé de compte bancaire des trois derniers mois' },
            //         { 'fr': 'Autorisation de prélèvement accusée de la banque ou vous êtes domicilié' },
            //         { 'fr': 'Un chèque barré' },
            //     ]
            // },

            // 'ccp': {
            //     'public': [
            //         { 'fr': ' Deux copies de la carte identité biométrique' },
            //         { 'fr': ' Acte de Naissance deux (02) exemplaires' },
            //         { 'fr': ' Justificatif de résidence de moins de 3 mois (Fiche de Résidence) deux (02) exemplaires' },
            //         { 'fr': ' Une (01) Fiche Familiale pour les personnes mariées' },
            //         { 'fr': ' Un (01) Extrait de Compte des trois (3) derniers mois' },
            //         { 'fr': ' Une attestation de travail de moins d’un (03) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé (pour les salariés)' },
            //         { 'fr': ' Une attestation de revenu extraite du CNR (pour les retraité)' },
            //         { 'fr': ' Trois (03) dernières Fiches de Paie' },
            //         { 'fr': ' Deux (02) photos' },
            //         { 'fr': ' Une copie d’échéancier en cas de crédit en cours' },
            //         { 'fr': ' Un chèque barré' },
            //         { 'fr': ' Une autorisation de prélèvement sur compte CCP légalisée auprès de l’A.P.C. (pour les clients qui reçoivent leur salaires à travers le CCP' },
            //         { 'fr': ' التـزام غيـر رجـعي بتوطيـن الراتـب (pour les clients qui reçoivent leur salaires à travers Salam-Bank)' }
            //     ],
            //     'retraite': [
            //         { 'fr': ' Deux copies de la carte identité biométrique'},
            //         { 'fr': ' Acte de Naissance deux (02) exemplaires'},
            //         { 'fr': ' Justificatif de résidence de moins de 3 mois (Fiche de Résidence) deux (02) exemplaires'},
            //         { 'fr': ' Une (01) Fiche Familiale pour les personnes mariées'},
            //         { 'fr': ' Un (01) Extrait de Compte des trois (3) derniers mois'},
            //         { 'fr': ' Une attestation de travail de moins d’un (03) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé (pour les salariés)' },
            //         { 'fr': ' Une attestation de revenu extraite du CNR (pour les retraité)'},
            //         { 'fr': ' Trois (03) dernières Fiches de Paie'},
            //         { 'fr': ' Deux (02) photos'},
            //         { 'fr': ' Une copie d’échéancier en cas de crédit en cours'},
            //         { 'fr': ' Un chèque barré'},
            //         { 'fr': ' Une autorisation de prélèvement sur compte CCP légalisée auprès de l’A.P.C. (pour les clients qui reçoivent leur salaires à travers le CCP' },
            //         { 'fr': ' التـزام غيـر رجـعي بتوطيـن الراتـب (pour les clients qui reçoivent leur salaires à travers Salam-Bank)' }
            //     ]
            // }

        }
    )

    const [Daralmal] = useState({

        'title': { 'fr': 'Dar Almal', 'ar': 'دار المال' },
        'titleserv': { 'fr': 'Nos services', 'ar': 'خدماتنا' },
        'engagement2': { 'fr': 'Un ramassage à domicile de votre dossier', 'ar': 'يتم جمع ملف تقسيط من المنزل ' },
        'engagement3': { 'fr': 'Un traitement efficace de votre dossier', 'ar': 'موافقة نهائية تتم بعد دراسة ملف الزبون لتاكد من امكانية استفادته من خدماتنا' },
        'engagement4': { 'fr': 'une livraison à domicile de votre commande', 'ar': 'خدمة توصيل طلبيتكم الى غاية منزلكم' },

    });

    return <LangContext.Provider value={{ Lang, setLang, NavMenu, Global, Panier, Account, BanqueDossier, Aide, FAQ, ProductLang, validation, FormulairetTitels, infoperssonnelle, adresse, situationprofessionnelle, chargesetpatrimoine, confirmation, Engagements, dartaksitsim, taksitdossier, RecapitulatifSimTaksit, daridjardef, dari3mardef, dari3marsection, daridjarPage, idjarsection1, daridjarsim, dari3marsim, idjardossier, dari3mardossier, Footer , Daralmal}}>{children}</LangContext.Provider>
}


export default LangContext