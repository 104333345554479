import { useContext } from 'react'
import Engagements from '../components/DarIdjar/Engagements'
import Footer from '../components/Global/Footer'
import LangContext from '../Context/lang';
import { useNavigate } from "react-router-dom";
import BankContext from '../Context/bank'





const DarTaksitDossier = () => {

    const { Global, taksitdossier } = useContext(LangContext);
    const { bankActive, setBankActive, GetTaksitPrice } = useContext(BankContext);

    const navigate = useNavigate();

    // console.log(taksitdossier['list'+bankActive]);

    const Lang = "ar";


    return (
        <div className='dar-idjar-page taksit-dossier taksit-dossier-ar'>
            {/* <div className='search-area d-flex align-items-center' style={{ height: '300px', padding: 0 }}>
                <div className='container'>
                    <div className='titel ass-title'>{(taksitdossier['titre1'][Lang])}</div>

                </div>
            </div> */}

            <div className='container mt-2 pt-md-5 pt-2'>
                <div className='text-center'>
                    {/* <div className='daridjar-dossier-title'>{( taksitdossier['titre1'][Lang] )}</div> */}
                    <div className='daridjar-dossier-title mt-2 mb-3'>{(taksitdossier['titre2'][Lang])}</div>
                    {/* <div>Tout document justifiant la propriété du bien (acte enregistré et publié, livret foncier…)</div> */}
                </div>

                <div className="text-center dossier-sub">للموظفين</div>
                <main className='mt-0 pt-0' dir={Lang === 'ar' ? 'rtl' : ''}>
                    <ol className="gradient-list">

                        <li> نسختين (02) من بطاقة الهوية الرسميـة البيومترية قيد الصلاحية تحمل رقم التعريف الوطني.</li>

                        <li> نسختين (02) من شهادة الميلاد  لا تتعدى 6 أشهر</li>

                        <li> نسختين (02) من بطاقة ا الإقامة لا تتعدى 6  أشهر</li>

                        <li> شهادة عائلية للمتزوجين  لا تتعدى 6  أشهر</li>

                        <li> شهادة عمل لا  تتعدى 6  أشهر  بالنسبة للعاملين في القطاع العام ( أما بالنسبة للعاملين في القطاع الخاص لا يزيد تاريخها عن شهر)  تثبت أن عقد العمل غير محدود المدة وأن العامل مرسم في منصبه</li>

                        <li> كشف راتب للأشهر الثلاثة (03) الأخيرة ومصادق عليها من صاحب العمل</li>

                        <li> كشف الحساب للأشهر الثلاث 3 الأخيرة</li>

                        <li> صك بريدي باسم المتعامل مشطوب عليه</li>

                        <li> 2 صور شمسية</li>

                        <li> الترخيص بالاقتطاع</li>

                        <li> نسخة من جدول السداد في حالة وجود قرض لدى البنوك</li>

                        <h5 className="text-center pt-3 mt-4 mb-3">بالنسبة للعاملين في القطاع الخاص</h5>
                        <hr className='mb-4' />

                        <li>   التصريح السنوي للأجور</li>

                        <li>   الانتساب للضمان الإجتماعي</li>

                        <li>   كشف الحساب السنوي</li>


                        {/* {taksitdossier['list' + bankActive]?.length > 0 && taksitdossier['list' + bankActive]?.map((item, i) => (
                            <li key={`Dartaksitdossier${item[Lang]}`}>{item[Lang]}.</li>
                        ))
                        } */}
                    </ol>
                </main>

                <div className="text-center dossier-sub mt-2 pt-5">للمتقاعدين</div>
                <main className='mt-0 pt-0' dir={Lang === 'ar' ? 'rtl' : ''}>
                    <ol className="gradient-list">

                        <li> نسختين (02) من بطاقة الهوية الرسميـة البيومترية قيد الصلاحية تحمل رقم التعريف الوطني. </li>

                        <li> نسختين (02) من شهادة الميلاد  لا تتعدى 6 أشهر </li>

                        <li> نسختين (02) من بطاقة الاقامة لا تتعدى 6  أشهر </li>

                        <li> شهادة عائلية للمتزوجين  لا تتعدى 6  أشهر </li>

                        <li> كشف حساب للأشهر الثلاث 3 الأخيرة </li>

                        <li> شهادة منحة التقاعد لا تتعدى 6  أشهر </li>

                        <li> كشف الراتب للمتقاعدين العسكريين  لا يتعدى 6  أشهر </li>

                        <li> 2 صور شمسية </li>

                        <li> صك بريدي باسم المتعامل مشطوب عليه </li>

                        <li> الترخيص بالاقتطاع </li>

                        <li> نسخة من جدول السداد في حالة وجود قرض لدى البنوك </li>



                        {/* {taksitdossier['list' + bankActive]?.length > 0 && taksitdossier['list' + bankActive]?.map((item, i) => (
                            <li key={`Dartaksitdossier${item[Lang]}`}>{item[Lang]}.</li>
                        ))
                        } */}
                    </ol>
                </main>


                <div className="text-center dossier-sub mt-2 pt-5">للعسكريين </div>
                <main className='mt-0 pt-0' dir={Lang === 'ar' ? 'rtl' : ''}>
                    <ol className="gradient-list">


                        <li> نسختين (02) من بطاقة الهوية الرسميـة البيومترية قيد الصلاحية تحمل رقم التعريف الوطني. </li>

                        <li> نسختين (02) من شهادة الميلاد  لا تتعدى 6 أشهر </li>

                        <li> نسختين (02) من بطاقة ا الإقامة لا تتعدى 6  أشهر </li>

                        <li> شهادة عائلية للمتزوجين  لا تتعدى 6  أشهر </li>

                        <li> شهادة الحضور بالوحدة لاتتعدى 6 أشهر ( إضافة العقد إذا كان الزبون متعاقد) </li>

                        <li> كشف الراتب العسكري للثلاث أشهر الأخيرة </li>

                        <li> كشف الحساب للأشهر الثلاث 3 الأخيرة </li>

                        <li> صك بريدي باسم المتعامل مشطوب عليه </li>

                        <li> 2 صور شمسية </li>

                        <li> الترخيص بالاقتطاع </li>

                        <li> نسخة من جدول السداد في حالة وجود قرض لدى البنوك </li>



                        {/* {taksitdossier['list' + bankActive]?.length > 0 && taksitdossier['list' + bankActive]?.map((item, i) => (
                            <li key={`Dartaksitdossier${item[Lang]}`}>{item[Lang]}.</li>
                        ))
                        } */}
                    </ol>
                </main>


                <div className="text-center dossier-sub mt-2 pt-5"> للمتقاعدين العسكريين </div>
                <main className='mt-0 pt-0' dir={Lang === 'ar' ? 'rtl' : ''}>
                    <ol className="gradient-list">



                      <li>  نسختين (02) من بطاقة الهوية الرسميـة البيومترية قيد الصلاحية تحمل رقم التعريف الوطني. </li>

                      <li>  نسختين (02) من شهادة الميلاد  لا تتعدى 6 أشهر </li>

                      <li>  نسختين (02) من بطاقة الاقامة لا تتعدى 6  أشهر </li>

                      <li>  شهادة عائلية للمتزوجين  لا تتعدى 6  أشهر </li>

                      <li>  كشف حساب للأشهر الثلاث 3 الأخيرة </li>

                      <li>  كشف الراتب للمتقاعدين العسكريين  لا يتعدى 6  أشهر </li>

                      <li>  2 صور شمسية </li>

                      <li>  صك بريدي باسم المتعامل مشطوب عليه </li>

                      <li>  الترخيص بالاقتطاع </li>

                      <li>  نسخة من جدول السداد في حالة وجود قرض لدى البنوك </li>



                        {/* {taksitdossier['list' + bankActive]?.length > 0 && taksitdossier['list' + bankActive]?.map((item, i) => (
                            <li key={`Dartaksitdossier${item[Lang]}`}>{item[Lang]}.</li>
                        ))
                        } */}
                    </ol>
                </main>


                {/* <div className='dar-idjar-dossier-sub mt-5 pt-5 text-center'>{(taksitdossier['titre3'][Lang])}</div> */}
                <div className='dar-idjar-dossier-sub mt-5 pt-5 text-center' dir="rtl">
                    الآن قم بإعداد الملف وأرسله على رقم whatsApp الخاص بنا
                </div>

                <a href="https://wa.me/213770061764" className=''>
                    <div className='sell-btn-taksit m-auto mt-3 mb-3'>0770061764
                        <svg className='ms-3' xmlns="https://www.w3.org/2000/svg" width="23.417" height="21.964" viewBox="0 0 23.417 21.964">
                            <g id="_270-2708173_cart-add-512x512-icon-online-shopping" data-name="270-2708173_cart-add-512x512-icon-online-shopping" transform="translate(-669.8 -330.1)">
                                <path id="Tracé_148" data-name="Tracé 148" d="M669.8,354.018c.238-.4.408-.463.848-.316l2.911.976c.39.128.472.248.509.665.073.857.151,1.719.229,2.595.578.087,1.142.17,1.705.257,1.284.188,2.567.38,3.851.564a.6.6,0,0,1,.555.371.538.538,0,0,1-.623.706c-.743-.105-1.481-.22-2.219-.33-.976-.147-1.948-.289-2.925-.431-.073-.009-.147-.014-.252-.023.028.339.05.656.092.972,0,.046.119.1.183.11,1.357.211,2.719.422,4.076.628l10.393,1.582c.651.1,1.3.193,1.948.3.16.027.234-.009.266-.174.06-.289.147-.573.229-.885l-1.123-.17c-1.036-.156-2.068-.307-3.1-.458a.536.536,0,0,1-.513-.628.526.526,0,0,1,.665-.431q1.939.282,3.874.573c.481.073.477.078.605-.4.055-.206.105-.413.165-.619.083-.293.248-.454.5-.463a.521.521,0,0,1,.536.348.22.22,0,0,0,.041.05v.229c-.028.087-.064.17-.087.257q-1,3.789-2.008,7.573c-.147.559-.243.633-.83.633H674.824c-.105,0-.215,0-.321,0a1.065,1.065,0,0,0-.156,2.1,1.931,1.931,0,0,0,.431.027h15.56a1.839,1.839,0,0,1,.275.009.535.535,0,0,1-.11,1.064c-.417.009-.834,0-1.284,0,.046.11.073.183.105.261a2.145,2.145,0,0,1-1.527,2.9c-.087.018-.174.041-.266.064h-.367a1.058,1.058,0,0,0-.128-.041,2.139,2.139,0,0,1-1.623-3c.023-.05.037-.11.06-.17h-7.427c.037.092.069.16.1.229a2.143,2.143,0,0,1-1.531,2.916c-.087.018-.179.041-.266.06h-.321c-.05-.014-.1-.032-.151-.041a2.14,2.14,0,0,1-1.627-3.007c.027-.06.046-.119.073-.179a2.169,2.169,0,0,1-.321-4.218c0-.073,0-.138,0-.2q-.475-5.515-.949-11.026a.278.278,0,0,0-.225-.289c-.834-.271-1.664-.559-2.5-.83a.783.783,0,0,1-.527-.431ZM675.081,367h.27q7.276,0,14.551,0c.183,0,.261-.041.312-.229.234-.926.486-1.848.729-2.769.023-.092.046-.188.078-.3-5.492-.839-10.952-1.673-16.444-2.508C674.746,363.145,674.911,365.053,675.081,367Zm2.168,5.355a1.064,1.064,0,1,0-1.059,1.068A1.07,1.07,0,0,0,677.249,372.356Zm10.122,1.064a1.066,1.066,0,1,0-1.073-1.05A1.054,1.054,0,0,0,687.372,373.419Z" transform="translate(0 -22.451)" fill="#fff" />
                                <path id="Tracé_149" data-name="Tracé 149" d="M905.713,330.1a1.487,1.487,0,0,1,.229.183.486.486,0,0,1,.105.532.491.491,0,0,1-.44.348c-.183.018-.367.023-.546.055a2.673,2.673,0,1,0,3.113,3.072c.023-.142.027-.289.037-.436a.534.534,0,1,1,1.064.055,3.6,3.6,0,0,1-2,3.246,3.718,3.718,0,0,1-5.313-4.241,3.606,3.606,0,0,1,2.984-2.76c.073-.014.147-.032.22-.05C905.346,330.1,905.53,330.1,905.713,330.1Z" transform="translate(-221.381)" fill="#fff" />
                                <path id="Tracé_150" data-name="Tracé 150" d="M950.716,379.035v.316c0,.5-.174.752-.523.756s-.536-.252-.536-.77v-.3c-.179,0-.335,0-.491,0a.535.535,0,1,1,.009-1.068c.151,0,.3,0,.486,0,0-.188,0-.353,0-.518a.53.53,0,1,1,1.059.009c0,.16,0,.316,0,.5.179,0,.335,0,.5,0a.533.533,0,0,1,.568.532.52.52,0,0,1-.555.536C951.065,379.04,950.9,379.035,950.716,379.035Z" transform="translate(-266.017 -44.653)" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </a>


            </div>
            {/* <div className='ass-tel'>
                <svg className='me-3' xmlns="https://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 16 16">
                    <path id="icons8-phone" d="M8.154,6A2.159,2.159,0,0,0,6,8.154V19.846A2.159,2.159,0,0,0,8.154,22H19.846A2.159,2.159,0,0,0,22,19.846V8.154A2.159,2.159,0,0,0,19.846,6Zm0,.615H19.846a1.536,1.536,0,0,1,1.538,1.538V19.846a1.536,1.536,0,0,1-1.538,1.538H8.154a1.536,1.536,0,0,1-1.538-1.538V8.154A1.536,1.536,0,0,1,8.154,6.615Zm2.4.927a1,1,0,0,0-.58.231,2.814,2.814,0,0,0-.848,1.038A3.294,3.294,0,0,0,9.4,11.846a.308.308,0,0,0,.031.044,14.689,14.689,0,0,0,6.649,6.66.308.308,0,0,0,.07.055,3.294,3.294,0,0,0,3.035.277h0a2.811,2.811,0,0,0,1.038-.848,1.011,1.011,0,0,0-.206-1.478h0l-2.071-1.4a.991.991,0,0,0-1.174.051h0l-.755.611a12.722,12.722,0,0,1-3.838-3.836l.617-.761a.989.989,0,0,0,.051-1.173l-1.4-2.072a1,1,0,0,0-.694-.428A1.029,1.029,0,0,0,10.555,7.542Zm.041.61a.383.383,0,0,1,.347.171l1.4,2.071h0a.367.367,0,0,1-.019.442l-.762.941a.308.308,0,0,0-.023.354,13.308,13.308,0,0,0,4.338,4.337.308.308,0,0,0,.354-.023l.935-.757a.368.368,0,0,1,.442-.02l2.072,1.4a.387.387,0,0,1,.077.574,2.474,2.474,0,0,1-.8.672,2.562,2.562,0,0,1-2.453-.219h0a.308.308,0,0,0-.07-.046,14.11,14.11,0,0,1-6.482-6.483.308.308,0,0,0-.024-.041A2.569,2.569,0,0,1,9.7,9.041a2.477,2.477,0,0,1,.672-.8A.4.4,0,0,1,10.6,8.152ZM8.9,13.263a.31.31,0,0,0-.115.04l-.528.316a.308.308,0,0,0,.316.528l.528-.316a.308.308,0,0,0-.2-.569Zm.96,1.3a.3.3,0,0,0-.228.058l-.493.367a.308.308,0,0,0,.367.493l.493-.368a.307.307,0,0,0-.139-.55Zm.965,1.209a.309.309,0,0,0-.222.079l-.455.414a.308.308,0,1,0,.413.456l.456-.414a.308.308,0,0,0-.192-.535ZM11.9,16.88a.307.307,0,0,0-.213.1l-.413.456a.307.307,0,1,0,.455.413l.414-.456a.307.307,0,0,0-.242-.514Zm1.18,1a.308.308,0,0,0-.2.12l-.368.493a.308.308,0,0,0,.493.368l.368-.493a.308.308,0,0,0-.291-.488Zm1.388.871a.309.309,0,0,0-.307.147l-.316.528a.308.308,0,0,0,.528.316l.316-.528a.307.307,0,0,0-.221-.462Z" transform="translate(-6 -6)" fill="#f33f69" />
                </svg>
                <h3>0982 300 500</h3>
            </div> */}

            <h6 className="text-center mt-4 pt-4">للدراجات النارية - Moto</h6>
            <div className='ass-tel '>
                <svg className='me-3' xmlns="https://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 16 16">
                    <path id="icons8-phone" d="M8.154,6A2.159,2.159,0,0,0,6,8.154V19.846A2.159,2.159,0,0,0,8.154,22H19.846A2.159,2.159,0,0,0,22,19.846V8.154A2.159,2.159,0,0,0,19.846,6Zm0,.615H19.846a1.536,1.536,0,0,1,1.538,1.538V19.846a1.536,1.536,0,0,1-1.538,1.538H8.154a1.536,1.536,0,0,1-1.538-1.538V8.154A1.536,1.536,0,0,1,8.154,6.615Zm2.4.927a1,1,0,0,0-.58.231,2.814,2.814,0,0,0-.848,1.038A3.294,3.294,0,0,0,9.4,11.846a.308.308,0,0,0,.031.044,14.689,14.689,0,0,0,6.649,6.66.308.308,0,0,0,.07.055,3.294,3.294,0,0,0,3.035.277h0a2.811,2.811,0,0,0,1.038-.848,1.011,1.011,0,0,0-.206-1.478h0l-2.071-1.4a.991.991,0,0,0-1.174.051h0l-.755.611a12.722,12.722,0,0,1-3.838-3.836l.617-.761a.989.989,0,0,0,.051-1.173l-1.4-2.072a1,1,0,0,0-.694-.428A1.029,1.029,0,0,0,10.555,7.542Zm.041.61a.383.383,0,0,1,.347.171l1.4,2.071h0a.367.367,0,0,1-.019.442l-.762.941a.308.308,0,0,0-.023.354,13.308,13.308,0,0,0,4.338,4.337.308.308,0,0,0,.354-.023l.935-.757a.368.368,0,0,1,.442-.02l2.072,1.4a.387.387,0,0,1,.077.574,2.474,2.474,0,0,1-.8.672,2.562,2.562,0,0,1-2.453-.219h0a.308.308,0,0,0-.07-.046,14.11,14.11,0,0,1-6.482-6.483.308.308,0,0,0-.024-.041A2.569,2.569,0,0,1,9.7,9.041a2.477,2.477,0,0,1,.672-.8A.4.4,0,0,1,10.6,8.152ZM8.9,13.263a.31.31,0,0,0-.115.04l-.528.316a.308.308,0,0,0,.316.528l.528-.316a.308.308,0,0,0-.2-.569Zm.96,1.3a.3.3,0,0,0-.228.058l-.493.367a.308.308,0,0,0,.367.493l.493-.368a.307.307,0,0,0-.139-.55Zm.965,1.209a.309.309,0,0,0-.222.079l-.455.414a.308.308,0,1,0,.413.456l.456-.414a.308.308,0,0,0-.192-.535ZM11.9,16.88a.307.307,0,0,0-.213.1l-.413.456a.307.307,0,1,0,.455.413l.414-.456a.307.307,0,0,0-.242-.514Zm1.18,1a.308.308,0,0,0-.2.12l-.368.493a.308.308,0,0,0,.493.368l.368-.493a.308.308,0,0,0-.291-.488Zm1.388.871a.309.309,0,0,0-.307.147l-.316.528a.308.308,0,0,0,.528.316l.316-.528a.307.307,0,0,0-.221-.462Z" transform="translate(-6 -6)" fill="#f33f69" />
                </svg>
                <h3>0982 300 500</h3>
            </div>

            <h6 className="text-center mt-4 pt-2"> للتجهيزات الكهرومنزلية - Électroménager </h6>
            <div className='ass-tel '>
                <svg className='me-3' xmlns="https://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 16 16">
                    <path id="icons8-phone" d="M8.154,6A2.159,2.159,0,0,0,6,8.154V19.846A2.159,2.159,0,0,0,8.154,22H19.846A2.159,2.159,0,0,0,22,19.846V8.154A2.159,2.159,0,0,0,19.846,6Zm0,.615H19.846a1.536,1.536,0,0,1,1.538,1.538V19.846a1.536,1.536,0,0,1-1.538,1.538H8.154a1.536,1.536,0,0,1-1.538-1.538V8.154A1.536,1.536,0,0,1,8.154,6.615Zm2.4.927a1,1,0,0,0-.58.231,2.814,2.814,0,0,0-.848,1.038A3.294,3.294,0,0,0,9.4,11.846a.308.308,0,0,0,.031.044,14.689,14.689,0,0,0,6.649,6.66.308.308,0,0,0,.07.055,3.294,3.294,0,0,0,3.035.277h0a2.811,2.811,0,0,0,1.038-.848,1.011,1.011,0,0,0-.206-1.478h0l-2.071-1.4a.991.991,0,0,0-1.174.051h0l-.755.611a12.722,12.722,0,0,1-3.838-3.836l.617-.761a.989.989,0,0,0,.051-1.173l-1.4-2.072a1,1,0,0,0-.694-.428A1.029,1.029,0,0,0,10.555,7.542Zm.041.61a.383.383,0,0,1,.347.171l1.4,2.071h0a.367.367,0,0,1-.019.442l-.762.941a.308.308,0,0,0-.023.354,13.308,13.308,0,0,0,4.338,4.337.308.308,0,0,0,.354-.023l.935-.757a.368.368,0,0,1,.442-.02l2.072,1.4a.387.387,0,0,1,.077.574,2.474,2.474,0,0,1-.8.672,2.562,2.562,0,0,1-2.453-.219h0a.308.308,0,0,0-.07-.046,14.11,14.11,0,0,1-6.482-6.483.308.308,0,0,0-.024-.041A2.569,2.569,0,0,1,9.7,9.041a2.477,2.477,0,0,1,.672-.8A.4.4,0,0,1,10.6,8.152ZM8.9,13.263a.31.31,0,0,0-.115.04l-.528.316a.308.308,0,0,0,.316.528l.528-.316a.308.308,0,0,0-.2-.569Zm.96,1.3a.3.3,0,0,0-.228.058l-.493.367a.308.308,0,0,0,.367.493l.493-.368a.307.307,0,0,0-.139-.55Zm.965,1.209a.309.309,0,0,0-.222.079l-.455.414a.308.308,0,1,0,.413.456l.456-.414a.308.308,0,0,0-.192-.535ZM11.9,16.88a.307.307,0,0,0-.213.1l-.413.456a.307.307,0,1,0,.455.413l.414-.456a.307.307,0,0,0-.242-.514Zm1.18,1a.308.308,0,0,0-.2.12l-.368.493a.308.308,0,0,0,.493.368l.368-.493a.308.308,0,0,0-.291-.488Zm1.388.871a.309.309,0,0,0-.307.147l-.316.528a.308.308,0,0,0,.528.316l.316-.528a.307.307,0,0,0-.221-.462Z" transform="translate(-6 -6)" fill="#f33f69" />
                </svg>
                <h3>0982 316 298</h3>
            </div>

            <div className='ass-tel mt-5'>

                <h3>helpdesk@diardzair.com.dz</h3>
            </div>


            <div className='pb-5'></div>
            {/* <Footer /> */}
        </div>
    )
}

export default DarTaksitDossier